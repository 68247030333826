import { Box, Divider, Stack, Typography } from '@mui/material'
import { t } from 'i18next'

import { formatDateShort } from '../../helpers/date-formatter.js'

const getAverage = (data) => {
  const sum = data.reduce((acc, current) => acc + current.y, 0)
  const media = sum / data.length
  return media.toFixed(2)
}

const ChartReferenceValues = ({ data, unitOfMeasure }) => {
  const average = getAverage(data)

  const orderedData = data.slice()
  orderedData.sort((a, b) => a.y - b.y)

  const minValue = {
    value: orderedData[0].y,
    date: orderedData[0].x
  }
  const maxValue = {
    value: orderedData[orderedData.length - 1].y,
    date: orderedData[orderedData.length - 1].x
  }

  return (
    <Stack
      direction="row"
      spacing={3}
      marginLeft={3}
      marginRight={3}
      marginBottom={3}
      divider={
        <Divider
          orientation="vertical"
          flexItem
        />
      }
      sx={{
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%'
      }}
    >
      <ReferenceValue
        title="Min"
        value={`${minValue.value} ${unitOfMeasure}`}
        date={formatDateShort(minValue.date)}
        unitOfMeasure={unitOfMeasure}
      />

      <ReferenceValue
        title="Max"
        value={`${maxValue.value} ${unitOfMeasure}`}
        date={formatDateShort(maxValue.date)}
        unitOfMeasure={unitOfMeasure}
      />

      <ReferenceValue
        title={t('charts.average')}
        value={`${average} ${unitOfMeasure}`}
        date={t('charts.inTheSelectedPeriod')}
        unitOfMeasure={unitOfMeasure}
      />

      <ReferenceValue
        title="N. misurazioni"
        value={`${data.length}`}
        date={t('charts.inTheSelectedPeriod')}
        unitOfMeasure=""
      />
    </Stack>
  )
}

const ReferenceValue = ({ title, value, date }) => {
  return (
    <Box>
      <Typography
        variant="h5"
        color="secondary"
        marginBottom={0.5}
      >
        {title}
      </Typography>
      <Typography variant="h5">{value}</Typography>
      <Typography variant="body2">{date}</Typography>
    </Box>
  )
}

export default ChartReferenceValues
