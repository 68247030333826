import { Box, Button, Stack, Typography } from '@mui/material'
import { t } from 'i18next'
import { useEffect, useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'

import { useValidationEffect } from '../../../helpers/hooks.js'
import Can from '../../Layout/Can/Can.jsx'
import ZList from '../../Shared/Components/ZList.jsx'
import { useGlobalContext } from '../../Shared/Contexts/GlobalContext.jsx'
import ConfirmDialog from '../../Shared/Dialogs/ConfirmDialog'
import TriggerCard from '../Card/TriggerCard.jsx'
import { useAutomations } from '../Contexts/AutomationsContext.jsx'
import AddTrigger from '../Dialogs/AddTrigger.jsx'
import TriggerDetail from '../Dialogs/TriggerDetail'
import TriggersFilters from '../Filters/TriggersFilters'

const DEFAULT_PAGE_SIZE = 25

const TriggersList = () => {
  const [searchParams] = useSearchParams()
  const fullText = searchParams.get('search')

  const { useSearchTriggers, useDeleteTrigger } = useAutomations()
  const { openErrorAlert, openSuccessAlert } = useGlobalContext()

  const { tab, entityId } = useParams()
  const [triggerToDeleteName, setTriggerToDeleteName] = useState(null)
  const [triggerToDeleteId, setTriggerToDeleteId] = useState(null)
  const [selectedTriggerId, setSelectedTriggerId] = useState()
  const [triggerDetailOpen, setTriggerDetailOpen] = useState(false)
  const [isAddTriggerDialogOpen, setIsAddTriggerDialogOpen] = useState(false)
  const [confirmDeleteTriggerIsOpen, setConfirmDeleteTriggerIsOpen] = useState(false)
  const [isFetchTriggersLoading, setIsFetchTriggersLoading] = useState(false)

  const [filters, setFilters] = useState({})
  const [page, setPage] = useState(1)

  const [triggers, setTriggers] = useState()

  const [openReadonlyDialog, setOpenReadonlyDialog] = useState(false)

  const searchTriggers = ({ filters, page }) => {
    setIsFetchTriggersLoading(true)
    useSearchTriggers({
      filters,
      page,
      pageSize: DEFAULT_PAGE_SIZE
    })
      .then((res) => setTriggers(res))
      .finally(() => setIsFetchTriggersLoading(false))
  }

  useValidationEffect(() => {
    searchTriggers({
      filters: {
        ...filters,
        fullText
      },
      page: page
    })
  }, [page])

  useEffect(() => {
    setPage(1)
    searchTriggers({
      filters: {
        ...filters,
        fullText
      },
      page: 1
    })
  }, [fullText])

  const handleChangePage = (_, value) => setPage(value)

  const prepareDeleteTrigger = async (trigger) => {
    setTriggerToDeleteId(trigger.id)
    setTriggerToDeleteName(trigger.name)
    setConfirmDeleteTriggerIsOpen(true)
  }

  useEffect(() => {
    if (tab !== 'triggers' || !entityId) {
      return
    }

    setOpenReadonlyDialog(true)
    setTriggerDetailOpen(true)
    setSelectedTriggerId(entityId)
  }, [tab, entityId])

  const handleCloseAddTrigger = () => {
    searchTriggers({
      filters: {
        ...filters,
        fullText
      },
      page: 1
    })
    setPage(1)
    setIsAddTriggerDialogOpen(false)
  }

  const handleFiltersChange = (filters) => {
    setFilters(filters)
    searchTriggers({
      filters: {
        ...filters,
        fullText
      },
      page: 1
    })
  }

  const handleOnEdit = (triggerId) => {
    setSelectedTriggerId(triggerId)
    setTriggerDetailOpen(true)
  }

  const handleTriggerDetailClose = () => {
    setOpenReadonlyDialog(false)
    setSelectedTriggerId(null)
    setTriggerDetailOpen(false)
  }

  const handleOnConfirmDeleteTrigger = () => {
    useDeleteTrigger({ id: triggerToDeleteId })
      .then(() => {
        openSuccessAlert()
        searchTriggers({
          filters: {
            ...filters,
            fullText
          },
          page: 1
        })
        setPage(1)
      })
      .catch((err) => openErrorAlert({ keys: err.data.keys }))
      .finally(() => setConfirmDeleteTriggerIsOpen(false))
  }

  const handleEnableEdit = () => setOpenReadonlyDialog(false)

  const triggersList = triggers?.data.map((trigger, index) => (
    <TriggerCard
      index={index}
      key={`trigger-${index}`}
      trigger={trigger}
      onDelete={() => prepareDeleteTrigger(trigger)}
      onEdit={handleOnEdit}
    />
  ))

  const triggersFilters = (
    <TriggersFilters
      filters={filters}
      onChange={handleFiltersChange}
    />
  )

  return (
    <>
      <Stack direction="row">
        <Typography variant="body1">
          <strong>{t('automations.triggers')}</strong>
        </Typography>
        <Box sx={{ flexGrow: 1 }} />
        <Can action={'devices_u'}>
          <Button
            id="triggers-list-create-new-trigger"
            size="small"
            variant="contained"
            color="secondary"
            onClick={() => {
              setIsAddTriggerDialogOpen(true)
            }}>
            {t('triggers.createNew')}
          </Button>
        </Can>
      </Stack>

      <ZList
        count={triggers?.count}
        filters={triggersFilters}
        entities={triggersList}
        page={page}
        isLoading={isFetchTriggersLoading}
        onPageChange={handleChangePage}
      />

      <AddTrigger
        isOpen={isAddTriggerDialogOpen}
        onClose={handleCloseAddTrigger}
      />
      {triggerDetailOpen && (
        <TriggerDetail
          open={triggerDetailOpen}
          onClose={handleTriggerDetailClose}
          triggerId={selectedTriggerId}
          onEnableEdit={handleEnableEdit}
          readonly={openReadonlyDialog}
        />
      )}
      <ConfirmDialog
        isOpen={confirmDeleteTriggerIsOpen}
        title={t('triggers.deleteTrigger')}
        message={`${t('triggers.areYouSureYouWantToDelete')} "${triggerToDeleteName}"?`}
        onConfirm={handleOnConfirmDeleteTrigger}
        onClose={() => setConfirmDeleteTriggerIsOpen(false)}
      />
    </>
  )
}

export default TriggersList
