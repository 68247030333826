import { Fragment } from 'react'

import { useAuth } from '../../Shared/Contexts/AuthContext'

const Can = ({ children, action, expression = () => true }) => {
  const { currentUser } = useAuth()
  const { permissions, currentOrganizationKey } = currentUser

  if (
    !permissions?.[currentOrganizationKey]?.includes(action) ||
    !expression()
  ) {
    return null
  }

  return <Fragment>{children}</Fragment>
}

export default Can
