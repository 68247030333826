import { Autocomplete, TextField } from '@mui/material'
import { t } from 'i18next'
import { useEffect, useState } from 'react'

import { useValidationEffect } from '../../../../helpers/hooks'
import { useAutomations } from '../../Contexts/AutomationsContext'
import ModelsSelector from './ModelsSelector'

const sortFn = (a, b) => {
  const label1 = a.label.toLowerCase()
  const label2 = b.label.toLowerCase()
  if (label1 < label2) return -1
  if (label1 > label2) return 1
  return 0
}

const MeasureTriggerRuleForm = ({ trigger, isEdit, onChange, selectedOrganizationKey, validation, readonly }) => {
  const {
    quantityId = trigger.quantity?.id || trigger.quantityId,
    device,
    selectedModels = trigger.models,
    applyToChildOrganizations,
    type,
    organizationKey
  } = trigger

  const { useGetQuantities } = useAutomations()

  const [quantities, setQuantities] = useState([])
  const [selectedQuantity, setSelectedQuantity] = useState()
  const [selectedSubQuantity, setSelectedSubQuantity] = useState()

  const getSubQuantities = () =>
    device && selectedQuantity
      ? device.quantities
          .filter((q) => q.measureId === selectedQuantity.id)
          .map((q) => ({
            id: q.measureName,
            label: q.measureName
          }))
      : []

  const getSelectedSubQuantity = () => {
    const subQuantities = getSubQuantities()
    return subQuantities.length === 1 ? subQuantities[0] : ''
  }

  //TODO 29-11-2024 valutare se non spostare queste chiamate comuni in trigger form
  useEffect(() => {
    useGetQuantities(selectedOrganizationKey).then((res) => {
      setQuantities(res.data)
      setSelectedQuantity(res.data.find((q) => q.id === quantityId))
    })
  }, [])

  useEffect(() => {
    const selected = quantities.find((q) => q.id === quantityId)
    setSelectedQuantity(selected)
  }, [quantityId])

  useValidationEffect(() => {
    const subQuantities = getSubQuantities()
    const selectedSubQuantity = subQuantities.length === 1 ? subQuantities[0] : null
    changeSubMeasure(selectedSubQuantity)
  }, [selectedQuantity])

  const changeSubMeasure = (subQuantity) => {
    if (!device) {
      return
    }
    setSelectedSubQuantity(subQuantity)
    const models = subQuantity
      ? [
          {
            id: parseInt(device.modelId, 10),
            measures: [{ name: subQuantity.id }]
          }
        ]
      : []
    onChange({ models })
  }

  const handleModelsChanged = (data) => onChange(data)
  const handleChangeSubMeasuere = (_event, value) => changeSubMeasure(value)

  const handleSelectedQuantity = (_data, value) => {
    setSelectedQuantity(value)
    setSelectedSubQuantity(null)
    onChange({
      quantity: value,
      quantityId: value?.id,
      models: []
    })
  }

  const filteredQuantities = device
    ? quantities
        .filter((q) => device.quantities.some((dq) => dq.measureId === q.id))
        .map((fq) => ({
          ...fq,
          label: t(fq.label)
        }))
        .sort(sortFn)
    : quantities
        .map((fq) => ({
          ...fq,
          label: t(fq.label)
        }))
        .sort(sortFn)

  const subQuantities = getSubQuantities()

  return (
    <>
      <Autocomplete
        sx={{
          marginTop: '30px'
        }}
        id="comunication-trigger-form-measure-selector"
        name="measureSelector"
        options={filteredQuantities
          .map((fq) => ({
            ...fq,
            label: t(fq.label)
          }))
          .sort(sortFn)}
        value={
          selectedQuantity
            ? {
                ...selectedQuantity,
                label: t(selectedQuantity.label)
              }
            : ''
        }
        getOptionLabel={(option) => option.label || ''}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        onChange={handleSelectedQuantity}
        renderInput={(params) => (
          <TextField
            required
            name="quantity"
            {...params}
            label={t('triggers.selectMeasure')}
            variant="standard"
            error={validation?.messages?.quantityId?.length > 0}
            helperText={validation?.messages?.quantityId?.join(', ')}
          />
        )}
        disabled={isEdit}
      />
      {subQuantities.length > 0 && (
        <Autocomplete
          sx={{
            marginTop: '30px'
          }}
          py={5}
          id="subMeasureSelector"
          name="subMeasureSelector"
          options={subQuantities}
          getOptionLabel={(option) => t(option.label)}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          onChange={handleChangeSubMeasuere}
          defaultValue={selectedSubQuantity || getSelectedSubQuantity()}
          value={selectedSubQuantity || getSelectedSubQuantity()}
          renderInput={(params) => (
            <TextField
              name="quantity"
              {...params}
              label={t('triggers.selectSubMeasure')}
              variant="standard"
            />
          )}
          disabled={isEdit}
        />
      )}
      {!device && (
        <ModelsSelector
          applyToChildOrganizations={applyToChildOrganizations}
          type={type}
          selectedModels={selectedModels}
          selectedQuantity={selectedQuantity}
          isEdit={isEdit}
          onModelsChanged={handleModelsChanged}
          selectedOrganizationKey={organizationKey || selectedOrganizationKey}
          validation={validation}
          readonly={readonly}
        />
      )}
    </>
  )
}

export default MeasureTriggerRuleForm
