import { CalendarMonthRounded as CalendarMonthRoundedIcon } from '@mui/icons-material'
import { IconButton, Stack, Typography } from '@mui/material'
import useForkRef from '@mui/utils/useForkRef'
import { renderDigitalClockTimeView } from '@mui/x-date-pickers/timeViewRenderers'
import { LocalizationProvider } from '@mui/x-date-pickers-pro'
import { AdapterMoment } from '@mui/x-date-pickers-pro/AdapterMoment'
import { DateTimeRangePicker } from '@mui/x-date-pickers-pro/DateTimeRangePicker'
import { t } from 'i18next'
import moment from 'moment-timezone'
import { forwardRef, useState } from 'react'

import { useGlobalContext } from '../../Shared/Contexts/GlobalContext.jsx'

const DateRangeButtonField = forwardRef(({
  handleDateTimeRangePickerOnClick: onClick,
  dateRange,
  InputProps: { ref: containerRef } = {},
  inputProps: { 'aria-label': ariaLabel } = {}
}, ref) => {
  const handleRef = useForkRef(ref, containerRef)

  return (
    <Stack
      direction="row"
      display="flex"
      justifyContent="flex-end"
      alignItems="center"
      sx={{ padding: '5px 16px' }}
    >
      <Typography
        variant="caption"
        onClick={onClick}
        sx={{
          cursor: 'pointer',
          textTransform: 'uppercase',
          textAlign: 'right'
        }}
      >
        <strong>{t('common.from')}: </strong> {moment(dateRange.from).tz('Europe/Rome').format('DD.MM.yyyy HH:mm')}
        <br />
        <strong>{t('common.to')}: </strong> {moment(dateRange.to).tz('Europe/Rome').format('DD.MM.yyyy HH:mm')}
      </Typography>
      <IconButton
        color="primary"
        aria-label={ariaLabel}
        onClick={onClick}
        ref={handleRef}
      >
        <CalendarMonthRoundedIcon id="dateRangePicker" fontSize="large" />
      </IconButton>
    </Stack>
  )
})

DateRangeButtonField.fieldType = 'single-input'
DateRangeButtonField.displayName = 'DateRangeButtonField'

const ButtonDateRangePicker = forwardRef((props, ref) => {
  const [open, setOpen] = useState(false)
  const { dateRange, setDateRange } = useGlobalContext()

  const shortcutsItems = [
    {
      label: t('common.today'),
      id: 'calendar-shortcut-today',
      getValue: () => {
        return [
          moment().tz('Europe/Rome').startOf('day'),
          moment().tz('Europe/Rome')
        ]
      }
    },
    {
      label: t('common.yesterday'),
      id: 'calendar-shortcut-yesterday',
      getValue: () => {
        return [
          moment().tz('Europe/Rome').subtract(1, 'day').startOf('day'),
          moment().tz('Europe/Rome').subtract(1, 'day').endOf('day')
        ]
      }
    },
    {
      label: t('common.lastWeek'),
      id: 'calendar-shortcut-last-week',
      getValue: () => {
        return [
          moment().tz('Europe/Rome').subtract(7, 'days').startOf('day'),
          moment().tz('Europe/Rome')]
      }
    },
    {
      label: t('common.lastTwoWeeks'),
      id: 'calendar-shortcut-last-two-weeks',
      getValue: () => {
        return [
          moment().tz('Europe/Rome').subtract(15, 'days').startOf('day'),
          moment().tz('Europe/Rome')
        ]
      }
    },
    {
      label: t('common.lastMonth'),
      id: 'calendar-shortcut-last-month',
      getValue: () => {
        return [
          moment().tz('Europe/Rome').subtract(30, 'days').startOf('day'),
          moment().tz('Europe/Rome')
        ]
      }
    },
    {
      label: t('common.lastYear'),
      id: 'calendar-shortcut-last-year',
      getValue: () => {
        return [
          moment().tz('Europe/Rome').subtract(1, 'year').startOf('day'),
          moment().tz('Europe/Rome')
        ]
      }
    },
    {
      label: 'Reset',
      id: 'calendar-shortcut-reset',
      getValue: () => {
        return [
          moment().tz('Europe/Rome').subtract(5, 'days').startOf('day'),
          moment().tz('Europe/Rome')
        ]
      }
    }
  ]

  const handleDateTimeRangePickerOnChange = (newDateRange) => {
    if (!newDateRange[0] || !newDateRange[1]) {
      return
    }
    setDateRange({ from: newDateRange[0], to: newDateRange[1] })
  }

  const handleDateTimeRangePickerOnClick = () => setOpen(!open)

  return (
    <DateTimeRangePicker
      {...props}
      ref={ref}
      ampm={false}
      calendars={1}
      disableFuture
      views={['day', 'hours']}
      viewRenderers={{ hours: renderDigitalClockTimeView }}
      timeSteps={{ minutes: 15 }}
      slots={{ ...props.slots, field: DateRangeButtonField }}
      slotProps={{
        field: { handleDateTimeRangePickerOnClick, dateRange },
        textField: {
          variant: 'standard',
          size: 'small'
        },
        shortcuts: { items: shortcutsItems }
      }}
      value={[moment(dateRange.from), moment(dateRange.to)]}
      onChange={handleDateTimeRangePickerOnChange}
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
    />
  )
})

ButtonDateRangePicker.displayName = 'ButtonDateRangePicker'

const TimePicker = () => {
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <ButtonDateRangePicker />
    </LocalizationProvider>
  )
}

export default TimePicker
