import { ListItemText, Menu, MenuItem } from '@mui/material'


export default function LanguageMenu(props) {
  const { anchorEl, handleClose } = props

  const open = Boolean(anchorEl)

  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      onClick={handleClose}
      PaperProps={{ elevation: 1 }}
      transformOrigin={{
        horizontal: 'right',
        vertical: 'top'
      }}
      anchorOrigin={{
        horizontal: 'right',
        vertical: 'bottom'
      }}
      sx={{ top: '20px' }}
    >
      <MenuItem>
        <ListItemText>Notifica 1</ListItemText>
      </MenuItem>
      <MenuItem>
        <ListItemText>Notifica 2</ListItemText>
      </MenuItem>
      <MenuItem>
        <ListItemText>Notifica 3</ListItemText>
      </MenuItem>
      <MenuItem>
        <ListItemText>Notifica 4</ListItemText>
      </MenuItem>
      <MenuItem>
        <ListItemText>Notifica 5</ListItemText>
      </MenuItem>
      <MenuItem>
        <ListItemText>Altro ...</ListItemText>
      </MenuItem>
    </Menu>
  )
}
