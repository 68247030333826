import { Box, Card, CardContent, Grid2, List, ListItem, ListItemText, Typography } from '@mui/material'
import { t } from 'i18next'

const DeviceSummary = ({ model, brand, type, sourceType, measurements }) => {
  return (
    <>
      {model && brand && (
        <Card
          variant="standard"
          elevation={0}
          mt={5}
          sx={{ background: '#E9EFF5' }}
        >
          <CardContent>
            <Typography
              variant="h4"
              mb={3}
            >
              {model} / {brand}
            </Typography>

            <Box sx={{ flexGrow: 1 }}>
              <Grid2
                container
                spacing={2}
              >
                <Grid2 size={6}>
                  <Typography variant="h5">{t('common.technology')}</Typography>
                  <Typography mb={3}>{type}</Typography>
                </Grid2>
                <Grid2 size={6}>
                  <Typography variant="h5">{t('common.typology')}</Typography>
                  <Typography mb={3}>{sourceType}</Typography>
                </Grid2>

                <Grid2 size={12}>
                  <Typography variant="h5">{t('devices.measures')}</Typography>
                  <List
                    dense
                    sx={{
                      columnCount: 2,
                      columnRuleStyle: 'solid'
                    }}
                  >
                    {measurements.slice(0, 13).map((q) => (
                      <ListItem key={q.measureName}>
                        <ListItemText primary={t(q.name)} />
                      </ListItem>
                    ))}
                    {measurements.length > 13 && (
                      <ListItem key="more">
                        <ListItemText
                          primary={t('devices.more', {
                            count: measurements.length - 13
                          })}
                        />
                      </ListItem>
                    )}
                  </List>
                </Grid2>
              </Grid2>
            </Box>
          </CardContent>
        </Card >
      )}
    </>
  )
}

export default DeviceSummary
