import { Stack } from '@mui/material'
import { t } from 'i18next'
import { useState } from 'react'

import Can from '../../Layout/Can/Can.jsx'
import Filter from '../../Layout/Dialogs/Filter.jsx'
import Count from '../../Shared/Components/Count.jsx'
import { useAuth } from '../../Shared/Contexts/AuthContext.jsx'
import FilterGatewayArea from '../../Shared/Filters/Filters/GatewayArea.jsx'
import FilterGatewayStatus from '../../Shared/Filters/Filters/GatewayStatus.jsx'
import FilterGatewayTechnology from '../../Shared/Filters/Filters/GatewayTechnology.jsx'

const Filters = ({ itemsCount, filters, onChange }) => {
  const {
    currentUser: { isSuperAdmin }
  } = useAuth()

  const [selectedArea, setSelectedArea] = useState(false)
  const [selectedStatus, setSelectedStatus] = useState(false)
  const [selectedTechnology, setSelectedTechnology] = useState(false)

  const handleOnDeleteArea = () => {
    onChange({
      ...filters,
      nearby: null
    })
    setSelectedArea(false)
  }

  const handleOnDeleteStatus = () => {
    onChange({
      ...filters,
      status: []
    })
    setSelectedStatus(false)
  }

  const handleOnDeleteTechnology = () => {
    onChange({
      ...filters,
      technology: []
    })
    setSelectedTechnology(false)
  }

  const handleChangeGatewaysStatus = (data) => {
    setSelectedStatus(true)
    onChange({
      ...filters,
      ...data
    })
  }
  const handleChangeGatewaysTechnology = (data) => {
    setSelectedTechnology(true)
    onChange({
      ...filters,
      ...data
    })
  }
  const handleChangeGatewayArea = (data) => {
    setSelectedArea(true)
    onChange({
      ...filters,
      ...data
    })
  }

  const filterUnits = [
    {
      label: t('filters.gateways.status.label'),
      id: 'chip-filters-status',
      selected: selectedStatus,
      onDelete: handleOnDeleteStatus,
      hidden: !isSuperAdmin,
      content: (
        <FilterGatewayStatus
          filters={filters}
          onChange={handleChangeGatewaysStatus}
        />
      ),
      count: <Count itemsCount={itemsCount} />
    },
    {
      label: t('filters.gateways.technology.label'),
      id: 'chip-filters-technology',
      selected: selectedTechnology,
      onDelete: handleOnDeleteTechnology,
      hidden: !isSuperAdmin,
      content: (
        <FilterGatewayTechnology
          filters={filters}
          onChange={handleChangeGatewaysTechnology}
        />
      ),
      count: <Count itemsCount={itemsCount} />
    },
    {
      label: t('filters.gateways.area.label'),
      id: 'chip-filters-area',
      selected: selectedArea,
      onDelete: handleOnDeleteArea,
      hidden: !isSuperAdmin,
      content: (
        <FilterGatewayArea
          filters={filters}
          onChange={handleChangeGatewayArea}
        />
      ),
      count: <Count itemsCount={itemsCount} />
    }
  ]

  return (
    <Stack
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      spacing={2}
    >
      {[...filterUnits].map((e, i) => (
        <Can
          action="devices_r"
          key={`device-filter-${i}`}
        >
          <Filter
            key={`device-filter-${i}`}
            id={e.id}
            onClick={e.onClick}
            onDelete={e.onDelete}
            selected={e.selected}
            title={e.label}
            hidden={e.hidden || false}
            content={e.content}
            count={e.count}
          />
        </Can>
      ))}
    </Stack>
  )
}

export default Filters
