import { CheckRounded as CheckRoundedIcon, ContentCopyRounded as ContentCopyRoundedIcon } from '@mui/icons-material'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  IconButton,
  Input,
  Typography
} from '@mui/material'
import { t } from 'i18next'
import { useEffect, useState } from 'react'

import CloseDialogButton from './CloseDialogButton.jsx'

const ConfirmWithCheckDialog = ({
  onConfirmAction,
  onClose,
  icon,
  questionText,
  subQuestionText,
  copyText,
  bodyText,
  inputSuggestionText,
  isOpen
}) => {
  const [disabled, setDisabled] = useState(true)
  const [controlText, setControlText] = useState(null)
  const [copied, setCopied] = useState(false)

  const handleChange = ({ target: { value } }) => setControlText(value)

  useEffect(() => {
    setDisabled(controlText !== copyText)
  }, [controlText])

  return (
    <Dialog
      maxWidth="xs"
      scroll="body"
      fullWidth={true}
      open={isOpen}
      onClose={onClose}
    >
      <DialogTitle
        display="flex"
        justifyContent="center"
      >
        <CloseDialogButton onClose={onClose} />
        <Box
          sx={{
            background: 'radial-gradient( 180px circle at 50% 50%, rgba(211, 47, 47, 0.3) 50%, transparent 51% )',
            height: '200px',
            width: '100%'
          }}
        >
          <Box
            display="flex"
            justifyContent="center"
            alignItems="baseline"
            sx={{
              position: 'relative',
              top: 'calc(50% - 30px)'
            }}
          >
            {icon}
          </Box>
          <Box mt={10}>
            <Typography
              variant="h5"
              align="center"
              color="error"
            >
              <strong>{questionText}</strong>
            </Typography>
          </Box>
        </Box>
      </DialogTitle>

      <DialogContent>
        <Typography
          id="confirm-with-check-dialog-device-name"
          variant="h5"
          align="center"
        >
          {subQuestionText}
        </Typography>
        <Typography
          id="confirm-with-check-dialog-device-id-label"
          variant="h5"
          align="center"
          mt={1}
        >
          {copyText}
          <IconButton
            onClick={() => {
              setCopied(true)
              navigator.clipboard.writeText(copyText)
            }}
          >
            {!copied ? <ContentCopyRoundedIcon /> : <CheckRoundedIcon />}
          </IconButton>
        </Typography>
        <Typography
          variant="h6"
          align="center"
          mt={3}
        >
          {bodyText}
        </Typography>

        <Box
          display="flex"
          justifyContent="center"
        >
          <FormControl
            variant="standard"
            sx={{
              width: '80%',
              my: 3
            }}
          >
            <Input
              id="confirm-with-check-dialog-device-id-input"
              slotProps={{ style: { textAlign: 'center' } }}
              aria-describedby="component-helper-text"
              onChange={handleChange}
            />

            <FormHelperText
              id="component-helper-text"
              sx={{ textAlign: 'center' }}
            >
              {inputSuggestionText}
            </FormHelperText>
          </FormControl>
        </Box>
      </DialogContent>

      <DialogActions
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <Button
          id="confirm-with-check-dialog-cancel"
          onClick={onClose}
          variant="standard"
        >
          {t('common.cancel')}
        </Button>
        <Button
          id="confirm-with-check-dialog-confirm"
          variant="contained"
          color="error"
          disabled={disabled}
          onClick={onConfirmAction}
        >
          {t('common.confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmWithCheckDialog
