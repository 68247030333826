import { Box } from '@mui/material'

const Invites = ({ main: MainComponent }) => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="80vh"
    >
      {MainComponent}
    </Box>
  )
}

export default Invites
