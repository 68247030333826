import { Typography } from '@mui/material'
import { t } from 'i18next'
import { useEffect, useState } from 'react'

import DynamicForm from '../../Shared/Forms/DynamicForm.jsx'

const ProvisioningSetup = ({ provisioningSetup, setIsValid, onChange }) => {
  const [provisioningSetupInfo, setProvisioningSetup] = useState({})

  useEffect(() => {
    setIsValid(provisioningSetupInfo.isValid)
    onChange(provisioningSetupInfo.provisioningSetup || {})
  }, [provisioningSetupInfo])

  const handleDynamicFormChange = (value, isValid) => setProvisioningSetup({
    isValid,
    provisioningSetup: value
  })

  return (
    <>
      <Typography
        id="devices-form-label-provisioning-setup"
        variant="h2"
        py={1}
      >
        {t('devices.provisioningSetup')}
      </Typography>
      {provisioningSetup && (
        <DynamicForm
          fieldsPayload={provisioningSetupInfo.provisioningSetup}
          fields={provisioningSetup.fields}
          onChange={handleDynamicFormChange}
        />
      )}
    </>
  )
}

export default ProvisioningSetup
