import { DeleteRounded as DeleteRoundedIcon } from '@mui/icons-material'
import { Button, Chip, Container, Grid2, Stack, Typography } from '@mui/material'
import { t } from 'i18next'
import { useEffect, useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'

import InviteUser from '../../Invites/Dialogs/InviteUser.jsx'
import Can from '../../Layout/Can/Can.jsx'
import ZList from '../../Shared/Components/ZList.jsx'
import { useGlobalContext } from '../../Shared/Contexts/GlobalContext.jsx'
import ConfirmWithCheckDialog from '../../Shared/Dialogs/ConfirmWithCheckDialog.jsx'
import UserCard from '../Card/UserCard.jsx'
import { useUsers } from '../Contexts/UsersContext.jsx'
import UpdateUser from '../Dialogs/UpdateUser.jsx'
import UserFilters from '../Filters/Filters.jsx'

const DEFAULT_PAGE_SIZE = 25

const UsersList = () => {
  const { useSearchUsersQuery, filters, useDeleteUser, setFilters, useFetchUser } = useUsers()
  const { openErrorAlert, openSuccessAlert } = useGlobalContext()
  const [searchParams] = useSearchParams()
  const fullText = searchParams.get('search')

  const { entityId } = useParams()

  const [confirmDialogData, setConfirmDialogData] = useState(null)
  const [isInviteUserDialogOpen, setIsInviteUserDialogOpen] = useState(false)
  const [isConfirmWithCheckOpen, setIsConfirmWithCheckOpen] = useState(false)
  const [isUpdateUserDialogOpen, setIsUpdateUserDialogOpen] = useState(false)
  const [selectedUser, setSelectedUser] = useState(null)
  const [users, setUsers] = useState({
    data: [],
    count: 0
  })
  const [isFetchUsersError, setIsFetchUsersError] = useState(false)
  const [isFetchUsersLoading, setIsFetchUsersLoading] = useState(false)
  const [userFromUrl, setUserFromUrl] = useState()

  const [page, setPage] = useState(1)

  const fetchUsers = () => {
    setIsFetchUsersLoading(true)
    useSearchUsersQuery({
      filters: {
        ...filters,
        fullText
      },
      page,
      pageSize: DEFAULT_PAGE_SIZE
    }).then((res) => {
      setUsers({
        data: res.data,
        count: res.count
      })
    })
      .catch(() => setIsFetchUsersError(true))
      .finally(() => setIsFetchUsersLoading(false))
  }

  useEffect(() => {
    fetchUsers()
  }, [fullText, filters, page])

  useEffect(() => {
    if (!entityId) {
      return
    }

    useFetchUser(entityId).then(({ data }) => setUserFromUrl(data))
  }, [entityId])

  useEffect(() => {
    if (userFromUrl !== undefined) {
      setSelectedUser(userFromUrl)
      setIsUpdateUserDialogOpen(true)
    }
  }, [userFromUrl])

  const handleChangePage = (_, value) => setPage(value)

  const handleInviteUser = () => setIsInviteUserDialogOpen(true)

  const handleCloseConfigrWithCheck = () => setIsConfirmWithCheckOpen(false)

  const handleFiltersChange = (filters) => setFilters(filters)

  const handleOnEdit = (user) => {
    setSelectedUser(user)
    setIsUpdateUserDialogOpen(true)
  }

  const handleCloseUpdateUser = () => {
    setIsUpdateUserDialogOpen(false)
    setSelectedUser(false)
  }

  const openDeleteDialog = (user) => {
    setConfirmDialogData({
      icon: (
        <DeleteRoundedIcon
          size={60}
          color="#ef5350"
        />
      ),
      questionText: t('users.doYouWantToDeleteTheUser') + '?',
      subQuestionText: `${user.firstName} ${user.lastName}`,
      copyText: user.uid,
      bodyText: t('users.confirmUserKeyToProceed'),
      inputSuggestionText: t('users.pasteUserKey')
    })
    setSelectedUser(user)
    setIsConfirmWithCheckOpen(true)
  }

  const handleConfirmAction = () => {
    useDeleteUser({
      uid: selectedUser.uid,
      organizationKey: selectedUser.organization.key
    })
      .then(() => {
        setSelectedUser(null)
        openSuccessAlert()
        setIsConfirmWithCheckOpen(false)
        fetchUsers()
      })
      .catch((err) => openErrorAlert(err.data.keys))
  }

  const usersList = users?.data.map((user, i) => (
    <UserCard
      index={i}
      key={`user-${i}`}
      user={user}
      onDelete={openDeleteDialog}
      onEdit={handleOnEdit}
    />
  ))

  const usersFilters = (
    <UserFilters
      onChange={handleFiltersChange}
      filters={filters}
    />
  )

  return (
    <Container
      maxWidth="lg"
      sx={{ py: 3 }}>
      <Grid2
        container
        justifyContent="space-between"
        alignItems="flex-start">
        <Grid2>
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={2}>
            <Typography variant="h2">{t('common.users')}</Typography>
            <Chip
              label={users?.count}
              color="primary"
            />
          </Stack>
        </Grid2>
        <Can action={'users_c'}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleInviteUser}>
            {t('invites.add')}
          </Button>
        </Can>
      </Grid2>

      <ZList
        count={users?.count}
        entities={usersList}
        filters={usersFilters}
        page={page}
        onPageChange={handleChangePage}
        isLoading={isFetchUsersError || isFetchUsersLoading}
      />

      <InviteUser
        isOpen={isInviteUserDialogOpen}
        onClose={() => setIsInviteUserDialogOpen(false)}
      />
      <UpdateUser
        isOpen={isUpdateUserDialogOpen}
        onClose={handleCloseUpdateUser}
        selectedUser={selectedUser}
      />

      {confirmDialogData && (
        <ConfirmWithCheckDialog
          isOpen={isConfirmWithCheckOpen}
          onClose={handleCloseConfigrWithCheck}
          onConfirmAction={handleConfirmAction}
          icon={confirmDialogData.icon}
          questionText={confirmDialogData.questionText}
          subQuestionText={confirmDialogData.subQuestionText}
          copyText={confirmDialogData.copyText}
          bodyText={confirmDialogData.bodyText}
          inputSuggestionText={confirmDialogData.inputSuggestionText}
        />
      )}
    </Container>
  )
}

export default UsersList
