import { Autocomplete, Chip, Stack, TextField, Typography } from '@mui/material'
import { t } from 'i18next'
import Joi from 'joi'
import { useEffect, useState } from 'react'

import useFieldValidation from '../../../helpers/fieldValidation'
import { useAuth } from '../../Shared/Contexts/AuthContext'
import { useFilters } from '../../Shared/Contexts/FiltersContext'

const schema = Joi.object({
  organization: Joi.string().empty(null).required(),
  name: Joi.string()
    .empty(null)
    .regex(/^[A-Za-z0-9.:,_()èùéàòì-\s]{1,110}$/i)
    .required(),
  serial: Joi.string().allow(null, '').default(null),
  description: Joi.string().allow(null, '').default(null),
  tags: Joi.array().items(
    Joi.string()
      .empty(null)
      .regex(/^[A-Za-z0-9.:,_()#&'\/\-\s]{1,50}$/i)
  )
})

const UpdateDeviceInfo = ({ deviceData, onChange }) => {
  const { useFetchSelfOrganizations } = useAuth()
  const { useFetchTagsFilters } = useFilters()

  const [validation, setValidation] = useState({ isValid: false })
  const [updatedDeviceData, setUpdatedDeviceData] = useState({
    organization: deviceData.device.organizationKey,
    name: deviceData.device.name,
    serial: deviceData.device.serial,
    description: deviceData.device.description,
    tags: deviceData.device.tags
  })
  const [organizationsSuggestions, setOrganizationsSuggestions] = useState()
  const [
    isOrganizationsSuggestionsLoading, setIsOrganizationsSuggestionsLoading
  ] = useState(false)
  const [tags, setTags] = useState([])
  const [isTagsLoading, setIsTagsLoading] = useState(false)

  useEffect(() => {
    const validationResults = useFieldValidation(updatedDeviceData, schema)
    setValidation(validationResults)
    onChange(updatedDeviceData, validationResults.isValid)
  }, [updatedDeviceData])

  useEffect(() => {
    setIsOrganizationsSuggestionsLoading(true)
    setIsTagsLoading(true)

    useFetchSelfOrganizations()
      .then((res) => {
        setOrganizationsSuggestions(res.data)
      })
      .finally(() => {
        setIsOrganizationsSuggestionsLoading(false)
      })

    useFetchTagsFilters()
      .then((res) => {
        setTags(res.data)
      })
      .finally(() => {
        setIsTagsLoading(false)
      })
  }, [])

  const handleChangeOrganization = (_, value) => {
    setUpdatedDeviceData({
      ...updatedDeviceData,
      organization: value.key
    })
  }

  const handleChangeTags = (_, value) => {
    setUpdatedDeviceData({
      ...updatedDeviceData,
      tags: [...value]
    })
  }

  const handleChangeName = (event) => {
    setUpdatedDeviceData({
      ...updatedDeviceData,
      name: event.target.value
    })
  }

  const handleChangeSerialNumber = (event) => {
    setUpdatedDeviceData({
      ...updatedDeviceData,
      serial: event.target.value
    })
  }

  const handleChangeDescription = (event) => {
    setUpdatedDeviceData({
      ...updatedDeviceData,
      description: event.target.value
    })
  }

  return (
    <>
      <Stack
        direction="column"
        spacing={5}
      >
        <Typography
          variant="h2"
          py={5}
        >
          {t('devices.addInfo')}
        </Typography>

        {organizationsSuggestions && (
          <Autocomplete
            id="update-device-choose-organization"
            name="organizationKey"
            sx={{ width: '50%' }}
            disableClearable
            loading={isOrganizationsSuggestionsLoading}
            options={organizationsSuggestions}
            getOptionLabel={(option) => option.name}
            isOptionEqualToValue={(option, value) => option.key === value.key}
            value={
              organizationsSuggestions.find(
                (option) => option.key === updatedDeviceData.organization
              ) || ''
            }
            onChange={handleChangeOrganization}
            renderInput={(params) => (
              <TextField
                name="organizationKey"
                {...params}
                label={t('devices.chooseOrganization')}
                variant="standard"
                required
                error={validation?.messages?.organization?.length > 0}
                helperText={validation?.messages?.organization?.join(', ')}
              />
            )}
          />
        )}
        <Stack
          direction="row"
          spacing={2}
          justifyContent="space-between"
        >
          <TextField
            id="update-device-info-name"
            label={t('common.name')}
            name="name"
            variant="standard"
            error={validation.messages?.name?.length > 0}
            helperText={validation.messages?.name?.join(', ')}
            fullWidth
            required
            value={updatedDeviceData?.name || ''}
            onChange={handleChangeName}
          />

          <TextField
            label={t('devices.serialNumber')}
            name="serial"
            variant="standard"
            fullWidth
            error={validation.messages?.serial?.length > 0}
            helperText={validation.messages?.serial?.join(', ')}
            value={updatedDeviceData?.serial || ''}
            onChange={handleChangeSerialNumber}
          />
        </Stack>
        <TextField
          label={t('common.description')}
          name="description"
          variant="standard"
          multiline
          rows={4}
          fullWidth
          error={validation.messages?.description?.length > 0}
          helperText={validation.messages?.description?.join(', ')}
          value={updatedDeviceData?.description || ''}
          onChange={handleChangeDescription}
        />

        <Autocomplete
          multiple
          id="tags-filled"
          name="tags"
          loading={isTagsLoading}
          options={tags.map((option) => option.name)}
          fullWidth
          freeSolo
          onChange={handleChangeTags}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                key={index}
                variant="standard"
                label={option}
                {...getTagProps({ index })}
              />
            ))
          }
          value={updatedDeviceData.tags || ''}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              label="Tags"
              placeholder="Tags"
              error={validation.messages?.tags?.length > 0}
              helperText={validation.messages?.tags?.join(', ')}
            />
          )}
        />
      </Stack>
    </>
  )
}

export default UpdateDeviceInfo
