import { Divider } from '@mui/material'

import EventStatusFilter from '../Fields/EventStatus.jsx'
import EventTypeFilter from '../Fields/EventType.jsx'

const FiltersOthers = ({ filters, onChange = () => { } }) => {
  return (
    <>
      <EventStatusFilter
        filters={filters}
        onChange={(value) => onChange({
          ...filters,
          status: value
        })}
      />
      <Divider />
      <EventTypeFilter
        filters={filters}
        onChange={(value) => onChange({
          ...filters,
          triggerTypes: value
        })}
      />
    </>
  )
}

export default FiltersOthers
