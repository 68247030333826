import { Typography } from '@mui/material'
import { ResponsiveLine } from '@nivo/line'

const buildLineChartData = (id, data) => {
  return [
    {
      id,
      data
    }
  ]
}

const NetworkChart = ({ data, measureName, unitOfMeasure }) => {
  return (
    <ResponsiveLine
      data={buildLineChartData(measureName, data)}
      margin={{
        top: 10,
        right: 10,
        bottom: 150,
        left: 60
      }}
      tooltip={({ point }) => {
        return (
          <div
            style={{
              background: 'white',
              padding: '9px 12px',
              border: '1px solid #ccc'
            }}
          >
            <Typography variant="body2">
              <small>
                {point.data.xFormatted}
                <br />
                <strong>{point.data.yFormatted}</strong> {unitOfMeasure}
              </small>
            </Typography>
          </div>
        )
      }}
      axisBottom={{
        orient: 'bottom',
        tickSize: 5,
        tickPadding: 8,
        tickRotation: -90,
        format: '%d/%m %H:%M'
      }}
      axisLeft={{
        legend: unitOfMeasure,
        legendOffset: -40,
        legendPosition: 'middle'
      }}
      xScale={{
        format: '%Y-%m-%dT%H:%M:%S.%LZ',
        type: 'time'
      }}
      xFormat="time:%d/%m %H:%M"
      yScale={{
        type: 'linear',
        min: 'auto',
        max: 'auto',
        stacked: true,
        reverse: false,
        clamp: true
      }}
      yFormat=" >-.0d"
      useMesh={true}
      enableArea={true}
      enablePoints={true}
      pointLabel="y"
      pointSize={7}
      pointBorderWidth={1}
      pointBorderColor="#ff00dd"
      colors={{ scheme: 'paired' }}
      areaOpacity={0.5}
      crosshairType="x"
    />
  )
}

export default NetworkChart
