import { FormControl, FormControlLabel, Switch } from '@mui/material'
import { t } from 'i18next'

const IsEnabledFilter = ({ filters, onChange = () => { } }) => {
  const handleChange = (_, value) => {
    onChange({ enabled: value })
  }

  return (
    <>
      <FormControl fullWidth>
        <FormControlLabel
          control={
            <Switch
              onChange={handleChange}
              checked={filters.enabled}
            />
          }
          label={t('common.enabled')}
          labelPlacement="end"
        />
      </FormControl>
    </>
  )
}

export default IsEnabledFilter
