import {
  AccountCircleRounded as AccountCircleRoundedIcon,
  MoreVertRounded as MoreVertRoundedIcon
} from '@mui/icons-material'
import { Avatar, Box, Button, Divider, Typography } from '@mui/material'
import { t } from 'i18next'
import { jwtDecode } from 'jwt-decode'
import { useEffect, useState } from 'react'

import { useAuth } from '../../Shared/Contexts/AuthContext'
import { useGlobalContext } from '../../Shared/Contexts/GlobalContext'
import ProfileMenu from '../ProfileMenu/ProfileMenu.jsx'

const ProfileBadge = () => {
  const {
    currentUser: { currentOrganizationKey, selfOrganizations, isSuperAdmin },
    authState
  } = useAuth()

  const { setLoginDialog } = useGlobalContext()

  const [isProfileMenuOpen, setIsProfileMenuOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const [name, setName] = useState(null)
  const [initials, setInitials] = useState(null)
  const [organization, setOrganization] = useState(null)

  const handleClick = (event) => {
    setIsProfileMenuOpen(!isProfileMenuOpen)
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => setIsProfileMenuOpen(false)

  useEffect(() => {
    const { accessToken = null } = authState || {}
    if (accessToken) {
      const { firstName, lastName } = jwtDecode(accessToken)

      if (firstName && lastName) {
        setInitials(`${firstName[0]}${lastName[0]}`)
        setName(`${firstName} ${lastName}`)
      }
    }
  }, [authState])

  useEffect(() => {
    if (selfOrganizations && selfOrganizations.length > 0) {
      setOrganization(
        selfOrganizations.find((o) => o?.key === currentOrganizationKey)
      )
    }
  }, [selfOrganizations])

  return (
    <>
      {authState.isAuthenticated && (
        <>
          <Button
            id="profile-badge-username"
            variant="text"
            onClick={handleClick}
            sx={{ textTransform: 'unset' }}
          >
            <Box
              alignItems="center"
              display="flex"
            >
              <Box px={2}>
                <Typography
                  component="div"
                  variant="caption"
                >
                  <strong>{name}</strong>
                </Typography>
                {!isSuperAdmin && (
                  <Typography
                    component="div"
                    variant="caption"
                  >
                    <i>{organization?.name}</i>
                  </Typography>
                )}
              </Box>
              <Divider orientation="vertical" />
              <Avatar>{initials}</Avatar>
              <MoreVertRoundedIcon fontSize="small" />
            </Box>
          </Button>
          <ProfileMenu
            anchorEl={anchorEl}
            handleClose={handleClose}
            isOpen={isProfileMenuOpen}
          />
        </>
      )}

      {!authState.isAuthenticated && (
        <Button
          id="profile-badge-button-login"
          onClick={() => setLoginDialog(true)}
        >
          <Box
            alignItems="center"
            display="flex"
          >
            <Box px="20px">
              <Typography
                component="div"
                variant="caption"
              >
                {t('users.signIn')}
              </Typography>
            </Box>
            <Avatar>
              <AccountCircleRoundedIcon fontSize="large" />
            </Avatar>
          </Box>
        </Button>
      )}
    </>
  )
}

export default ProfileBadge
