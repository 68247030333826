import moment from 'moment-timezone'
import { createContext, useContext, useReducer, useState } from 'react'

import sanitizer from '../../../helpers/sanitizer.js'
import { decodeFromBase64Url, encodeInBase64Url, putIntoUrl, readFromUrl } from '../../../helpers/utilis.js'
import i18n from '../../../i18n/index.js'
import { useAuth } from './AuthContext.jsx'

const GlobalContext = createContext({})

export const useGlobalContext = () => {
  return useContext(GlobalContext)
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'setTheme':
      return {
        ...state,
        theme: action.payload
      }
    case 'setLanguage':
      return {
        ...state,
        language: action.payload
      }

    case 'setMap':
      return {
        ...state,
        map: action.payload
      }

    case 'setMapCenter':
      return {
        ...state,
        map: {
          ...state.map,
          center: action.payload
        }
      }

    case 'setMapZoom':
      return {
        ...state,
        map: {
          ...state.map,
          zoom: action.payload
        }
      }

    case 'setDateRange':
      return {
        ...state,
        dateRange: {
          from: action.payload.from.utc().format(),
          to: action.payload.to.utc().format()
        }
      }

    case 'setDevicesFilters': {
      return {
        ...state,
        devicesFilters: action.payload
      }
    }

    default:
      throw new Error(`Reducer error: unknown action type ${action.type}`)
  }
}

const getDeviceFilters = () => {
  const defaultFilters = {
    organizationKeys: [],
    showPublic: null,
    showOffline: null,
    showHidden: null,
    brandIds: [],
    modelIds: [],
    tags: [],
    tagSearchMode: null,
    searchInSubOrganizations: false
  }

  const filters = readFromUrl('filters')

  if (filters) {
    return decodeFromBase64Url(filters)
  }

  return defaultFilters
}

export const GlobalContextProvider = ({ children }) => {
  const { useBaseAxiosGet, useBaseAxiosPost } = useAuth()

  const [state, dispatch] = useReducer(reducer, {
    theme: 'light',
    language: 'it',
    dateRange: {
      from: moment().tz('Europe/Rome').subtract(5, 'days').startOf('day').utc().format(),
      to: moment().tz('Europe/Rome').endOf('day').utc().format()
    },
    map: {
      center: {
        lat: 45.745,
        lng: 9.823
      },
      zoom: 10
    },
    devicesFilters: getDeviceFilters()
  })

  const useGlobalSearch = async ({ entityType, query }) => {
    return await useBaseAxiosGet({
      url: `/watson/search?query=${query}${entityType ? `&entityType=${entityType}` : ''}`
    })
  }

  //TODO 04-12-2024 spostare in devices
  const useFetchMarkers = async ({ filters }) => {
    const markerFilters = structuredClone(filters)
    delete markerFilters.searchInSubOrganizations
    return await useBaseAxiosPost({
      url: '/devices/markers',
      payload: sanitizer(markerFilters)
    })
  }

  const setLanguage = (language) => {
    i18n.changeLanguage(language)
    dispatch({
      type: 'setLanguage',
      payload: language
    })
  }

  const setTheme = (theme) => dispatch({
    type: 'setTheme',
    payload: theme
  })

  const setMap = ({ zoom, lat, lng }) => dispatch({
    type: 'setMap',
    payload: {
      center: {
        lat,
        lng
      },
      zoom
    }
  })

  const setMapCenter = ({ lat, lng }) => dispatch({
    type: 'setMapCenter',
    payload: {
      lat,
      lng
    }
  })

  const setMapZoom = ({ zoom }) => dispatch({
    type: 'setMapZoom',
    payload: zoom
  })

  const setDateRange = (dateRange) => dispatch({ type: 'setDateRange', payload: dateRange })

  const setDevicesFilters = (filters) => {
    const encoded = encodeInBase64Url(filters)
    putIntoUrl(encoded, 'filters')
    dispatch({
      type: 'setDevicesFilters',
      payload: filters
    })
  }

  const [loginDialog, setLoginDialog] = useState(false)
  const [resetPasswordDialog, setResetPasswordDialog] = useState(false)
  const [resetPasswordSuccessDialog, setResetPasswordSuccessDialog] = useState(false)
  const [errorAlert, setErrorAlert] = useState(false)
  const [errorAlertMessage, setErrorAlertMessage] = useState('')
  const [successAlert, setSuccessAlert] = useState(false)

  const openSuccessAlert = () => {
    setSuccessAlert(true)
  }

  const openErrorAlert = (errorObject = {}) => {
    const { keys = [] } = errorObject
    setErrorAlert(true)
    setErrorAlertMessage(keys)
  }

  const value = {
    theme: state.theme,
    language: state.theme,
    dateRange: state.dateRange,
    map: state.map,
    devicesFilters: state.devicesFilters,
    setLanguage,
    setTheme,
    setMap,
    useGlobalSearch,
    useFetchMarkers,
    setDateRange,
    setMapCenter,
    setMapZoom,
    setDevicesFilters,
    loginDialog,
    setLoginDialog,
    resetPasswordDialog,
    setResetPasswordDialog,
    resetPasswordSuccessDialog,
    setResetPasswordSuccessDialog,
    errorAlert,
    setErrorAlert,
    errorAlertMessage,
    setErrorAlertMessage,
    successAlert,
    setSuccessAlert,
    openSuccessAlert,
    openErrorAlert
  }

  return <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>
}
