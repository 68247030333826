import { Autocomplete, Box, FormControl, Grid2, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import { t } from 'i18next'

import Can from '../../../Layout/Can/Can'
import { useAuth } from '../../../Shared/Contexts/AuthContext'

const SelectAction = ({ action, onChangeAction, onChangeOrganization }) => {
  const {
    currentUser: { isSuperAdmin, selfOrganizations }
  } = useAuth()

  const handleActionChange = ({ target: { value } }) => onChangeAction(value)

  const handleChangeOrganization = (_, organization) =>
    onChangeOrganization(organization)

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid2
        container
        spacing={2}
      >
        <Grid2
          display="flex"
          justifyContent="flex-start"
          size={6}
        >
          <FormControl fullWidth>
            <InputLabel id="action-select-label">
              {t('actions.selectAnAction')}
            </InputLabel>
            <Select
              labelId="action-select-label"
              id="action-select"
              value={action || ''}
              label={t('actions.selectAnAction')}
              onChange={handleActionChange}
            >
              <MenuItem value="downlink">{t('actions.downlink')}</MenuItem>
              <MenuItem value="alarm">{t('actions.alarm')}</MenuItem>
              <MenuItem value="notification">
                {t('actions.notification')}
              </MenuItem>
              <MenuItem value="webhook">{t('actions.webhook')}</MenuItem>
            </Select>
          </FormControl>
        </Grid2>
        <Grid2
          display="flex"
          justifyContent="flex-start"
          size={6}
        >
          <Can
            action={'devices_u'}
            expression={() => isSuperAdmin}
          >
            <Autocomplete
              id="select-action-organizations-autocomplete"
              name="organizationKey"
              disableClearable
              fullWidth
              options={selfOrganizations}
              getOptionLabel={(option) => option.name}
              isOptionEqualToValue={(option, value) => option.key === value.key}
              onChange={handleChangeOrganization}
              renderInput={(params) => (
                <TextField
                  name="organizationKey"
                  {...params}
                  label={t('devices.chooseOrganization')}
                  required={true}
                />
              )}
            />
          </Can>
        </Grid2>
      </Grid2>
    </Box>
  )
}

export default SelectAction
