import { Divider } from '@mui/material'

import { ActionTypeFilter, IsUsedInRuleFilter } from './index.js'

const ActionsOthersFilters = ({ filters = {}, onChange = () => { } }) => {
  return (
    <>
      <IsUsedInRuleFilter
        filters={filters}
        onChange={onChange}
      />
      <Divider />
      <ActionTypeFilter
        filters={filters}
        onChange={onChange}
      />
    </>
  )
}

export default ActionsOthersFilters
