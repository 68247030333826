import {
  ChevronRightRounded as ChevronRightRoundedIcon,
  ExpandMoreRounded as ExpandMoreRoundedIcon,
  WifiTetheringRounded as WifiTetheringRoundedIcon
} from '@mui/icons-material'
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  Collapse,
  Divider,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material'
import { t } from 'i18next'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { useGlobalContext } from '../../Shared/Contexts/GlobalContext.jsx'
import ActionFooter from './ActionFooter.jsx'
import ActionHeader from './ActionHeader.jsx'

const DeviceCard = ({ device, onDelete, onEdit, onAction, onEvents, id }) => {
  const { setMap } = useGlobalContext()

  const navigate = useNavigate()

  const [expanded, setExpanded] = useState(false)

  const handleClick = (id, position) => {
    position?.latitude && position?.longitude
      ? setMap({
        lat: position.latitude,
        lng: position.longitude,
        zoom: 20
      })
      : setMap({
        lat: 0,
        lng: 0,
        zoom: 3
      })

    navigate(`/detail/${id}`)
  }

  const cardStyle = {
    overflow: 'visible',
    border: '1px solid #cdcdcd',
    background: 'radial-gradient( 80px circle at 30px 30px, rgba(223, 253, 225, 0.4) 50%, transparent 51% )'
  }

  return (
    <>
      <Card
        sx={{ marginBottom: '40px' }}
        style={device.isAlive ? cardStyle : null}>
        <CardHeader
          avatar={
            <WifiTetheringRoundedIcon
              color={device.isAlive ? 'success' : '#000'}
              size={28}
            />
          }
          title={
            <Typography
              variant="body2"
              color="textSecondary"
              component="p">
              {device?.address}
            </Typography>
          }
          action={
            <ActionHeader
              isPublic={device?.public}
              isHidden={device?.hidden}
            />
          }
        />

        <CardContent>
          <Typography variant="h6">{device?.organizationKey}</Typography>

          <Typography
            component="h3"
            onClick={() => handleClick(device.id, device.position)}
            sx={{
              cursor: 'pointer'
            }}
            id={`${id}-name`}>
            <strong>{device?.name}</strong>
          </Typography>

          <Typography variant="body2">
            {device.brand}/{device.model}
          </Typography>

          <Stack
            mt={1}
            direction="row"
            spacing={1}>
            {device?.tags?.map((t) => (
              <Chip
                size="small"
                label={t}
                key={t}
                color="secondary"
              />
            ))}
          </Stack>
        </CardContent>

        <CardContent>
          <Stack
            direction="row"
            alignItems="center">
            <Typography variant="body">{t('devices.measures')}</Typography>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setExpanded(!expanded)}>
              {expanded ? <ExpandMoreRoundedIcon /> : <ChevronRightRoundedIcon />}
            </IconButton>
          </Stack>
        </CardContent>

        <Collapse
          in={expanded}
          aria-expanded={expanded}
          aria-label="show-more"
          timeout="auto"
          unmountOnExit>
          <CardContent>
            <TableContainer component={Box}>
              <Table
                aria-label="measures table"
                size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>{t('devices.measureName')}</TableCell>
                    <TableCell>{t('devices.measureNameDoc')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {device.quantities.map((row) => (
                    <TableRow
                      key={row.name}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <TableCell
                        component="th"
                        scope="row">
                        {t(row.name)}
                      </TableCell>
                      <TableCell>{row.measureName}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
        </Collapse>

        <Divider variant="middle" />

        <CardActions>
          <ActionFooter
            device={device}
            actionsDisabled={device.actions.length === 0}
            onDelete={onDelete}
            onEdit={onEdit}
            onAction={onAction}
            onEvents={onEvents}
          />
        </CardActions>
      </Card>
    </>
  )
}

export default DeviceCard
