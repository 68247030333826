import { forwardRef } from 'react'
import { IMaskInput } from 'react-imask'

const Appskey = forwardRef(function Appskey(props, ref) {
  const { onChange, ...other } = props

  return (
    <IMaskInput
      {...other}
      mask={/^[0-9a-f]{0,32}$/i}
      inputRef={ref}
      onAccept={(value) => {
        onChange({
          target: {
            name: props.name,
            value: value.toLowerCase()
          }
        })
      }}
      overwrite
      prepareChar={(str) => {
        return str.toUpperCase()
      }}
    />
  )
})

export default Appskey
