import { Save as SaveIcon } from '@mui/icons-material'
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Divider,
  FormControlLabel,
  TextField
} from '@mui/material'
import { t } from 'i18next'
import Joi from 'joi'
import { useState } from 'react'

import useFieldValidation from '../../../../helpers/fieldValidation.js'
import { useValidationEffect } from '../../../../helpers/hooks.js'
import Can from '../../../Layout/Can/Can.jsx'
import Show from '../../../Layout/Can/Show.jsx'
import { useAuth } from '../../../Shared/Contexts/AuthContext.jsx'
import { schedulerTypes } from '../../utils/utilis.js'
import DeviceField from './DeviceField.jsx'
import SchedulerHoursSelectionForm from './SchedulerHoursSelectionForm.jsx'
import TriggerComponent from './TriggerComponent.jsx'
import TriggerRuleType from './TriggerRuleType.jsx'
import ZRadioGroup from './ZRadioGroup.jsx'

const validationSchema = {
  'measure-trigger': Joi.object({
    quantityId: Joi.string().required(),
    quantity: Joi.object().required(),
    models: Joi.array().min(1)
  }).options({ allowUnknown: true }),

  communication: Joi.object({
    models: Joi.array().min(1)
  }).options({ allowUnknown: true }),

  trigger: Joi.object({
    models: Joi.array().min(1)
  }).options({ allowUnknown: true }),

  'scheduled-trigger': Joi.object().options({ allowUnknown: true }),

  threshold: Joi.object({
    quantityId: Joi.string().required(),
    models: Joi.array().min(1),
    trigger: Joi.object().required()
  }).options({ allowUnknown: true }),

  'measure-communication': Joi.object({
    models: Joi.array().min(1),
    missingComunicationQuantity: Joi.object({
      timeDelta: Joi.number().positive().required()
    }).required()
  }).options({ allowUnknown: true }),

  delta: Joi.object({
    quantity: Joi.object().required(),
    models: Joi.array().min(1),
    trigger: Joi.object().required()
  }).options({ allowUnknown: true })
}

const schema = Joi.object({
  name: Joi.string().empty(null).required()
})

const saSchema = Joi.object({
  name: Joi.string().empty(null).required(),
  organizationKey: Joi.string().required()
})

const TriggerForm = ({ triggerData, isEdit, onSave, onChange, onEnableEdit, readonly = false }) => {
  const {
    currentUser: { isSuperAdmin, selfOrganizations, currentOrganizationKey }
  } = useAuth()

  const [selectedOrganization, setSelectedOrganization] = useState(null)

  const [validation, setValidation] = useState({ isValid: false })
  const [triggerValidation, setTriggerValidation] = useState({ isValid: false })

  const {
    triggerType = !!triggerData.device ? 'device' : 'quantity',
    name,
    isForSuperAdmin,
    applyToChildOrganizations,
    models,
    type,
    schedulerType,
    scheduler,
    sendReminder,
    thresholdsCounter,
    trigger,
    device,
    quantity,
    quantityId,
    revision,
    missingComunicationQuantity
  } = triggerData

  useValidationEffect(() => {
    const headerValidation =
      isSuperAdmin && !isEdit
        ? useFieldValidation({
          organizationKey: selectedOrganization?.key,
          name
        }, saSchema)
        : useFieldValidation({ name }, schema)

    const triggerValidation = validationSchema[type] ? useFieldValidation(triggerData, validationSchema[type]) : { isValid: true }

    setValidation(headerValidation)
    setTriggerValidation(triggerValidation)
  }, [selectedOrganization, triggerData])

  const handleChangeOrganization = (_, organization) => setSelectedOrganization(organization)

  const handleRuleType = ({ target: { value } }) => {
    onChange({
      ...triggerData,
      missingComunicationQuantity: value === 'measure-communication' ? { timeDelta: null } : null,
      schedulerType: value === 'scheduled-trigger' ? schedulerTypes.SCHEDULED : schedulerTypes.DEFAULT,
      type: value
    })
  }

  const handleTriggerType = ({ target: { value } }) =>
    onChange({
      ...triggerData,
      scheduler: triggerData.scheduler,
      schedulerType: triggerData.schedulerType,
      name: triggerData.name,
      device: null,
      triggerType: value
    })

  const onSendReminder = (_event, value) => onChange({
    ...triggerData,
    sendReminder: value
  })

  const handleTriggerName = ({ target: { value } }) => onChange({
    ...triggerData,
    name: value
  })

  const handleSchedulerType = (type) => {
    resetScheduler()
    onChange({
      ...triggerData,
      schedulerType: type
    })
  }

  const handleSchedulerChange = (scheduler) => onChange({
    ...triggerData,
    scheduler
  })

  const resetScheduler = () => {
    const newScheduler = {}
    Object.keys(scheduler).map((key) => (newScheduler[key] = {
      selected: false,
      timeSlots: []
    }))
    onChange({
      ...triggerData,
      scheduler: newScheduler
    })
  }

  const handleOnClickSaveButton = () => {
    const payload = {
      deviceId: device ? device.id : null,
      name,
      isForSuperAdmin,
      applyToChildOrganizations,
      models: models,
      type: type,
      schedulerType: type === 'scheduled-trigger' ? schedulerTypes.SCHEDULED : schedulerType,
      scheduler,
      sendReminder,
      thresholdsCounter,
      trigger,
      quantityId: quantity?.id || quantityId,
      revision,
      missingComunicationQuantity: missingComunicationQuantity
    }
    isSuperAdmin && !isEdit
      ? onSave({
        ...payload,
        organizationKey: selectedOrganization.key
      })
      : onSave({
        ...payload,
        organizationKey: currentOrganizationKey
      })
  }

  const handleTriggerChange = (data) => onChange({
    ...triggerData,
    ...data
  })

  const handleDeviceSelected = (device) =>
    onChange({
      ...triggerData,
      scheduler: triggerData.scheduler,
      schedulerType: triggerData.schedulerType,
      name: triggerData.name,
      triggerType: triggerData.triggerType,
      models: [{ id: parseInt(device.modelId) }],
      device: device
    })

  return (
    <>
      <Can
        action={'devices_u'}
        expression={() => !isEdit && isSuperAdmin}>
        <Autocomplete
          id="trigger-form-organizations-autocomplete"
          name="organizationKey"
          disableClearable
          options={selfOrganizations}
          getOptionLabel={(option) => option.name}
          isOptionEqualToValue={(option, value) => option.key === value.key}
          value={selectedOrganization}
          onChange={handleChangeOrganization}
          fullWidth
          renderInput={(params) => (
            <TextField
              name="organizationKey"
              {...params}
              label={t('devices.chooseOrganization')}
              required={true}
              variant="standard"
              error={validation?.messages?.organization?.length > 0}
              helperText={validation?.messages?.organization?.join(', ')}
            />
          )}
          disabled={readonly}
        />
      </Can>
      <TextField
        id="trigger-name"
        label={t('triggers.triggerName')}
        name="triggerName"
        variant="standard"
        fullWidth
        value={triggerData.name}
        error={validation?.messages?.name?.length > 0}
        helperText={validation?.messages?.name?.join(', ')}
        onChange={handleTriggerName}
        disabled={isEdit}
      />

      <Divider
        orientation="horizontal"
        sx={{ my: 3 }}
      />

      <ZRadioGroup
        id={'trigger-form-type-radio'}
        title={t('triggers.triggerDefinition')}
        disabled={isEdit || readonly}
        values={[
          {
            id: 'trigger-form-type-quantity-radio',
            value: 'quantity',
            label: t('triggers.byQuantity')
          },
          {
            id: 'trigger-form-type-device-radio',
            value: 'device',
            label: t('triggers.byDevice')
          }
        ]}
        defaultValue={'quantity'}
        selectedValue={triggerType}
        onChange={handleTriggerType}
      />

      <Divider
        orientation="horizontal"
        sx={{ my: 3 }}
      />

      <Show when={() => triggerType === 'device'}>
        <DeviceField
          device={device}
          isEdit={isEdit}
          onChange={handleDeviceSelected}
        />
      </Show>

      <Card sx={{ marginBottom: '40px' }}>
        <CardContent sx={{
          marginX: '20px',
          marginBottom: '15px'
        }}>
          <TriggerRuleType
            onChange={handleRuleType}
            isEdit={isEdit}
            type={type}
          />

          <TriggerComponent
            type={type}
            isEdit={isEdit}
            selectedOrganizationKey={selectedOrganization?.key}
            validation={triggerValidation}
            trigger={triggerData}
            onChange={handleTriggerChange}
            readonly={readonly}
          />

          <SchedulerHoursSelectionForm
            readonly={readonly}
            type={type}
            schedulerType={schedulerType}
            scheduler={scheduler}
            onChangeType={handleSchedulerType}
            onChangeScheduler={handleSchedulerChange}
          />

          <Divider
            orientation="horizontal"
            sx={{ my: 5 }}
          />

          <Show when={() => type !== 'scheduled-trigger'}>
            <FormControlLabel
              label={t('triggers.sendReminder')}
              control={
                <Checkbox
                  disabled={readonly}
                  id="trigger-form-send-reminder-checkbox"
                  onChange={onSendReminder}
                />
              }
            />
          </Show>
        </CardContent>
      </Card>

      <Box sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'end'
      }}>
        <Show when={() => readonly}>
          <Button
            id="enable-edit-rule"
            variant="contained"
            onClick={onEnableEdit}>
            {t('common.enableEdit')}
          </Button>
        </Show>
        <Show when={() => !readonly}>
          <Button
            variant="contained"
            id="trigger-form-save"
            endIcon={<SaveIcon />}
            disabled={(!validation.isValid || !triggerValidation.isValid) && !isEdit}
            onClick={handleOnClickSaveButton}>
            {t('common.save')}
          </Button>
        </Show>
      </Box>
    </>
  )
}

export default TriggerForm
