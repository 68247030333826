import { CorporateFareRounded as CorporateFareRoundedIcon } from '@mui/icons-material'
import {
  Autocomplete,
  CircularProgress,
  FormControlLabel,
  Icon,
  InputAdornment,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Switch,
  TextField
} from '@mui/material'
import { t } from 'i18next'

import { useAuth } from '../../Contexts/AuthContext'

const OrganizationsFilter = ({ onChange = () => { }, filters = {} }) => {
  const {
    currentUser: { selfOrganizations }
  } = useAuth()

  const handleChange = (_, value) => {
    const organizationKeys = value.map((i) => i.key)
    onChange({
      organizationKeys,
      searchInSubOrganizations: !organizationKeys.length ? false : filters.searchInSubOrganizations
    })
  }

  const handleChangeSwitch = () => {
    onChange({
      organizationKeys,
      searchInSubOrganizations: !filters.searchInSubOrganizations
    })
  }

  const { organizationKeys = [] } = filters

  return (
    <>
      <Stack
        direction="column"
        justifyContent="space-between"
        alignItems="left"
        spacing={2}
        mt={3}
        mb={3}>
        <Autocomplete
          multiple
          id="organizations-filter"
          options={selfOrganizations}
          onChange={handleChange}
          isOptionEqualToValue={(option, value) => option.key === value.key}
          getOptionLabel={(option) => option.name}
          value={selfOrganizations.filter(({ key }) => organizationKeys.includes(key))}
          renderOption={(props, option) => (
            <ListItem
              {...props}
              divider
              key={option.key}>
              <ListItemIcon>
                <CorporateFareRoundedIcon fontSize="large" />
              </ListItemIcon>
              <ListItemText
                primaryTypographyProps={{
                  fontSize: '0.8rem',
                  fontWeight: 500
                }}
                primary={option.name}
              />
            </ListItem>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder={t('filters.devices.organizations.placeholder')}
              slotProps={{
                ...params.InputProps,
                endAdornment: (
                  <InputAdornment position="end">
                    <Icon
                      color="inherit"
                      edge="end">
                      {!selfOrganizations && (
                        <CircularProgress
                          edge="end"
                          size={20}
                        />
                      )}
                    </Icon>
                  </InputAdornment>
                )
              }}
            />
          )}
        />
        <FormControlLabel
          label={t('filters.organizations.searchInSubOrganizations')}
          control={
            <Switch
              id="search-in-child-organizations"
              disabled={filters?.organizationKeys?.length > 0 ? false : true}
              checked={filters.searchInSubOrganizations || false}
              onChange={handleChangeSwitch}
            />
          }
        />
      </Stack>
    </>
  )
}

export default OrganizationsFilter
