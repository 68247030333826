import { LinkedIn as LinkedInIcon, Mail as MailIcon, X as XIcon, YouTube as YouTubeIcon } from '@mui/icons-material'
import { Box, Button, ButtonGroup, Container, Link, Stack, Typography } from '@mui/material'
import { t } from 'i18next'

import LogoA2AColor from '../../../images/logocolor.svg'
import LogoA2AWhite from '../../../images/logowhite.svg'
import { useGlobalContext } from '../../Shared/Contexts/GlobalContext'

const Footer = () => {
  const { theme } = useGlobalContext()

  return (
    <Box bgcolor={theme === 'light' ? '#E9EFF5' : '#2d363f'}>
      <Container maxWidth="xl">
        <Stack
          justifyContent="space-between"
          direction={{
            xs: 'column',
            sm: 'row'
          }}
          spacing={{
            xs: 1,
            sm: 2,
            md: 4
          }}
        >
          <Box py={2}>
            <Box width="120px">
              {theme === 'light' ? (
                <Box
                  component="img"
                  sx={{ height: '50px' }}
                  src={LogoA2AColor}
                />
              ) : (
                <Box
                  component="img"
                  sx={{ height: '50px' }}
                  src={LogoA2AWhite}
                />
              )}
            </Box>
          </Box>

          <Box py={2}>
            <Typography variant="body2">
              <strong>Bergamo</strong>
            </Typography>
            <Typography
              variant="body2"
              mb={1}
            >
              Via Suardi, 26
            </Typography>
          </Box>

          <Box py={2}>
            <Typography variant="body2">
              <strong>Brescia</strong>
            </Typography>
            <Typography variant="body2">Via A. Lamarmora, 230</Typography>
          </Box>

          <Box py={2}>
            <Typography variant="body2">
              <strong>Cremona</strong>
            </Typography>
            <Typography
              variant="body2"
              mb={1}
            >
              Via dell&apos;Innovazione Digitale, 8
            </Typography>
          </Box>

          <Box py={2}>
            <Typography
              variant="h6"
              sx={{
                textTransform: 'uppercase',
                fontWeight: 700
              }}
            >
              {t('common.contacts')}
            </Typography>
            <Link
              href="mailto:info@a2asmartcity.it"
              variant="body2"
              color={'inherit'}
            >
              info@a2asmartcity.it
            </Link>
            <br />
            <Link
              href="https://www.a2asmartcity.it/contatti/"
              variant="body2"
              color={'inherit'}
            >
              Contattaci
            </Link>
          </Box>
        </Stack>

        <Stack
          justifyContent="center"
          alignItems="center"
          direction="column"
        >
          <ButtonGroup size="small">
            <Button
              variant="text"
              key="twitter"
              href="https://twitter.com/A2ASmartCity"
            >
              <XIcon />
            </Button>
            <Button
              variant="text"
              key="linkedin"
              href="https://www.linkedin.com/showcase/a2a-smart-city/"
            >
              <LinkedInIcon />
            </Button>
            <Button
              variant="text"
              key="youtube"
              href="https://www.youtube.com/channel/UCHczlhVciqpwLdUweQTJXJQ"
            >
              <YouTubeIcon />
            </Button>
            <Button
              variant="text"
              key="mail"
              href="mailto:info@a2asmartcity.it"
            >
              <MailIcon />
            </Button>
          </ButtonGroup>

          <Typography variant="body2">
            <small>
              Copyright ©{new Date().getFullYear()} A2A Smart city - P.I.
              02159020177 - www.a2asmartcity.it
            </small>
          </Typography>
        </Stack>
      </Container>
    </Box>
  )
}

export default Footer
