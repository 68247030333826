import { DeleteRounded as DeleteRoundedIcon, LockRounded as LockRoundedIcon } from '@mui/icons-material'
import { Box, Pagination, Skeleton, Stack } from '@mui/material'
import { t } from 'i18next'
import { useEffect, useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'

import { useGlobalContext } from '../../Shared/Contexts/GlobalContext.jsx'
import ConfirmWithCheckDialog from '../../Shared/Dialogs/ConfirmWithCheckDialog.jsx'
import OrganizationCard from '../Card/OrganizationCard.jsx'
import { useOrganizations } from '../Contexts/OrganizationsContext.jsx'
import OrganizationAddDialog from '../Dialogs/OrganizationAdd.jsx'
import OrganizationEditDialog from '../Dialogs/OrganizationEdit'
import OrganizationsFilters from '../Filters/Filters.jsx'

const DEFAULT_PAGE_SIZE = 25

const Organizations = ({ onOrganizationsCountUpdate, isAddOrganizationDialogOpen, onOrganizationAddDialogClose }) => {
  const [searchParams] = useSearchParams()
  const fullText = searchParams.get('search')
  const { organizationId } = useParams()

  const { useFetchOrganizationsWithThresholdsAndFilters, useLockOrganization, useUnlockOrganization, useDeleteOrganization } = useOrganizations()

  const { openErrorAlert, openSuccessAlert } = useGlobalContext()

  const [filters, setFilters] = useState({})

  const [organizations, setOrganizations] = useState({
    data: [],
    count: 0
  })
  const [isFetchOrganizationsLoading, setIsFetchOrganizationsLoading] = useState(false)
  const [selectedOrganization, setSelectedOrganization] = useState(null)
  const [confirmDialogData, setConfirmDialogData] = useState(null)
  const [isEditOrganizationDialogOpen, setIsEditOrganizationDialogOpen] = useState(false)
  const [isConfirmWithCheckOpen, setIsConfirmWithCheckOpen] = useState(false)
  const [openReadonlyDialog, setOpenReadonlyDialog] = useState(false)

  const [page, setPage] = useState(1)

  const fetchOrgs = async ({ page }) => {
    setIsFetchOrganizationsLoading(true)
    useFetchOrganizationsWithThresholdsAndFilters({
      filters,
      fullText,
      page,
      pageSize: DEFAULT_PAGE_SIZE
    })
      .then((res) => setOrganizations(res))
      .finally(() => setIsFetchOrganizationsLoading(false))

    setIsConfirmWithCheckOpen(false)
  }

  const handleCloseConfirmWithCheck = () => setIsConfirmWithCheckOpen(false)

  useEffect(() => {
    fetchOrgs({ page: 1 })
  }, [fullText, filters])

  useEffect(() => {
    fetchOrgs({ page: page })
  }, [page])

  useEffect(() => {
    setOpenReadonlyDialog(true)
    setIsEditOrganizationDialogOpen(true)
  }, [organizationId])

  useEffect(() => {
    onOrganizationsCountUpdate(organizations?.count || 0)
  }, [organizations])

  const handleChangePage = (_, value) => setPage(value)

  const handleSaveOrganization = () => {
    onOrganizationAddDialogClose()
    fetchOrgs({ page: 1 })
  }

  const handleOrganizationEdit = () => {
    fetchOrgs({ page: 1 })
    setOpenReadonlyDialog(false)
    setSelectedOrganization(null)
  }

  const openEditDialog = (organization) => {
    setSelectedOrganization(organization)
    setIsEditOrganizationDialogOpen(true)
  }

  const handleFiltersChange = (filters) => setFilters(filters)

  const handleEnableEdit = () => setOpenReadonlyDialog(false)

  const openDeleteDialog = (organization) => {
    setConfirmDialogData({
      icon: (
        <DeleteRoundedIcon
          id="organization-delete-confirm-dialog-icon"
          size={60}
          color="#ef5350"
        />
      ),
      onConfirmAction: () =>
        useDeleteOrganization(organization.id)
          .then(() => {
            fetchOrgs({ page: 1 })
            setPage(1)
            openSuccessAlert()
          })
          .catch((err) => openErrorAlert({ keys: err.data.keys })),
      questionText: t('organizations.delete') + '?',
      subQuestionText: organization.name,
      copyText: organization.key,
      bodyText: t('organizations.confirmKeyToProceed'),
      inputSuggestionText: t('organizations.pasteKey')
    })
    setIsConfirmWithCheckOpen(true)
  }

  const toggleLockOperation = (organization) => {
    if (organization.locked) useUnlockOrganization(organization.id).then(() => fetchOrgs({ page: 1 }))
    else {
      setConfirmDialogData({
        icon: (
          <LockRoundedIcon
            id="organization-lock-confirm-dialog-icon"
            size={60}
            color="#ef5350"
          />
        ),
        onConfirmAction: () => useLockOrganization(organization.id).then(() => fetchOrgs({ page: 1 })),
        questionText: t('organizations.block') + '?',
        subQuestionText: organization.name,
        copyText: organization.key,
        bodyText: t('organizations.confirmKeyToProceed'),
        inputSuggestionText: t('organizations.pasteKey')
      })
      setIsConfirmWithCheckOpen(true)
    }
  }

  return (
    <>
      <Box sx={{
        my: 3,
        ml: 1
      }}>
        <OrganizationsFilters
          onChange={handleFiltersChange}
          filters={filters}
        />
      </Box>

      {isFetchOrganizationsLoading && (
        <Stack
          spacing={2}
          sx={{
            marginBottom: '20px',
            marginTop: '20px'
          }}>
          {[...Array(DEFAULT_PAGE_SIZE).keys()].map((index) => (
            <Skeleton
              key={`skeleton-${index}`}
              variant="rounded"
              sx={{ height: '230px' }}></Skeleton>
          ))}
        </Stack>
      )}
      {organizations.data.map((o) => (
        <OrganizationCard
          key={o.key}
          isRoot={o.isRoot}
          index={o.key}
          organization={o}
          onEdit={openEditDialog}
          onDelete={openDeleteDialog}
          onLock={toggleLockOperation}
        />
      ))}

      <Box
        display="flex"
        justifyContent="center">
        {organizations.count > DEFAULT_PAGE_SIZE && (
          <Pagination
            count={Math.ceil(organizations.count / DEFAULT_PAGE_SIZE)}
            page={page}
            onChange={handleChangePage}
            showFirstButton
            showLastButton
          />
        )}
      </Box>

      {(selectedOrganization || organizationId) && (
        <OrganizationEditDialog
          isOpen={isEditOrganizationDialogOpen}
          setIsOpen={setIsEditOrganizationDialogOpen}
          organizationId={parseInt(selectedOrganization?.id || organizationId)}
          onOrganizationEdit={handleOrganizationEdit}
          readonly={openReadonlyDialog}
          onEnableEdit={handleEnableEdit}
        />
      )}

      {confirmDialogData && (
        <ConfirmWithCheckDialog
          onClose={handleCloseConfirmWithCheck}
          isOpen={isConfirmWithCheckOpen}
          icon={confirmDialogData.icon}
          onConfirmAction={confirmDialogData.onConfirmAction}
          questionText={confirmDialogData.questionText}
          subQuestionText={confirmDialogData.subQuestionText}
          copyText={confirmDialogData.copyText}
          bodyText={confirmDialogData.bodyText}
          inputSuggestionText={confirmDialogData.inputSuggestionText}
        />
      )}

      <OrganizationAddDialog
        isOpen={isAddOrganizationDialogOpen}
        onClose={onOrganizationAddDialogClose}
        onSave={handleSaveOrganization}
      />
    </>
  )
}

export default Organizations
