import { PowerSettingsNewRounded as PowerSettingsNewRoundedIcon } from '@mui/icons-material'
import { Box, CardContent, Chip, Grid2, Stack, Typography } from '@mui/material'
import { t } from 'i18next'

import Show from '../../Layout/Can/Show.jsx'
import ZCard from '../../Shared/Components/ZCard.jsx'

const RuleCardTrigger = ({ trigger }) => {
  const composeSchedulerString = (s) => {
    return s
      ? Object.entries(s)
        .filter(([, v]) => v.selected)
        .map(([k, v]) => {
          const slots = v.timeSlots.map((s) => [s.startHour, s.endHour].filter(Boolean).join('-'))
          return `[${k} ${slots}] `
        })
      : 'Default 7/7 H24'
  }

  return (
    <CardContent
      sx={{
        marginX: '20px',
        marginBottom: '15px',
        background: 'rgba(30, 250, 150, 0.2)'
      }}>
      <Box>
        <Typography
          component="p"
          variant="body2"
          sx={{ fontWeight: 'bold' }}>
          {trigger?.name}
        </Typography>
        <Grid2 container>
          <Grid2>
            <Typography variant="body2">{t(`triggers.types.${trigger?.type}`)}</Typography>
          </Grid2>
          <Grid2>
            <Show when={() => trigger?.type === 'threshold' && !!trigger?.trigger}>
              <Typography variant="body">
                {`${t(`triggers.operators.${trigger?.trigger?.operator}`)} [${trigger.trigger?.values?.toString()}]`}
              </Typography>
            </Show>
          </Grid2>
          <Grid2>
            <Typography variant="body">{composeSchedulerString(trigger?.scheduler)}</Typography>
          </Grid2>
        </Grid2>
      </Box>
    </CardContent>
  )
}

const RuleCardAction = ({ action }) => {
  return (
    <CardContent
      sx={{
        marginX: '20px',
        marginBottom: '15px',
        background: 'rgba(233, 239, 245, 0.5)'
      }}>
      <Typography
        component="p"
        variant="body2"
        sx={{ fontWeight: 'bold' }}>
        {action.name}
      </Typography>

      <Box sx={{ flexGrow: 1 }}>
        <Grid2
          container
          spacing={2}>
          <Grid2 size={2}>
            <Typography variant="body2">{t(`actions.${action.type}`)}</Typography>
          </Grid2>
          {action.type === 'webhook' && (
            <Grid2 size="grow">
              <Typography variant="body">{action.webhookUrlAction}</Typography>

              {action?.webhookHttpHeaders && (
                <Box mt={2}>
                  <Typography
                    component="p"
                    variant="body2"
                    sx={{ fontWeight: 'bold' }}>
                    Headers
                  </Typography>
                  {Object.keys(action.webhookHttpHeaders).map((k, i) => (
                    <Typography
                      variant="body"
                      component="p"
                      key={i}>
                      <strong>{k} :</strong> {action.webhookHttpHeaders[k]}
                    </Typography>
                  ))}
                </Box>
              )}
            </Grid2>
          )}
          {action.type === 'downlink' && (
            <Grid2 size="grow">
              <Stack
                spacing={3}
                direction={'row'}>
                <Typography
                  component="p"
                  variant="body2"
                  sx={{ fontWeight: 'bold' }}>
                  {t('actions.actionName')}:
                </Typography>

                <Typography
                  component="p"
                  variant="body2">
                  {action.actionName}
                </Typography>
              </Stack>
            </Grid2>
          )}
          <Grid2 size="grow">
            <Typography variant="body"></Typography>
          </Grid2>
        </Grid2>
      </Box>
    </CardContent>
  )
}

const RuleCard = ({ index, rule, onDelete, onEdit }) => {
  return (
    <ZCard
      name={'rule'}
      key={`rule-card-${index}`}
      id={`rule-card-${index}`}
      action="devices_u"
      title={rule.name}
      titleInfo={
        rule.enabled ? (
          <Chip
            icon={<PowerSettingsNewRoundedIcon />}
            label={t('common.active')}
            color="success"
          />
        ) : (
          <Chip
            icon={<PowerSettingsNewRoundedIcon />}
            label={t('common.notActive')}
          />
        )
      }
      subheader={
        rule.trigger?.models?.length && rule.trigger.models[0].id !== '*'
          ? `${rule.trigger?.models[0].brand.name} / ${rule.trigger.models[0].name}`
          : ''
      }
      createdAt={rule.createdAt}
      updatedAt={rule.updatedAt}
      onDelete={() => onDelete(rule)}
      onEdit={() => onEdit(rule)}
      content={
        <>
          <RuleCardTrigger trigger={rule.trigger} />
          {rule.actions.map((item, i) => (
            <RuleCardAction
              key={`action-${i}`}
              action={item}
            />
          ))}
        </>
      }
    />
  )
}

export default RuleCard
