import { Download as DownloadIcon } from '@mui/icons-material'
import { Box, Button, CircularProgress, Grid2, Stack, Typography } from '@mui/material'
import { t } from 'i18next'
import { useEffect, useState } from 'react'

import LineChart from '../../Charts/LineChart.jsx'
import LinkStatusChart from '../../Charts/LinkStatusChart.jsx'
import NetworkChart from '../../Charts/NetworkChart.jsx'
import Can from '../../Layout/Can/Can'
import Show from '../../Layout/Can/Show'
import { useAuth } from '../../Shared/Contexts/AuthContext.jsx'
import { useGlobalContext } from '../../Shared/Contexts/GlobalContext.jsx'
import { useDevices } from '../Contexts/DevicesContext'

const NetworkTab = ({ device, sourceData, refresh, setRefresh }) => {
  const { dateRange } = useGlobalContext()

  const {
    currentUser: { isSuperAdmin }
  } = useAuth()
  const { useFetchDeviceNetwork, useFetchDeviceStatistics, useDeviceStatisticsDownload, useDeviceLinkStatusesDownload } = useDevices()
  const { openErrorAlert } = useGlobalContext()
  const [networkChart, setNetworkChart] = useState([])
  const [statisticsChart, setStatisticsChart] = useState([])

  const quantities = [
    {
      chart: 'network',
      hidden: false,
      labels: null,
      measureId: null,
      measureName: 'sf',
      name: 'network.sf',
      summary: false,
      unitOfMeasure: ''
    },
    {
      chart: 'network',
      hidden: false,
      labels: null,
      measureId: null,
      measureName: 'seqno',
      name: 'network.seqno',
      summary: false,
      unitOfMeasure: ''
    }
  ]

  useEffect(() => {
    if (refresh) {
      useFetchDeviceNetwork({
        id: device.id,
        from: dateRange.from,
        to: dateRange.to
      })
        .then((res) => setNetworkChart(res.data))

      useFetchDeviceStatistics({
        id: device.id,
        from: dateRange.from,
        to: dateRange.to
      })
        .then((res) => setStatisticsChart(res.data))
      setRefresh(false)
    }
  }, [refresh, dateRange])

  useEffect(() => {
    useFetchDeviceNetwork({
      id: device.id,
      from: dateRange.from,
      to: dateRange.to
    }).then((res) => {
      setNetworkChart(res.data)
    })
    useFetchDeviceStatistics({
      id: device.id,
      from: dateRange.from,
      to: dateRange.to
    })
      .then((res) => setStatisticsChart(res.data))
  }, [dateRange])

  const [downloadInProgress, setDownloadInProgress] = useState(false)

  const handleDownloadLinkStatus = () => {
    setDownloadInProgress(true)
    useDeviceLinkStatusesDownload({
      id: device.id,
      name: device.name,
      serial: device.serial,
      from: dateRange.from,
      to: dateRange.to
    })
      .catch(() => openErrorAlert({ keys: ['download'] }))
      .finally(() => setDownloadInProgress(false))
  }

  const handleDownloadStatistics = () => {
    setDownloadInProgress(true)
    useDeviceStatisticsDownload({
      id: device.id,
      name: device.name,
      serial: device.serial,
      from: dateRange.from,
      to: dateRange.to
    })
      .catch(() => openErrorAlert({ keys: ['download'] }))
      .finally(() => setDownloadInProgress(false))
  }

  return (
    <>
      <Typography
        mt={5}
        mb={2}
        variant="h4">
        {t('devices.deviceInfo')}
      </Typography>

      {device?.source?.type === 'lorawan' && (
        <Grid2
          container
          spacing={2}>
          <Grid2>
            <Typography
              mt={3}
              sx={{
                fontSize: '0.8rem',
                fontWeight: 500
              }}>
              {t('devices.networkServer')}
            </Typography>
            <Typography sx={{
              fontSize: '0.8rem',
              fontWeight: 200
            }}>{sourceData?.networkServerId}</Typography>
          </Grid2>
          <Grid2>
            <Typography
              mt={3}
              sx={{
                fontSize: '0.8rem',
                fontWeight: 500
              }}>
              {t('devices.deviceEUI')}
            </Typography>
            <Typography sx={{
              fontSize: '0.8rem',
              fontWeight: 200
            }}>{sourceData?.device?.devEui}</Typography>
          </Grid2>
          <Grid2>
            <Typography
              mt={3}
              sx={{
                fontSize: '0.8rem',
                fontWeight: 500
              }}>
              {t('devices.joinEUI')}
            </Typography>
            <Typography sx={{
              fontSize: '0.8rem',
              fontWeight: 200
            }}>{sourceData?.device?.joinEui}</Typography>
          </Grid2>
          <Grid2>
            <Typography
              mt={3}
              sx={{
                fontSize: '0.8rem',
                fontWeight: 500
              }}>
              {t('devices.appKey')}
            </Typography>
            <Typography sx={{
              fontSize: '0.8rem',
              fontWeight: 200
            }}>{sourceData?.device?.appKey}</Typography>
          </Grid2>
          <Grid2>
            <Typography
              mt={3}
              sx={{
                fontSize: '0.8rem',
                fontWeight: 500
              }}>
              {t('devices.loraType')}
            </Typography>
            <Typography sx={{
              fontSize: '0.8rem',
              fontWeight: 200
            }}>{sourceData?.device?.type === 128 ? 'OTAA' : 'ABP'}</Typography>
          </Grid2>
        </Grid2>
      )}

      {device?.source?.type === 'wmbus' && (
        <Grid2
          container
          spacing={2}>
          <Grid2>
            <Typography
              mt={3}
              sx={{
                fontSize: '0.8rem',
                fontWeight: 500
              }}>
              {t('devices.wmbusId')}
            </Typography>
            <Typography sx={{
              fontSize: '0.8rem',
              fontWeight: 200
            }}>{device?.source?.key?.wmbusid}</Typography>
          </Grid2>
        </Grid2>
      )}

      {device?.source?.type === 'nbiot' && (
        <Grid2
          container
          spacing={2}>
          <Grid2>
            <Typography
              mt={3}
              sx={{
                fontSize: '0.8rem',
                fontWeight: 500
              }}>
              {t('devices.imsi')}
            </Typography>
            <Typography sx={{
              fontSize: '0.8rem',
              fontWeight: 200
            }}>{device?.source?.key?.imsi}</Typography>
          </Grid2>
        </Grid2>
      )}

      {device?.source?.type === 'lorawan' && networkChart && statisticsChart && (
        <>
          <Stack>
            <Box
              id="linkstatus"
              mt={4}>
              <Typography
                variant="h5"
                pb={3}
                align="center">
                {t('devices.listeningGateways')}
              </Typography>
              <LinkStatusChart data={networkChart} />
            </Box>

            {quantities.map((q) => (
              <Box
                key={q.measureName}
                sx={{ height: '400px' }}
                align="center">
                {statisticsChart?.[q.measureName]?.length > 0 && (
                  <>
                    <Typography
                      variant="h5"
                      align="center"
                      pb={3}>
                      {t(q.name)}
                    </Typography>

                    {q.chart === 'line' && (
                      <LineChart
                        data={statisticsChart[q.measureName]}
                        unitOfMeasure={q.unitOfMeasure}
                        measureName={q.measureName}
                      />
                    )}

                    {q.chart === 'network' && (
                      <NetworkChart
                        data={statisticsChart[q.measureName]}
                        unitOfMeasure={q.unitOfMeasure}
                        measureName={q.measureName}
                      />
                    )}
                  </>
                )}
              </Box>
            ))}
          </Stack>
          <Stack
            direction="row"
            justifyContent="center"
            spacing={2}>
            <Can action={'devices_r'}>
              <Show when={() => downloadInProgress}>
                <CircularProgress size={30} />
              </Show>
              <Show when={() => !downloadInProgress}>
                <Button
                  id="download-statistics-xlsx"
                  variant="contained"
                  startIcon={<DownloadIcon fontSize="small" />}
                  onClick={handleDownloadStatistics}>
                  {t('common.download')} {t('common.statistics')}
                </Button>
              </Show>
            </Can>

            <Can
              action={'devices_r'}
              expression={() => isSuperAdmin}>
              <>
                <Show when={() => downloadInProgress}>
                  <CircularProgress size={30} />
                </Show>
                <Show when={() => !downloadInProgress}>
                  <Button
                    id="download-link-status-xlsx"
                    variant="contained"
                    startIcon={<DownloadIcon fontSize="small" />}
                    onClick={handleDownloadLinkStatus}>
                    {t('common.download')} LinkStatus
                  </Button>
                </Show>
              </>
            </Can>
          </Stack>
        </>
      )}
    </>
  )
}

export default NetworkTab
