import { Box } from '@mui/material'
import { useEffect, useState } from 'react'

import Card from '../Card/Card.jsx'
import { useRoles } from '../Contexts/RolesContext.jsx'

const RolesList = () => {
  const [superRoles, setSuperRoles] = useState([])
  const { useFetchRolesSuperQuery } = useRoles()

  useEffect(() => {
    useFetchRolesSuperQuery().then((res) => {
      if (res.isError) {
        return
      }
      setSuperRoles(res.data)
    })
  }, [])

  return (
    <Box mt={4}>
      {superRoles.map((role) => (
        <Card
          role={role}
          isSuper={true}
          key={`superrole-${role.name}`}
        />
      ))}
    </Box>
  )
}

export default RolesList
