import {
  AddCircleOutlineRounded as AddCircleOutlineRoundedIcon,
  DeleteRounded as DeleteRoundedIcon,
  UploadRounded as UploadRoundedIcon
} from '@mui/icons-material'
import { Box, Divider, IconButton, Pagination, Skeleton, Stack, Typography } from '@mui/material'
import { t } from 'i18next'
import { useEffect, useState } from 'react'

import { encodeInBase64Url, putIntoUrl } from '../../../helpers/utilis.js'
import Can from '../../Layout/Can/Can.jsx'
import DevicesCount from '../../Shared/Components/Count.jsx'
import { useAuth } from '../../Shared/Contexts/AuthContext.jsx'
import { useGlobalContext } from '../../Shared/Contexts/GlobalContext.jsx'
import ConfirmWithCheckDialog from '../../Shared/Dialogs/ConfirmWithCheckDialog'
import AlarmedFilter from '../../Shared/Filters/Fields/AlarmedFilter.jsx'
import DeviceCard from '../Card/DeviceCard.jsx'
import { useDevices } from '../Contexts/DevicesContext'
import Actions from '../Dialogs/Actions.jsx'
import AddDevice from '../Dialogs/AddDevice.jsx'
import ImportDevices from '../Dialogs/ImportDevices'
import SendAction from '../Dialogs/SendAction.jsx'
import UpdateDevice from '../Dialogs/UpdateDevice.jsx'
import Filters from '../Filters/DevicesFilters.jsx'

const DEFAULT_PAGE_SIZE = 7

const List = () => {
  const { authState } = useAuth()

  const { useFetchDevices, useDeleteDevice } = useDevices()
  const { devicesFilters: filters, setDevicesFilters } = useGlobalContext()
  const { openErrorAlert, openSuccessAlert } = useGlobalContext()

  const [selectedDevice, setSelectedDevice] = useState(null)
  const [isAddDeviceDialogOpen, setIsAddDeviceDialogOpen] = useState(false)
  const [isImportDeviceDialogOpen, setIsImportDeviceDialogOpen] = useState(false)
  const [isEditDeviceDialogOpen, setIsEditDeviceDialogOpen] = useState(false)
  const [isActionDialogOpen, setIsActionDialogOpen] = useState(false)
  const [isSendActionOpen, setIsSendActionOpen] = useState(false)
  const [isConfirmWithCheckOpen, setIsConfirmWithCheckOpen] = useState(false)
  const [devices, setDevices] = useState({
    data: [],
    count: 0
  })
  const [isDevicesError, setIsDevicesError] = useState(false)
  const [isDevicesLoading, setIsDevicesLoading] = useState(false)
  const [isDeleteDeviceSuccess, setIsDeleteDeviceSuccess] = useState(false)

  const [page, setPage] = useState(1)

  const handleChange = (_, value) => setPage(value)

  const handleDevicesFiltersChange = (filters) => setDevicesFilters(filters)

  const handleDelete = (device) => {
    setSelectedDevice(device)
    setIsConfirmWithCheckOpen(true)
  }

  const handleEditDevice = (device) => {
    setSelectedDevice(device)
    setIsEditDeviceDialogOpen(true)
  }

  const handleEvents = (device) => {
    setSelectedDevice(device)
    setIsEventsDialogOpen(true)
  }

  const handleSetIsOpenEditDevice = (isOpen) => {
    setIsEditDeviceDialogOpen(isOpen)
    if (!isOpen) {
      setSelectedDevice(null)
    }
  }

  const handleAction = (device) => {
    setIsActionDialogOpen(true)
    setSelectedDevice(device)
  }

  const handleSetIsOpenActionDialogOpen = (isOpen) => {
    setIsActionDialogOpen(isOpen)
    if (!isOpen) {
      setSelectedDevice(null)
    }
  }

  const handleSendAction = (device) => {
    setIsActionDialogOpen(false)
    setIsSendActionOpen(true)
    setSelectedDevice(device)
  }

  const handleSendActionIsOpen = (isOpen) => {
    setIsSendActionOpen(isOpen)
    if (!isOpen) {
      setSelectedDevice(null)
    }
  }

  const handleCloseConfigrWithCheck = () => setIsConfirmWithCheckOpen(false)

  const handleConfirmAction = () => {
    useDeleteDevice({ id: selectedDevice.id })
      .then(() => {
        setIsDeleteDeviceSuccess(true)
        openSuccessAlert()
        fetchDevices()
      })
      .catch((err) => openErrorAlert({ keys: err.data.keys }))
      .finally()
    setIsConfirmWithCheckOpen(false)
  }

  const fetchDevices = async () => {
    const devicesFilters = structuredClone(filters)
    delete devicesFilters.isAlarm
    useFetchDevices({
      filters: devicesFilters,
      page,
      pageSize: DEFAULT_PAGE_SIZE
    })
      .then((res) => setDevices({
        data: res.data,
        count: res.count
      }))
      .catch(() => setIsDevicesError(true))
      .finally(() => setIsDevicesLoading(false))
  }

  useEffect(() => {
    setIsDevicesLoading(true)
    fetchDevices()
  }, [authState, page, filters, isDeleteDeviceSuccess])

  useEffect(() => {
    const encoded = encodeInBase64Url(filters)
    putIntoUrl(encoded, 'filters')
  }, [])

  const handleCloseAddDevice = ({ success }) => {
    if (success) {
      fetchDevices()
    }
    setIsAddDeviceDialogOpen(false)
  }

  const handleAlarmedFilterChange = (value) => setDevicesFilters({
    ...filters,
    ...value
  })

  return (
    <Box id="device-list">
      <Stack direction="row">
        <Box sx={{ flexGrow: 1 }} />

        <Can action="devices_c">
          <IconButton
            id="devices-list-add-device"
            color="primary"
            onClick={() => setIsAddDeviceDialogOpen(true)}>
            <AddCircleOutlineRoundedIcon />
          </IconButton>
          <IconButton
            color="primary"
            onClick={() => setIsImportDeviceDialogOpen(true)}>
            <UploadRoundedIcon id="devices-list-import-device" />
          </IconButton>
        </Can>
      </Stack>

      <Divider />

      <Box>
        <Typography variant="h5">
          <DevicesCount itemsCount={devices?.count} />
        </Typography>
      </Box>

      <Can action="alarms_r">
        <Divider />

        <Box py={2}>
          <AlarmedFilter
            filters={filters}
            onChange={handleAlarmedFilterChange}
          />
        </Box>

        <Divider />
      </Can>

      <Stack
        direction="row"
        spacing={1}
        my={4}>
        <Filters
          filters={filters}
          itemsCount={devices?.count}
          onChange={handleDevicesFiltersChange}
        />
      </Stack>

      {isDevicesLoading && (
        <Stack
          spacing={2}
          sx={{ marginBottom: '20px' }}>
          {[...Array(DEFAULT_PAGE_SIZE).keys()].map((index) => (
            <Skeleton
              key={`skeleton-${index}`}
              variant="rounded"
              sx={{ height: '230px' }}></Skeleton>
          ))}
        </Stack>
      )}

      {!isDevicesLoading &&
        !isDevicesError &&
        devices?.data.map((device, i) => (
          <DeviceCard
            key={`device-${i}`}
            id={`device-card-${i}`}
            device={device}
            onDelete={() => handleDelete(device)}
            onEdit={handleEditDevice}
            onAction={handleAction}
            onEvents={handleEvents}
          />
        ))}

      <Stack
        spacing={2}
        justifyContent="space-between"
        alignItems="center">
        {!isDevicesLoading && !!devices?.count && (
          <Pagination
            count={Math.ceil(devices.count / DEFAULT_PAGE_SIZE)}
            page={page}
            onChange={handleChange}
            showFirstButton
            showLastButton
          />
        )}
      </Stack>

      <Can action="devices_c">
        {isAddDeviceDialogOpen && (
          <AddDevice
            isOpen={isAddDeviceDialogOpen}
            onClose={handleCloseAddDevice}
          />
        )}
        {isImportDeviceDialogOpen && (
          <ImportDevices
            isOpen={isImportDeviceDialogOpen}
            setIsOpen={setIsImportDeviceDialogOpen}
          />
        )}
      </Can>

      <Can
        expression={() => !!selectedDevice}
        action="devices_d">
        <ConfirmWithCheckDialog
          isOpen={isConfirmWithCheckOpen}
          icon={
            <DeleteRoundedIcon
              size={60}
              color="#ef5350"
            />
          }
          onClose={handleCloseConfigrWithCheck}
          onConfirmAction={handleConfirmAction}
          questionText={t('devices.deleteDevice.title')}
          subQuestionText={selectedDevice?.name}
          copyText={selectedDevice?.id}
          bodyText={t('devices.deleteDevice.subtitle')}
          inputSuggestionText={t('devices.deleteDevice.helper')}
        />
      </Can>

      <Can action="devices_u">
        {isActionDialogOpen && (
          <Actions
            onSendAction={handleSendAction}
            isOpen={isActionDialogOpen}
            setIsOpen={handleSetIsOpenActionDialogOpen}
            device={selectedDevice}
          />
        )}
        {isSendActionOpen && (
          <SendAction
            device={selectedDevice}
            isOpen={isSendActionOpen}
            setIsOpen={handleSendActionIsOpen}
          />
        )}
        {isEditDeviceDialogOpen && (
          <UpdateDevice
            deviceId={selectedDevice.id}
            isOpen={isEditDeviceDialogOpen}
            setIsOpen={handleSetIsOpenEditDevice}
          />
        )}
      </Can>
    </Box>
  )
}

export default List
