import { Box, Chip, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material'
import { useState } from 'react'

import CloseDialogButton from '../../Shared/Dialogs/CloseDialogButton'

const Filter = ({
  title,
  id,
  content = <></>,
  count = <></>,
  hidden = false,
  onDelete = () => { },
  selected = false
}) => {
  const [open, setOpen] = useState(false)

  const handleOnClick = () => {
    setOpen(true)
  }

  const handleOnClose = () => {
    setOpen(false)
  }

  return (
    <>
      {!hidden && (
        <>
          <Dialog
            open={open}
            onClose={handleOnClose}
            scroll="body"
            aria-labelledby="filters-dialog-title"
            aria-describedby="filters-dialog-content"
            fullWidth={true}
            maxWidth={'md'}
          >
            <DialogTitle
              variant="h4"
              display="flex"
              justifyContent="center"
            >
              {title}
            </DialogTitle>

            <CloseDialogButton onClose={handleOnClose} />

            <DialogTitle
              id="filters-dialog-title"
              display="flex"
              justifyContent="center"
            >
              {count && (
                <Box>
                  <Typography
                    variant="h6"
                    align="center"
                  >
                    {count}
                  </Typography>
                </Box>
              )}
            </DialogTitle>
            <DialogContent id="filters-dialog-content">{content}</DialogContent>
            <DialogActions></DialogActions>
          </Dialog>

          <Chip
            id={id}
            label={title}
            variant="text"
            size="medium"
            color={selected ? 'primary' : 'notSelected'}
            onClick={handleOnClick}
            onDelete={onDelete}
          />
        </>
      )}
    </>
  )
}

export default Filter
