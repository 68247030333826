import { Box, Grid2, TextField } from '@mui/material'
import { t } from 'i18next'
import Joi from 'joi'
import { useState } from 'react'

import useFieldValidation from '../../../../helpers/fieldValidation'
import { useValidationEffect } from '../../../../helpers/hooks'

const schema = Joi.object({
  name: Joi.string().empty(null).required()
})

const ConfigureActionAlarm = ({
  actionName,
  onChangeIsValid,
  onChangeActionName
}) => {
  const [name, setName] = useState(actionName)
  const [validation, setValidation] = useState({ isValid: false })

  const handleNameChange = (event) => {
    setName(event.target.value)
  }

  useValidationEffect(() => {
    const fieldValidation = useFieldValidation({ name }, schema)
    setValidation(fieldValidation)
    onChangeIsValid(fieldValidation.isValid)
    onChangeActionName(name)
  }, [name])

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid2
        container
        marginBottom={5}
        display="flex"
        justifyContent="center"
      >
        <Grid2 size={6}>
          <TextField
            id="configure-action-name"
            variant="standard"
            label={t('actions.actionName')}
            fullWidth
            onChange={handleNameChange}
            value={actionName}
            error={validation.messages?.name?.length > 0}
            helperText={validation.messages?.name?.join(', ')}
          />
        </Grid2>
      </Grid2>
    </Box>
  )
}

export default ConfigureActionAlarm
