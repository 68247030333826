import { t } from 'i18next'
import { useState } from 'react'

import { useGlobalContext } from '../../Shared/Contexts/GlobalContext.jsx'
import ZDialog from '../../Shared/Dialogs/ZDialog.jsx'
import { useAutomations } from '../Contexts/AutomationsContext.jsx'
import TriggerForm from '../Form/Trigger/TriggerForm.jsx'

const defaultScheduler = {
  monday: {
    selected: false,
    timeSlots: []
  },
  tuesday: {
    selected: false,
    timeSlots: []
  },
  wednesday: {
    selected: false,
    timeSlots: []
  },
  thursday: {
    selected: false,
    timeSlots: []
  },
  friday: {
    selected: false,
    timeSlots: []
  },
  saturday: {
    selected: false,
    timeSlots: []
  },
  sunday: {
    selected: false,
    timeSlots: []
  }
}

const defaultTriggerData = {
  name: '',
  isForSuperAdmin: false,
  applyToChildOrganizations: false,
  models: [],
  type: null,
  schedulerType: 'default',
  scheduler: JSON.parse(JSON.stringify(defaultScheduler)),
  sendReminder: false,
  thresholdsCounter: 1,
  triggerType: 'quantity',
  quantity: null,
  subQuantity: null,
  trigger: null,
  interval: null,
  tolerance: null,
  maxDeltaChange: null,
  device: null
}

const AddTrigger = ({ isOpen, onClose }) => {
  const { useCreateTrigger } = useAutomations()
  const { openErrorAlert, openSuccessAlert } = useGlobalContext()
  const [triggerData, setTriggerData] = useState(defaultTriggerData)

  const handleOnSave = (trigger) => {
    useCreateTrigger(trigger)
      .then(() => {
        openSuccessAlert()
        handleClose()
      })
      .catch((err) => openErrorAlert({ keys: err.data.keys }))
  }

  const handleClose = () => {
    setTriggerData(defaultTriggerData)
    onClose()
  }
  const handleChange = (trigger) => setTriggerData(trigger)

  return (
    <ZDialog
      id={'add-trigger-dialog'}
      isOpen={isOpen}
      title={t('triggers.add')}
      content={
        <TriggerForm
          triggerData={triggerData}
          isEdit={false}
          onSave={handleOnSave}
          onChange={handleChange}
        />
      }
      onClose={handleClose}
    />
  )
}

export default AddTrigger
