import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material'
import { t } from 'i18next'
import { useState } from 'react'

import CloseDialogButton from '../../Shared/Dialogs/CloseDialogButton'

const OrganizationsTable = ({
  organizations,
  handleLeaveOrganization = () => { }
}) => {
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)
  const [organizationKeyToLeave, setOrganizationKeyToLeave] = useState()

  const handleOpenConfirmDialog = (organizationKey) => {
    setOrganizationKeyToLeave(organizationKey)
    setConfirmDialogOpen(true)
  }

  const handleCloseConfirmDialog = () => {
    setOrganizationKeyToLeave(null)
    setConfirmDialogOpen(false)
  }

  const handleConfirmLeaveOrganization = () => {
    handleLeaveOrganization(organizationKeyToLeave)
    setOrganizationKeyToLeave(null)
    setConfirmDialogOpen(false)
  }

  return (
    <>
      <TableContainer component={Paper}>
        <Table aria-label="user organizations table">
          <TableHead>
            <TableRow>
              <TableCell align="left">{t('common.organization')}</TableCell>
              <TableCell align="left">{t('common.role')}</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {organizations.map((row) => (
              <TableRow
                key={row.key}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell
                  id='organizations-table-org-name'
                  component="th"
                  scope="row"
                  align="left"
                >
                  {row.name}
                </TableCell>
                <TableCell id='organizations-table-user-role'
                  align="left">{row.role}</TableCell>
                <TableCell id='organizations-table-leave'
                  align="left">
                  {organizations.length > 1 && (
                    <Button onClick={() => handleOpenConfirmDialog(row.key)}>
                      {t('organizations.leave')}
                    </Button>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog
        open={confirmDialogOpen}
        onClose={handleCloseConfirmDialog}
        maxWidth="sm"
        scroll="body"
        fullWidth
      >
        <CloseDialogButton onClose={handleCloseConfirmDialog} />

        <DialogTitle
          id="organizations-table-dialog-leave-org"
          variant="h4"
          display="flex"
          justifyContent="center"
        >
          {t('organizations.areYouSureYouWantToLeave')}
        </DialogTitle>

        <DialogContent>
          <Stack
            spacing={2}
            justifyContent={'center'}
            alignItems={'center'}
          >
            <Box mt={6}>
              <Typography
                variant="title"
                align="center"
                textAlign={'center'}
                justifyContent={'center'}
              >
                {t('organizations.onceYouQuit')}.
              </Typography>
            </Box>
            <Box textAlign="center">
              <Stack
                direction="row"
                alignItems={'center'}
                justifyContent={'center'}
                spacing={2}
              >
                <Button
                  variant="contained"
                  color="primary"
                  sx={{ marginTop: 3 }}
                  onClick={handleCloseConfirmDialog}
                >
                  {t('common.cancel')}
                </Button>
                <Button
                  id="organizations-table-dialog-leave-org-button-leave"
                  onClick={handleConfirmLeaveOrganization}
                  variant="contained"
                >
                  {t('organizations.leave')}
                </Button>
              </Stack>
            </Box>
          </Stack>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default OrganizationsTable
