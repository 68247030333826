import { Box, Button, Stack, Typography } from '@mui/material'
import { t } from 'i18next'
import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import Can from '../../Layout/Can/Can.jsx'
import Show from '../../Layout/Can/Show.jsx'
import ZList from '../../Shared/Components/ZList'
import { useGlobalContext } from '../../Shared/Contexts/GlobalContext'
import ConfirmDialog from '../../Shared/Dialogs/ConfirmDialog'
import ActionCard from '../Card/ActionCard.jsx'
import { useAutomations } from '../Contexts/AutomationsContext'
import AddAction from '../Dialogs/AddAction'
import ActionsFilters from '../Filters/ActionsFilters.jsx'

const DEFAULT_PAGE_SIZE = 25

const ActionsList = () => {
  const [searchParams] = useSearchParams()
  const fullText = searchParams.get('search')

  const { useSearchActions, useDeleteAction } = useAutomations()
  const { openErrorAlert, openSuccessAlert } = useGlobalContext()

  const [actionId, setActionId] = useState(null)
  const [actionName, setActionName] = useState(null)
  const [actionType, setActionType] = useState(null)
  const [isAddActionDialogOpen, setIsAddActionDialogOpen] = useState(false)
  const [page, setPage] = useState(1)
  const [confirmDeleteActionIsOpen, setConfirmDeleteActionIsOpen] = useState(false)
  const [filters, setFilters] = useState({})
  const [isSearchActionsLoading, setIsSearchActionsLoading] = useState(false)
  const [actions, setActions] = useState()

  const handleFiltersChange = (filters) => setFilters(filters)
  const handleChangePage = (_, value) => setPage(value)

  const searchActions = () => {
    setIsSearchActionsLoading(true)
    useSearchActions({
      page,
      pageSize: DEFAULT_PAGE_SIZE,
      organizationKeys: filters.organizationKeys,
      isUsedInRule: filters.isUsedInRule,
      fullText
    })
      .then((res) => setActions(res))
      .finally(() => setIsSearchActionsLoading(false))
  }

  useEffect(() => {
    searchActions()
  }, [fullText, filters, page])

  const selectFilter = (action) => {
    const filterFunctions = {
      actionTypes: (action) => !filters.actionTypes?.length || filters.actionTypes?.includes(action.type),
      default: () => true
    }

    return Object.keys(filters).reduce((acc, curr) => {
      const func = filterFunctions[curr] || filterFunctions['default']
      return acc && func(action)
    }, true)
  }

  const handleDeleteAction = (action) => {
    setActionId(action.id)
    setActionName(action.name)
    setActionType(action.type)
    setConfirmDeleteActionIsOpen(true)
  }

  const handleOnConfirmDeleteAction = () => {
    setConfirmDeleteActionIsOpen(false)
    useDeleteAction({
      id: actionId,
      type: actionType
    })
      .then(() => {
        openSuccessAlert()
        searchActions()
      })
      .catch((err) => openErrorAlert({ keys: err.data.keys }))
  }

  const handleAddActionClose = ({ reload }) => {
    if (reload) {
      searchActions()
    }
    setIsAddActionDialogOpen(false)
  }

  const actionsList = actions?.data
    .filter((action) => selectFilter(action))
    .map((action, index) => (
      <ActionCard
        index={index}
        key={`action-${index}`}
        action={action}
        onDelete={() => handleDeleteAction(action)}
      />
    ))

  const actionsFilters = (
    <ActionsFilters
      filters={filters}
      onChange={handleFiltersChange}
    />
  )

  return (
    <>
      <Stack direction="row">
        <Typography variant="body1">
          <strong>{t('actions.preset')}</strong>
        </Typography>
        <Box sx={{ flexGrow: 1 }} />
        <Can action={'devices_u'}>
          <Button
            id="actions-list-create-new-action"
            size="small"
            variant="contained"
            color="secondary"
            onClick={() => setIsAddActionDialogOpen(true)}>
            {t('actions.createNew')}
          </Button>
        </Can>
      </Stack>

      <ZList
        count={actions?.count}
        filters={actionsFilters}
        entities={actionsList}
        page={page}
        isLoading={isSearchActionsLoading}
        onPageChange={handleChangePage}
      />

      <Show when={() => isAddActionDialogOpen}>
        <AddAction
          isOpen={isAddActionDialogOpen}
          onClose={handleAddActionClose}
        />
      </Show>

      <ConfirmDialog
        isOpen={confirmDeleteActionIsOpen}
        title={t('actions.deleteAction')}
        message={`${t('actions.areYouSureYouWantToDelete')} ${actionName}?`}
        onConfirm={handleOnConfirmDeleteAction}
        onClose={() => setConfirmDeleteActionIsOpen(false)}
      />
    </>
  )
}

export default ActionsList
