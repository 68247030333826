import { Autocomplete, TextField } from '@mui/material'
import { t } from 'i18next'
import { useEffect, useState } from 'react'

import { useFilters } from '../../Contexts/FiltersContext'

const filterOptions = (option) => option

const AddressFilter = ({ filters, onChange = () => { } }) => {
  const { useFetchPlaces } = useFilters()

  const [position, setPosition] = useState({
    address: filters?.address || null,
    latitude: null,
    longitude: null
  })

  const [places, setPlaces] = useState([])
  const [suggestions, setSuggestions] = useState([])

  const fetchPlaces = (place) => {
    useFetchPlaces(place).then(({ data }) => setPlaces(data))
  }

  useEffect(() => {
    onChange(position)
  }, [position])

  const onInputChange = (_, value, reason) => {
    switch (reason) {
      case 'clear':
        setPosition({
          address: null,
          latitude: null,
          longitude: null
        })
        setSuggestions([])
        break
      default:
        if (value?.length > 3) {
          fetchPlaces({ place: value })
        }
        break
    }
  }

  useEffect(() => {
    setSuggestions(places)
  }, [places])

  const onChangeAddress = (_, value) => {
    if (!value) {
      return
    }
    const { address = '', lat: latitude = null, lng: longitude = null } = value

    setPosition({
      address,
      latitude,
      longitude
    })
  }

  return (
    <>
      <Autocomplete
        id="address-field"
        filterOptions={filterOptions}
        onChange={onChangeAddress}
        onInputChange={onInputChange}
        options={suggestions}
        getOptionLabel={(option) => option.address || position.address}
        isOptionEqualToValue={(option, value) => option.address === value}
        value={position?.address}
        clearOnBlur={true}
        renderInput={(params) => (
          <TextField
            {...params}
            label={t('common.address')}
            variant="standard"
            slotProps={{
              ...params.InputProps
            }}
          />
        )}
      />
    </>
  )
}

export default AddressFilter
