import {
  VisibilityOffRounded as VisibilityOffRoundedIcon,
  VisibilityRounded as VisibilityRoundedIcon
} from '@mui/icons-material'
import {
  Box,
  FormControlLabel,
  IconButton,
  InputAdornment,
  MenuItem,
  Stack,
  Switch,
  TextField,
  Typography
} from '@mui/material'
import { t } from 'i18next'
import { useState } from 'react'

const DynamicInput = ({ validation, value, field, onChange = () => { } }) => {
  const [showPassword, setShowPassword] = useState(false)

  const { internalName, options } = field

  const handleCheckBox = (event) =>
    onChange({
      internalName,
      value: event.target.checked
    })

  const handleChangeNumber = (event) =>
    onChange({
      internalName,
      value: parseFloat(event.target.value)
    })

  const handleChange = (event) =>
    onChange({
      internalName,
      value: event.target.value
    })

  const handleMouseDownPassword = (event) => event.preventDefault()

  const handleClickShowPassword = () => setShowPassword((show) => !show)

  return (
    <Stack
      width={'100%'}
      sx={{ mb: 2 }}
      spacing={1}
    >
      <Box
        key={`${field.internalName}-${field.type}`}
        width={'100%'}
      >
        {field.type === 'bool' && (
          <FormControlLabel
            key={`${field.internalName}-${field.type}`}
            control={
              <Switch
                id={`dynamic-input-${field.internalName}`}
                name={field.internalName}
                checked={value}
                onChange={handleCheckBox}
              />
            }
            label={field.labelUI}
          />
        )}

        {(field.type === 'integer' || field.type === 'float') && (
          <TextField
            key={`${field.internalName}-${field.type}`}
            id={`dynamic-input-${field.internalName}`}
            name={field.internalName}
            label={field.labelUI}
            type="number"
            variant="standard"
            required={field.required}
            value={value || ''}
            onChange={handleChangeNumber}
            error={validation?.messages?.[field.internalName]?.length > 0}
            helperText={validation?.messages?.[field.internalName]?.join(', ')}
            fullWidth
          />
        )}

        {field.type === 'select' && (
          <TextField
            key={`${field.internalName}-${field.type}`}
            id={`dynamic-input-${field.internalName}`}
            select
            label={field.labelUI}
            name={field.internalName}
            required={field.required}
            value={options.find((o) => Object.keys(o).includes(value)) || ''}
            onChange={handleChange}
            error={validation?.messages?.[field.internalName]?.length > 0}
            helperText={validation?.messages?.[field.internalName]?.join(', ')}
            sx={{ minWidth: '150px' }}
            fullWidth
          >
            {options?.map((o, i) => (
              <MenuItem
                key={`selectOptions-${i}`}
                value={Object.keys(o)[0]}
              >
                {Object.values(o)}
              </MenuItem>
            ))}
          </TextField>
        )}

        {field.type === 'password' && (
          <TextField
            key={`${field.internalName}-${field.type}`}
            id={`dynamic-input-${field.internalName}`}
            label={field.labelUI}
            name={field.internalName}
            type={showPassword ? 'text' : 'password'}
            variant="standard"
            required={field.required}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {showPassword ? (
                    <VisibilityOffRoundedIcon />
                  ) : (
                    <VisibilityRoundedIcon />
                  )}
                </IconButton>
              </InputAdornment>
            }
            value={value || ''}
            onChange={handleChange}
            error={validation?.messages?.[field.internalName]?.length > 0}
            helperText={validation?.messages?.[field.internalName]?.join(', ')}
            fullWidth
          />
        )}

        {field.type === 'string' && (
          <TextField
            key={`${field.internalName}-${field.type}`}
            id={`dynamic-input-${field.internalName}`}
            label={field.labelUI}
            name={field.internalName}
            type="text"
            variant="standard"
            required={field.required}
            value={value || ''}
            onChange={handleChange}
            error={validation?.messages?.[field.internalName]?.length > 0}
            helperText={validation?.messages?.[field.internalName]?.join(', ')}
            fullWidth
          />
        )}

        {field.type === 'textarea' && (
          <TextField
            key={`${field.internalName}-${field.type}`}
            id={`dynamic-input-${field.internalName}`}
            label={field.labelUI}
            name={field.internalName}
            multiline
            rows={4}
            variant="standard"
            required={field.required}
            value={value || ''}
            onChange={handleChange}
            error={validation?.messages?.[field.internalName]?.length > 0}
            helperText={validation?.messages?.[field.internalName]?.join(', ')}
            fullWidth
          />
        )}

        {field.type === 'selectMeasureFromDevice' && (
          <p key={`${field.internalName}-${field.type}`}>
            {t('devices.selectMeasureFromDevice')}
          </p>
        )}
      </Box>
      {field.description && (
        <Typography variant="body">{field.description}</Typography>
      )}
    </Stack>
  )
}

export default DynamicInput
