import { createContext, useContext } from 'react'

import sanitizer from '../../../helpers/sanitizer'
import { useAuth } from '../../Shared/Contexts/AuthContext'

const OrganizationsContext = createContext({})

export const useOrganizations = () => {
  return useContext(OrganizationsContext)
}

export const OrganizationsProvider = ({ children }) => {
  const {
    useBaseAxiosGet,
    useBaseAxiosPost,
    useBaseAxiosPatch,
    useBaseAxiosDelete,
    useBaseAxiosPut
  } = useAuth()

  const useFetchOrganization = async (organizationId) => {
    return await useBaseAxiosGet({ url: `/organizations/${organizationId}` })
  }

  const useFetchKeySuggestion = async (name) => {
    return await useBaseAxiosGet({
      url: `/organization-key-suggestion?name=${name}`
    })
  }

  const useCreateRootOrganization = async (payload) => {
    const formPayload = new FormData()
    formPayload.append('Content-Type', 'multipart/form-data')
    formPayload.append('data', JSON.stringify(payload.data))
    formPayload.append('logo', payload.logo)
    return await useBaseAxiosPost({
      url: '/organizations',
      payload: formPayload
    })
  }

  const useCreateChildOrganization = async ({
    parentOrganization,
    payload
  }) => {
    const formPayload = new FormData()
    formPayload.append('Content-Type', 'multipart/form-data')
    formPayload.append('data', JSON.stringify(payload.data))
    formPayload.append('logo', payload.logo)
    return await useBaseAxiosPost({
      url: `/organizations/${parentOrganization}/children`,
      payload: formPayload
    })
  }

  const useEditOrganization = async (payload) => {
    const formPayload = new FormData()
    formPayload.append('Content-Type', 'multipart/form-data')
    formPayload.append('data', JSON.stringify(payload.data))
    formPayload.append('logo', payload.logo)
    return await useBaseAxiosPut({
      url: `/organizations/${payload.organizationId}`,
      payload: formPayload
    })
  }

  const useFetchTimezones = async () => {
    return await useBaseAxiosGet({ url: '/timezones' })
  }

  const useFetchAllNetworkServers = async () => {
    return await useBaseAxiosGet({
      url: '/network-servers?filter=onlyAvailableUsers'
    })
  }

  const useFetchOrganizationProfiles = async () => {
    return await useBaseAxiosGet({
      url: '/organization-profiles'
    })
  }

  const useFetchOrganizationProfile = async (profileId) => {
    return await useBaseAxiosGet({
      url: `/organization-profiles/${profileId}`
    })
  }

  const useFetchOrganizationsWithThresholdsAndFilters = async ({
    filters,
    fullText,
    page,
    pageSize
  }) => {
    return await useBaseAxiosPost({
      url: '/organizations/search',
      params: fullText ? {
        fullText,
        page,
        pageSize
      } : {
        page,
        pageSize
      },
      payload:
        filters.organizationKeys?.length > 0
          ? sanitizer({
            ...filters,
            searchInSubOrganizations: true
          })
          : {}
    })
  }

  const useLockOrganization = async (organizationId) => {
    return await useBaseAxiosPatch({
      url: `/organizations/${organizationId}/lock`
    })
  }

  const useUnlockOrganization = async (organizationId) => {
    return await useBaseAxiosPatch({
      url: `/organizations/${organizationId}/unlock`
    })
  }
  const useDeleteOrganization = async (organizationId) => {
    return await useBaseAxiosDelete({
      url: `/organizations/${organizationId}`
    })
  }

  const value = {
    useCreateRootOrganization,
    useCreateChildOrganization,
    useEditOrganization,
    useFetchOrganization,
    useFetchKeySuggestion,
    useFetchTimezones,
    useFetchAllNetworkServers,
    useFetchOrganizationProfiles,
    useFetchOrganizationProfile,
    useFetchOrganizationsWithThresholdsAndFilters,
    useLockOrganization,
    useUnlockOrganization,
    useDeleteOrganization
  }

  return (
    <OrganizationsContext.Provider value={value}>
      {children}
    </OrganizationsContext.Provider>
  )
}
