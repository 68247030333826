import { ResponsiveBar } from '@nivo/bar'
import { useState } from 'react'

const BarChart = ({ data = [] }) => {
  //TODO: togliere il groupBy https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/groupBy
  const groupedEvents = Object.groupBy(
    data.map((d) => ({
      ...d,
      startedAt: d.startedAt.substring(0, 10)
    })),
    (x) => x.startedAt
  )

  const [visibleKeys, setVisibleKeys] = useState([
    {
      type: 'measure-communication',
      isVisible: true
    },
    {
      type: 'communication',
      isVisible: true
    },
    {
      type: 'threshold',
      isVisible: true
    },
    {
      type: 'delta',
      isVisible: true
    },
    {
      type: 'trigger',
      isVisible: false
    },
    {
      type: 'measure-trigger',
      isVisible: false
    },
    {
      type: 'scheduled-trigger',
      isVisible: true
    }
  ])

  const toggleVisibleCharts = (id) => {
    const newVk = visibleKeys.map((vk) => ({
      ...vk,
      isVisible: vk.type === id ? !vk.isVisible : vk.isVisible
    }))
    setVisibleKeys(newVk)
  }

  const charData = Object.keys(groupedEvents).map((x) => {
    const measureCommunication = groupedEvents[x].find(
      (c) =>
        c.type === 'measure-communication' &&
        visibleKeys.find((x) => x.isVisible && x.type === c.type)
    )
    const communication = groupedEvents[x].find(
      (c) =>
        c.type === 'communication' &&
        visibleKeys.find((x) => x.isVisible && x.type === c.type)
    )
    const threshold = groupedEvents[x].find(
      (c) =>
        c.type === 'threshold' &&
        visibleKeys.find((x) => x.isVisible && x.type === c.type)
    )
    const delta = groupedEvents[x].find(
      (c) =>
        c.type === 'delta' &&
        visibleKeys.find((x) => x.isVisible && x.type === c.type)
    )
    const trigger = groupedEvents[x].find(
      (c) =>
        c.type === 'trigger' &&
        visibleKeys.find((x) => x.isVisible && x.type === c.type)
    )
    const measureTrigger = groupedEvents[x].find(
      (c) =>
        c.type === 'measure-trigger' &&
        visibleKeys.find((x) => x.isVisible && x.type === c.type)
    )
    const scheduledTrigger = groupedEvents[x].find(
      (c) =>
        c.type === 'scheduled-trigger' &&
        visibleKeys.find((x) => x.isVisible && x.type === c.type)
    )
    return {
      startedAt: x,
      measureCommunication: measureCommunication?.total || null,
      communication: communication?.total || null,
      threshold: threshold?.total || null,
      trigger: trigger?.total || null,
      measureTrigger: measureTrigger?.total || null,
      delta: delta?.total || null,
      scheduledTrigger: scheduledTrigger?.total || null
    }
  })

  return (
    <div style={{ height: '400px' }}>
      <ResponsiveBar
        data={charData}
        keys={[
          'scheduledTrigger',
          'communication',
          'threshold',
          'delta',
          'trigger',
          'measureCommunication',
          'measureTrigger'
        ]}
        indexBy="startedAt"
        margin={{
          top: 50,
          right: 130,
          bottom: 90,
          left: 60
        }}
        padding={0.3}
        valueScale={{ type: 'linear' }}
        indexScale={{
          type: 'band',
          round: true
        }}
        borderColor={{
          from: 'color',
          modifiers: [['darker', 1.6]]
        }}
        axisBottom={{
          tickRotation: 90
        }}
        legends={[
          {
            onClick: (args) => toggleVisibleCharts(args.id),
            dataFrom: 'keys',
            anchor: 'bottom-right',
            direction: 'column',
            justify: false,
            translateX: 120,
            translateY: 0,
            itemsSpacing: 2,
            itemWidth: 100,
            itemHeight: 20,
            itemDirection: 'left-to-right',
            itemOpacity: 0.85,
            symbolSize: 20,
            effects: [
              {
                on: 'hover',
                style: {
                  itemOpacity: 1
                }
              }
            ]
          }
        ]}
        role="application"
      />
    </div>
  )
}

export default BarChart
