import { Autocomplete, Stack, TextField } from '@mui/material'
import { Box } from '@mui/material'
import { t } from 'i18next'
import Joi from 'joi'
import { useEffect, useState } from 'react'

import useFieldValidation from '../../../../helpers/fieldValidation'
import { useValidationEffect } from '../../../../helpers/hooks'
import OperatorsEqualIcon from '../../../../images/operators__equal.svg'
import OperatorsGreaterIcon from '../../../../images/operators__greater.svg'
import OperatorsGreaterEqualIcon from '../../../../images/operators__greater-equal.svg'
import OperatorsLessIcon from '../../../../images/operators__less.svg'
import OperatorsLessEqualIcon from '../../../../images/operators__less-equal.svg'
import OperatorsNotEqualIcon from '../../../../images/operators__not-equal.svg'
import { useAutomations } from '../../Contexts/AutomationsContext'

const printSingleValueOperator = (operator) => {
  switch (operator) {
    case 'less-than':
      return (
        <Box
          component="img"
          sx={{ height: '50px' }}
          src={OperatorsLessIcon}
        />
      )
    case 'less-than-or-equal-to':
      return (
        <Box
          component="img"
          sx={{ height: '50px' }}
          src={OperatorsLessEqualIcon}
        />
      )
    case 'greater-than':
      return (
        <Box
          component="img"
          sx={{ height: '50px' }}
          src={OperatorsGreaterIcon}
        />
      )
    case 'greater-than-or-equal-to':
      return (
        <Box
          component="img"
          sx={{ height: '50px' }}
          src={OperatorsGreaterEqualIcon}
        />
      )
    case 'equal':
      return (
        <Box
          component="img"
          sx={{ height: '50px' }}
          src={OperatorsEqualIcon}
        />
      )
    case 'not-equal':
      return (
        <Box
          component="img"
          sx={{ height: '50px' }}
          src={OperatorsNotEqualIcon}
        />
      )
    default:
      return ''
  }
}

const initialValuesByOperator = (operator) => {
  switch (operator) {
    case 'between':
    case 'not-between':
      return ['', '']
    case 'less-than':
    case 'less-than-or-equal-to':
    case 'greater-than':
    case 'greater-than-or-equal-to':
    case 'equal':
    case 'not-equal':
      return ['']
    default:
      return []
  }
}

const schema = Joi.object({
  operator: Joi.string().empty(null).required(),
  values: Joi.array().empty(null, []).required()
})

const ThresholdTriggerSelector = ({
  trigger,
  quantity,
  onTriggerChange,
  isEdit
}) => {
  const { useGetNumericComparisonOperators } = useAutomations()
  const [operators, setOperators] = useState([])

  useEffect(() => {
    useGetNumericComparisonOperators().then((res) => setOperators(res.data))
  }, [])

  const onOperatorSelected = (_event, operator) => {
    const trigger = operator
      ? {
        operator: operator.name,
        values: initialValuesByOperator(operator.name)
      }
      : null
    onTriggerChange({ trigger })
  }

  const handleChange = (index, value) => {
    const newValues = [...trigger.values]
    newValues[index] = value
    onTriggerChange({
      trigger: {
        ...trigger,
        values: newValues
      }
    })
  }

  const [validation, setValidation] = useState({ isValid: false })

  useValidationEffect(() => {
    setValidation(
      useFieldValidation(
        {
          operator: trigger ? trigger.operator : null,
          values: trigger ? trigger.values : []
        },
        schema
      )
    )
  }, [trigger])

  return (
    <>
      <h3>{t('triggers.operators.label')}</h3>
      <Autocomplete
        id="thresholdTriggerSelectorSelectOperator"
        label={t('triggers.selectOperator')}
        placeholder={t('triggers.selectOperator')}
        options={operators || []}
        value={{ name: trigger?.operator || '' }}
        getOptionLabel={(option) =>
          option.name ? t(`triggers.operators.${option.name}`) : ''
        }
        isOptionEqualToValue={(option, value) => option.name === value.name}
        onChange={onOperatorSelected}
        disabled={isEdit}
        renderInput={(params) => (
          <TextField
            name="triggerSelector"
            {...params}
            label={t('triggers.selectOperator')}
            variant="standard"
            error={validation?.messages?.operator?.length > 0}
            helperText={validation?.messages?.operator?.join(', ')}
          />
        )}
      />

      {trigger && trigger.operator === 'between' && (
        <Stack
          direction="row"
          spacing={2}
          justifyContent="space-between"
          sx={{
            marginBottom: '15px',
            margin: '15px 15px'
          }}
        >
          <TextField
            id="thresholdTriggerSelectorFromValue"
            value={trigger.values[0]}
            onChange={({ target: { value } }) => {
              handleChange(0, value)
            }}
            type="number"
            disabled={isEdit}
            required
            label={t('common.value')}
            error={validation?.messages?.values?.length > 0}
            helperText={validation?.messages?.values?.join(', ')}
          />

          <h3>{printSingleValueOperator('less-than-or-equal-to')}</h3>
          <h3>
            {t(quantity.label)} ({quantity.type.unitOfMeasure})
          </h3>
          <h3>{printSingleValueOperator('less-than-or-equal-to')}</h3>

          <TextField
            id="thresholdTriggerSelectorToValue"
            value={trigger.values[1]}
            onChange={({ target: { value } }) => handleChange(1, value)}
            type="number"
            disabled={isEdit}
            required
            label={t('common.value')}
            error={validation?.messages?.values?.length > 0}
            helperText={validation?.messages?.values?.join(', ')}
          />
        </Stack>
      )}
      {trigger && quantity && trigger.operator === 'not-between' && (
        <Stack
          direction="row"
          spacing={2}
          justifyContent="space-between"
          sx={{
            marginBottom: '15px',
            margin: '15px 15px'
          }}
        >
          <h3>
            {t(quantity.label)} ({quantity.type.unitOfMeasure})
          </h3>
          <h3>{printSingleValueOperator('less-than')}</h3>
          <TextField
            id="thresholdTriggerSelectorFromValue"
            value={trigger.values[0]}
            onChange={({ target: { value } }) => {
              handleChange(0, value)
            }}
            type="number"
            disabled={isEdit}
            required
            label={t('common.value')}
            error={validation?.messages?.values?.length > 0}
            helperText={validation?.messages?.values?.join(', ')}
          />
          <h3>
            <span className="a2a-alarms-page__operators--or">
              {t('alarms.page.addRule.operatorsOr')}{' '}
            </span>
            {t(quantity.label)} ({quantity.type.unitOfMeasure})
          </h3>
          <h3>{printSingleValueOperator('greater-than')}</h3>
          <TextField
            id="thresholdTriggerSelectorToValue"
            value={trigger.values[1]}
            onChange={({ target: { value } }) => handleChange(1, value)}
            type="number"
            disabled={isEdit}
            required
            label={t('common.value')}
            error={validation?.messages?.values?.length > 0}
            helperText={validation?.messages?.values?.join(', ')}
          />
        </Stack>
      )}
      {trigger &&
        quantity &&
        trigger.operator !== 'between' &&
        trigger.operator !== 'not-between' && (
        <Stack
          direction="row"
          spacing={2}
          justifyContent="space-between"
          sx={{
            marginBottom: '15px',
            margin: '15px 15px'
          }}
        >
          <h3>
            {t(quantity.label)} ({quantity.type.unitOfMeasure})
          </h3>
          <h3>{printSingleValueOperator(trigger.operator)}</h3>
          <TextField
            id="thresholdTriggerSelectorSingleValue"
            value={trigger?.values[0]}
            onChange={({ target: { value } }) => handleChange(0, value)}
            type="number"
            disabled={isEdit}
            required
            label={t('common.value')}
            error={validation?.messages?.values?.length > 0}
            helperText={validation?.messages?.values?.join(', ')}
          />
        </Stack>
      )}
    </>
  )
}

export default ThresholdTriggerSelector
