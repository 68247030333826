import { Container } from '@mui/material'
import Barcode from 'react-barcode'

import ZDialog from '../../Shared/Dialogs/ZDialog.jsx'

const BarcodeDialog = ({ isOpen, serial, onClose }) => {
  return (
    <ZDialog
      id={'barcode-modal'}
      isOpen={isOpen}
      maxWidth='md'
      fullWidth={false}
      content={
        <Container maxWidth="md">
          <Barcode value={serial}
            width={2}
            height={100}
            marginTop={60}
            marginBottom={60} />
        </Container>}
      onClose={onClose}
    />
  )
}

export default BarcodeDialog
