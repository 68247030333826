import { Divider } from '@mui/material'

import { BrandsFilter, IsUsedInRuleFilter, ModelsFilter, TriggerTypeFilter } from './index.js'

const TriggerOthersFilters = ({ filters = {}, onChange = () => { } }) => {
  return (
    <>
      <IsUsedInRuleFilter
        filters={filters}
        onChange={onChange}
      />
      <Divider />
      <BrandsFilter
        filters={filters}
        onChange={onChange}
      />
      <Divider />
      <ModelsFilter
        filters={filters}
        onChange={onChange}
      />
      <Divider />
      <TriggerTypeFilter
        filters={filters}
        onChange={onChange}
      />
    </>
  )
}

export default TriggerOthersFilters
