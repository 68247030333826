import { LineChart } from '@mui/x-charts'
import moment from 'moment-timezone'

const Chart = ({ data, measureName, unitOfMeasure }) => {

  return (
    <LineChart
      dataset={data.map(d => ({ x: new Date(d.x), y: d.y }))}
      series={[{
        type: 'line',
        curve: 'linear',
        area: false,
        dataKey: 'y',
        label: unitOfMeasure,
        id: measureName,
        color: '#129aff'
      }]}
      xAxis={[{
        scaleType: 'time',
        dataKey: 'x',
        valueFormatter: (date) => moment(date).tz('Europe/Rome').format('DD/MM HH:mm')
      }]}
      grid={{ vertical: true }}
      slotProps={{ legend: { hidden: true } }}
      margin={{
        left: 60,
        right: 40,
        top: 20,
        bottom: 20
      }}
      height={200}
    />
  )
}

export default Chart
