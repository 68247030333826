import {
  Autocomplete,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  ListItem,
  ListItemText,
  TextField
} from '@mui/material'
import { t } from 'i18next'
import { useEffect, useState } from 'react'

import CloseDialogButton from '../../Shared/Dialogs/CloseDialogButton.jsx'
import { useAutomations } from '../Contexts/AutomationsContext.jsx'

const ActionSelector = ({ isOpen, onClose, onChange, rule, organization }) => {
  const { useSearchActions } = useAutomations()

  const [actions, setActions] = useState()

  useEffect(() => {
    useSearchActions({
      page: 1,
      pageSize: 100,
      organizationKey: organization?.key
    }).then((res) => setActions(res))
  }, [])

  const handleClose = () => {
    onClose()
  }
  const handleChangeSelectedAction = (_event, value) => {
    onChange(value)
    onClose()
  }

  const actionFilters = {
    'scheduled-trigger': actions?.data?.filter((a) => a.type === 'downlink') || [],
    'trigger': actions?.data?.filter((a) => a.type !== 'alarm') || [],
    'measure-trigger': actions?.data?.filter((a) => a.type !== 'alarm') || []
  }

  const actionOptions = actions?.data ? actionFilters[rule.trigger?.type] || actions?.data || [] : []

  return (
    <Dialog
      fullWidth={true}
      maxWidth={'sm'}
      open={isOpen}
      onClose={handleClose}
      scroll="body"
      sx={{ borderRadius: 100 }}
    >
      <DialogTitle
        display="flex"
        justifyContent="center"
        variant="h4"
      >
        {t('rules.selectAction')}
      </DialogTitle>

      <CloseDialogButton onClose={handleClose} />

      <DialogContent
        style={{
          paddingTop: '30px',
          minHeight: '15vh',
          maxHeight: '15vh'
        }}
      >
        <Container maxWidth="md">
          <Autocomplete
            id="action-selector"
            loading={!actions}
            options={actionOptions}
            disableClearable={true}
            onChange={handleChangeSelectedAction}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t('rules.selectAction')}
                variant="standard"
              />
            )}
            renderOption={(props, option) => (
              <ListItem
                {...props}
                divider
                key={option.id}
              >
                <ListItemText
                  primary={option.name}
                  secondary={option.organizationKey}
                />
              </ListItem>
            )}
          />
        </Container>
      </DialogContent>
    </Dialog>
  )
}

export default ActionSelector
