import { CloseRounded as CloseRoundedIcon } from '@mui/icons-material'
import { IconButton } from '@mui/material'

const CloseDialogButton = ({ onClose }) => {
  return (
    <IconButton
      id="filters-dialog-close-button"
      aria-label="close"
      sx={{
        position: 'absolute',
        right: 8,
        top: 8
      }}
      onClick={onClose}
    >
      <CloseRoundedIcon />
    </IconButton>
  )
}

export default CloseDialogButton
