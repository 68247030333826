import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material'
import { t } from 'i18next'
import { useEffect } from 'react'

const ExclusiveNumericValueSelector = ({
  trigger,
  onTriggerChange,
  readonly
}) => {
  useEffect(() => {
    onTriggerChange({
      trigger: {
        operator: 'position',
        values: [1]
      }
    })
  }, [])

  const handleRadioChange = (_, value) => {
    onTriggerChange({
      trigger: {
        operator: 'position',
        values: [value]
      }
    })
  }

  return (
    <>
      <FormControl>
        <FormLabel>
          <h3>{t('triggers.operators.label')}</h3>
        </FormLabel>
        <RadioGroup
          row
          value={trigger?.values[0]}
          onChange={handleRadioChange}
          defaultValue={'present'}
        >
          <FormControlLabel
            value="1"
            control={<Radio />}
            label={t('triggers.operators.present')}
            disabled={readonly}
          />
          <FormControlLabel
            value="0"
            control={<Radio />}
            label={t('triggers.operators.notPresent')}
            disabled={readonly}
          />
        </RadioGroup>
      </FormControl>
    </>
  )
}

export default ExclusiveNumericValueSelector
