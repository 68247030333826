import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import { t } from 'i18next'
import { useEffect, useState } from 'react'

import { useGlobalContext } from '../../Shared/Contexts/GlobalContext.jsx'
import CloseDialogButton from '../../Shared/Dialogs/CloseDialogButton.jsx'
import { useOrganizations } from '../Contexts/OrganizationsContext.jsx'
import OrganizationForm from '../Form/OrganizationForm.jsx'

const OrganizationEdit = ({ organizationId, isOpen, setIsOpen, onOrganizationEdit, onEnableEdit, readonly }) => {
  const { useEditOrganization, useFetchOrganization } = useOrganizations()
  const { openErrorAlert, openSuccessAlert } = useGlobalContext()

  const [isOrganizationDataFetching, setIsOrganizationDataFetching] = useState(true)
  const [organization, setOrganization] = useState()
  const [orgData, setOrgData] = useState(null)
  const [isValid, setIsValid] = useState(false)

  const handleClose = () => {
    setIsOpen(false)
    setOrgData(null)
    onOrganizationEdit()
  }

  const handleOrganizationFormChange = (orgData, isValid) => {
    setIsValid(isValid)
    setOrgData(orgData)
  }

  const handleSaveOrganization = () => {
    const payload = {
      data: {
        name: orgData.organizationName,
        domain: orgData.domain,
        timeZone: orgData.timezone,
        orgProfileId: orgData.profile?.id || null
      },
      logo: orgData.file,
      organizationId: organizationId
    }
    useEditOrganization(payload)
      .then(() => {
        handleClose()
        openSuccessAlert()
      })
      //TODO: 2025-01-02 usare i keys
      .catch((err) => openErrorAlert({ keys: err.data.message }))
  }

  useEffect(() => {
    setIsOrganizationDataFetching(true)
    useFetchOrganization(organizationId).then((res) => {
      setOrganization(res.data)
      setIsOrganizationDataFetching(false)
    })
  }, [organizationId])

  return (
    <Dialog
      maxWidth="md"
      scroll="body"
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="edit-organization-modal-title"
      aria-describedby="edit-organization-modal-description">
      <CloseDialogButton onClose={handleClose} />

      <DialogTitle
        variant="h4"
        display="flex"
        justifyContent="center">
        {t('organizations.edit')}: {organization?.name}
      </DialogTitle>

      <DialogContent>
        {!isOrganizationDataFetching && (
          <OrganizationForm
            onChange={handleOrganizationFormChange}
            organization={organization}
            readonly={readonly}
          />
        )}
      </DialogContent>
      <DialogActions>
        {!readonly ?
          <Button
            id="edit-organization-confirm-save-button"
            variant="contained"
            onClick={handleSaveOrganization}
            disabled={!isValid}>
            {t('common.confirmAndSave')}
          </Button>
          :
          <Button
            id="enable-edit-organization"
            variant="contained"
            onClick={onEnableEdit}>
            {t('common.enableEdit')}
          </Button>
        }
      </DialogActions>
    </Dialog>
  )
}

export default OrganizationEdit
