import { saveAs } from 'file-saver'
import { createContext, useContext } from 'react'

import sanitizer, { sanitizeFilters } from '../../../helpers/sanitizer'
import { useAuth } from '../../Shared/Contexts/AuthContext'

const DevicesContext = createContext({})

export const useDevices = () => {
  return useContext(DevicesContext)
}

export const DevicesProvider = ({ children }) => {
  const { useBaseAxiosPost, useBaseAxiosDelete, useBaseAxiosGet, useBaseAxiosPut, useBaseDownload } = useAuth()

  const useSearchDevices = async ({ filters, page, pageSize }) => {
    return useBaseAxiosPost({
      url: `/devices/search?page=${page}&pageSize=${pageSize}`,
      payload: sanitizeFilters(filters)
    })
  }

  const useSearchDevicesAutomations = async ({ payload }) => {
    return useBaseAxiosPost({
      url: '/devices/search',
      params: {
        sort: 'name:ASC'
      },
      payload: sanitizer(payload)
    })
  }

  const useFetchAutomationDevices = async ({ filters, page, pageSize }) => {
    return useBaseAxiosPost({
      url: '/devices/search',
      params: {
        page,
        pageSize
      },
      payload: sanitizer(filters)
    })
  }

  const useSearchDownlinks = async ({ brand, model }) => {
    return useBaseAxiosGet({
      url: `/brands/${brand}/models/${model}/actions?withFields=true`
    })
  }

  const useDeleteDevice = async ({ id }) => {
    return useBaseAxiosDelete({ url: `/devices/${id}` })
  }

  const useFetchDevice = async ({ id }) => {
    return useBaseAxiosGet({ url: `/devices/${id}` })
  }

  const useFetchBrands = async () => {
    return useBaseAxiosGet({ url: '/brands' })
  }

  const useFetchModels = async (brandId) => {
    return useBaseAxiosGet({ url: `/models?brandId=${brandId}` })
  }

  const useFetchDevices = async ({ filters, sort, direction, page, pageSize }) => {
    return useBaseAxiosPost({
      url: '/devices/search',
      params: {
        page,
        pageSize,
        sort: sort ? `${sort}:${direction}` : undefined
      },
      payload: sanitizer(filters)
    })
  }

  const useFetchDeviceGroups = async () => {
    return useBaseAxiosGet({ url: '/device-groups' })
  }

  const useFetchDeviceTypes = async () => {
    return useBaseAxiosGet({ url: '/device-types' })
  }

  const useFetchDeviceBrands = async () => {
    return useBaseAxiosGet({ url: '/brands' })
  }

  const useFetchDeviceModels = async (brandId) => {
    return useBaseAxiosGet({ url: `/models?brandId=${brandId}` })
  }

  const useFetchDownlinks = async (id) => {
    return useBaseAxiosGet({ url: `/devices/${id}/downlinks` })
  }

  const useFetchNetworkServers = async (organizationKey) => {
    return useBaseAxiosGet({
      url: `/organizations/${organizationKey}/network-servers`
    })
  }

  const useFetchNetworkServerUsers = async ({ networkServer }) => {
    return useBaseAxiosGet({
      url: `/${networkServer}/users`
    })
  }

  const useFetchNetworkServerApplications = async ({ nsUserId, networkServer }) => {
    return useBaseAxiosGet({
      url: `${networkServer}/${nsUserId}/applications`
    })
  }

  const useFetchProvisioningSetup = async ({ brandEncoded, modelEncoded }) => {
    return useBaseAxiosGet({
      url: `/brands/${brandEncoded}/models/${modelEncoded}/provisioningSetup`
    })
  }

  const useFetchDeviceMeasurements = async ({ id, measureNames, from, to }) => {
    return useBaseAxiosPost({
      url: `/devices/${id}/measurements/chart`,
      payload: {
        from,
        to,
        limit: 90,
        measureNames: measureNames
      }
    })
  }

  const useUpdateDevice = async ({ id, payload }) => {
    return useBaseAxiosPut({
      url: `/devices/${id}`,
      payload: payload
    })
  }

  const useCreateDevice = async (payload) => {
    return useBaseAxiosPost({
      url: '/devices',
      payload
    })
  }

  const useImportDevices = async (payload) => {
    return useBaseAxiosPost({
      url: '/devices/bulk-import',
      payload
    })
  }

  const useSendDownlinkAction = async ({ payload, deviceId }) => {
    return useBaseAxiosPost({
      url: `devices/${deviceId}/downlinks`,
      payload
    })
  }

  const useEncodeFields = async ({ payload, brandEncoded, modelEncoded, actionName }) => {
    return useBaseAxiosPut({
      url: `/brands/${brandEncoded}/models/${modelEncoded}/actions/${actionName}`,
      payload: payload
    })
  }

  const useFetchPlaces = async (place) => {
    return useBaseAxiosPost({
      url: '/devices/geocode',
      payload: { address: place }
    })
  }

  const useFetchDeviceNetwork = async ({ id, from, to }) => {
    return useBaseAxiosPost({
      url: `/devices/${id}/network/chart`,
      payload: {
        from,
        to
      }
    })
  }

  const useFetchDeviceStatistics = async ({ id, from, to }) => {
    return useBaseAxiosPost({
      url: `/devices/${id}/statistics/chart`,
      payload: {
        from,
        to,
        limit: 90
      }
    })
  }

  const useExportDevicesMeasurementsDownload = async ({ payload }) => {
    return useBaseDownload({
      method: 'post',
      url: '/devices/measurements-export',
      payload
    }).then((response) => saveAs(response.data, `measurements-export - ${payload.measuresFilters.from}-${payload.measuresFilters.to}.xlsx`))
  }

  const useExportDevicesStatisticsDownload = async ({ payload }) => {
    return useBaseDownload({
      method: 'post',
      url: '/devices/statistics-export',
      payload
    }).then((response) => saveAs(response.data, `statistics-export - ${payload.measuresFilters.from}-${payload.measuresFilters.to}.xlsx`))
  }

  const useDeviceMeasurementsDownload = async ({ id, name, serial, from, to }) => {
    return useBaseDownload({
      url: `/devices/${id}/measurements/excel?from=${from}&to=${to}`
    })
      .then((response) => saveAs(response.data, `${name}${serial} - ${from}-${to}.xlsx`))
      .catch((err) => console.log(err))
  }

  const useDeviceStatisticsDownload = async ({ id, name, serial, from, to }) => {
    return useBaseDownload({
      url: `/devices/${id}/statistics/excel?from=${from}&to=${to}`
    }).then((response) => saveAs(response.data, `${name}${serial} - ${from}-${to}.xlsx`))
  }

  const useDeviceLinkStatusesDownload = async ({ id, name, serial, from, to }) => {
    return useBaseDownload({
      url: `/devices/${id}/link-status/excel?from=${from}&to=${to}`
    }).then((response) => saveAs(response.data, `${name}${serial} - ${from}-${to}.xlsx`))
  }

  const useDownloadImportTemplate = ({ modelInfo }) => {
    return useBaseDownload({
      url: `/devices/brands/${modelInfo.model.brandNameEncoded}/models/${modelInfo.model.nameEncoded}/bulk-import-template-file`
    }).then((response) => saveAs(response.data, 'devices_bulk_import.xlsx'))
  }

  const value = {
    useFetchBrands,
    useFetchModels,
    useFetchDevices,
    useSearchDevices,
    useDeleteDevice,
    useFetchDevice,
    useUpdateDevice,
    useCreateDevice,
    useFetchDeviceGroups,
    useFetchDeviceTypes,
    useFetchProvisioningSetup,
    useSearchDownlinks,
    useFetchDeviceBrands,
    useFetchDeviceModels,
    useImportDevices,
    useSendDownlinkAction,
    useEncodeFields,
    useFetchNetworkServers,
    useFetchNetworkServerUsers,
    useFetchNetworkServerApplications,
    useFetchPlaces,
    useFetchDeviceMeasurements,
    useFetchDeviceNetwork,
    useFetchDeviceStatistics,
    useFetchDownlinks,
    useSearchDevicesAutomations,
    useFetchAutomationDevices,
    useExportDevicesMeasurementsDownload,
    useExportDevicesStatisticsDownload,
    useDeviceMeasurementsDownload,
    useDeviceStatisticsDownload,
    useDeviceLinkStatusesDownload,
    useDownloadImportTemplate
  }

  return <DevicesContext.Provider value={value}>{children}</DevicesContext.Provider>
}
