import { CorporateFareRounded as CorporateFareRoundedIcon } from '@mui/icons-material'
import {
  Autocomplete,
  CircularProgress,
  Icon,
  InputAdornment,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField
} from '@mui/material'
import { t } from 'i18next'
import { useEffect, useState } from 'react'

import { useRoles } from '../../../Roles/Contexts/RolesContext'

const RolesFilter = ({ onChange = () => { }, filters }) => {
  const { useFetchRolesStandardQuery } = useRoles()
  const [roles, setRoles] = useState([])
  const [{ isFetchRolesError, isFetchRolesLoading }, setFetchStatus] = useState(
    {}
  )

  useEffect(() => {
    setFetchStatus({
      isFetchRolesError: false,
      isFetchRolesLoading: true
    })
    useFetchRolesStandardQuery()
      .then((res) => {
        setRoles(res.data)
        setFetchStatus({
          isFetchRolesError: false,
          isFetchRolesLoading: false
        })
      })
      .catch(() => {
        setFetchStatus({
          isFetchRolesError: true,
          isFetchRolesLoading: false
        })
      })
  }, [])

  const handleChange = (_, value) => {
    const roleIds = value.map((i) => i.key)
    const rolesIdsFilter =
      roleIds.length > 0 ? { roleIds: roleIds } : { roleIds: undefined }
    onChange({
      ...filters,
      ...rolesIdsFilter
    })
  }
  const { roleIds = [] } = filters

  return (
    <>
      <Autocomplete
        multiple
        disabled={isFetchRolesError || isFetchRolesLoading}
        id="roles-filter"
        options={roles.map((role) => ({
          key: role.id,
          value: role.name
        }))}
        onChange={handleChange}
        isOptionEqualToValue={(option, value) => option.key === value.key}
        loading={isFetchRolesLoading}
        getOptionLabel={(option) => option.value}
        value={roles
          .filter(({ id }) => roleIds.includes(id))
          .map((role) => ({
            key: role.id,
            value: role.name
          }))}
        renderOption={(props, option) => (
          <ListItem
            {...props}
            divider
            key={props.key}
          >
            <ListItemIcon>
              <CorporateFareRoundedIcon fontSize="large" />
            </ListItemIcon>
            <ListItemText
              primaryTypographyProps={{
                fontSize: '0.8rem',
                fontWeight: 500
              }}
              primary={option.value}
            />
          </ListItem>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={t('filters.roles.placeholder')}
            slotProps={{
              ...params.InputProps,
              endAdornment: (
                <InputAdornment position="end">
                  <Icon
                    color="inherit"
                    edge="end"
                  >
                    {isFetchRolesLoading && (
                      <CircularProgress
                        edge="end"
                        size={20}
                      />
                    )}
                  </Icon>
                </InputAdornment>
              )
            }}
          />
        )}
      />
    </>
  )
}

export default RolesFilter
