import { Button, Container, Divider, Stack, Typography } from '@mui/material'
import { t } from 'i18next'

import Can from '../../Layout/Can/Can.jsx'
import ListStandard from '../Lists/ListStandard.jsx'
import ListSuper from '../Lists/ListSuper.jsx'

const Users = () => {
  return (
    <Container
      maxWidth="lg"
      sx={{ py: 3 }}
    >
      <Can action="superroles_r">
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={2}
          >
            <Typography variant="h2">{t('common.superroles')}</Typography>
          </Stack>

          <Button
            variant="contained"
            color="primary"
          >
            {t('superroles.add')}
          </Button>
        </Stack>

        <ListSuper />
      </Can>

      <Divider sx={{ my: 5 }} />

      <Can action="roles_r">
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={2}
          >
            <Typography variant="h2">{t('common.roles')}</Typography>
          </Stack>

          <Button
            variant="contained"
            color="primary"
          >
            {t('roles.add')}
          </Button>
        </Stack>
        <ListStandard />
      </Can>
    </Container>
  )
}

export default Users
