import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Autocomplete, Box, CircularProgress, Stack, Tab, TextField, Typography } from '@mui/material'
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api'
import { t } from 'i18next'
import Joi from 'joi'
import { useCallback, useEffect, useState } from 'react'

import { GOOGLE_MAPS_API_KEY } from '../../../configs/environment.js'
import useFieldValidation from '../../../helpers/fieldValidation.js'
import { useDevices } from '../Contexts/DevicesContext.jsx'

const schema = Joi.object({
  address: [Joi.string().optional(), Joi.allow(null)],
  city: [Joi.string().optional(), Joi.allow(null)],
  province: [Joi.string().optional(), Joi.allow(null)],
  region: [Joi.string().optional(), Joi.allow(null)],
  country: [Joi.string().optional(), Joi.allow(null)],
  position: {
    latitude: Joi.number().required(),
    longitude: Joi.number().required()
  }
})

const defaultCenter = {
  lat: 45.745,
  lng: 9.823
}

const containerStyle = {
  width: '100%',
  height: '280px'
}

const mapOptions = {
  mapTypeControl: false
}

const Position = ({ setIsValid, onChange }) => {
  const { useFetchPlaces } = useDevices()

  const [places, setPlaces] = useState([])
  const [isPlacesLoading, setIsPlacesLoading] = useState(false)

  const { isLoaded } = useJsApiLoader({
    id: 'cityeye-map',
    googleMapsApiKey: GOOGLE_MAPS_API_KEY
  })

  const [validation, setValidation] = useState(false)
  const [position, setPosition] = useState({
    position: {
      latitude: defaultCenter.lat,
      longitude: defaultCenter.lng
    }
  })

  // eslint-disable-next-line no-unused-vars
  const [map, setMap] = useState(null)
  const [tab, setTab] = useState('0')

  const onLoad = useCallback((map) => {
    setMap(map)
  }, [])

  const onUnmount = useCallback(() => {
    setMap(null)
  }, [])

  const handleChangeTab = (_, value) => {
    if (position?.position === null)
      setPosition({
        ...position,
        position: {
          latitude: defaultCenter.lat,
          longitude: defaultCenter.lng
        }
      })

    setTab(value)
  }

  const onInputChange = (_, value) => {
    if (value?.length > 3) {
      setIsPlacesLoading(true)

      useFetchPlaces(value)
        .then((res) => {
          setPlaces(res.data)
        })
        .finally(() => {
          setIsPlacesLoading(false)
        })
    }
  }

  const onChangeAddress = (_, value) => {
    if (!value) {
      return
    }

    setPosition({
      ...position,
      address: value.address,
      city: value.city,
      province: value.province,
      region: value.region,
      country: value.country,
      position: {
        latitude: value.lat,
        longitude: value.lng
      }
    })
  }

  const onChangeLongitude = (event) => {
    setPosition({
      ...position,
      position: {
        ...position.position,
        [event.target.name]: parseFloat(event.target.value)
      }
    })
  }

  const onChangeLatitude = (event) => {
    setPosition({
      ...position,
      position: {
        ...position.position,
        [event.target.name]: parseFloat(event.target.value)
      }
    })
  }

  useEffect(() => {
    const newValidation = useFieldValidation(position, schema)
    setValidation(newValidation)
    setIsValid(newValidation.isValid)
    onChange(position)
  }, [position])

  return (
    <Stack
      direction="column"
      spacing={5}
    >
      <Typography
        variant="h2"
        py={1}
      >
        {t('devices.addPosition')}
      </Typography>

      <TabContext value={tab}>
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={1}
          mt={4}
        >
          <Box sx={{
            borderBottom: 1,
            borderColor: 'divider'
          }}>
            <TabList
              onChange={handleChangeTab}
              aria-label="SelectPositionMethod"
            >
              <Tab
                label={t('common.findAddress')}
                value="0"
              />
              <Tab
                label={t('common.findCoordinates')}
                value="1"
              />
            </TabList>
          </Box>
        </Stack>

        <TabPanel
          value="0"
          sx={{ padding: '24px 0px' }}
        >
          <Autocomplete
            id="addDevice-position"
            name="address"
            loading={isPlacesLoading}
            options={places}
            getOptionLabel={(option) => option.address || position.address}
            filterOptions={(x) => x}
            isOptionEqualToValue={(option, value) => option.address === value}
            value={position.address}
            onChange={onChangeAddress}
            onInputChange={onInputChange}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t('common.address')}
                variant="standard"
                slotProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {isPlacesLoading && (
                        <CircularProgress
                          color="inherit"
                          size={20}
                        />
                      )}
                      {params.InputProps.endAdornment}
                    </>
                  )
                }}
              />
            )}
          />
        </TabPanel>

        <TabPanel
          value="1"
          sx={{ padding: '24px 0px' }}
        >
          <Stack
            direction="row"
            spacing={2}
            justifyContent="space-between"
          >
            <TextField
              label={t('common.latitude')}
              name={'latitude'}
              variant="standard"
              type="number"
              defaultValue={position?.position?.latitude || defaultCenter.lat}
              error={validation?.messages?.position?.length > 0}
              helperText={validation?.messages?.position?.join(', ')}
              fullWidth
              onChange={onChangeLatitude}
            />

            <TextField
              label={t('common.longitude')}
              name={'longitude'}
              variant="standard"
              type="number"
              defaultValue={position?.position?.longitude || defaultCenter.lng}
              error={validation?.messages?.position?.length > 0}
              helperText={validation?.messages?.position?.join(', ')}
              fullWidth
              onChange={onChangeLongitude}
            />
          </Stack>
        </TabPanel>
      </TabContext>

      {isLoaded && (
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={{
            lat: position.position?.latitude || defaultCenter.lat,
            lng: position.position?.longitude || defaultCenter.lng
          }}
          zoom={18}
          options={mapOptions}
          onUnmount={onUnmount}
          onLoad={onLoad}
        >
          <Marker
            position={{
              lat: position.position?.latitude || defaultCenter.lat,
              lng: position.position?.longitude || defaultCenter.lng
            }}
          />
        </GoogleMap>
      )}
    </Stack>
  )
}

export default Position
