import { Box, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material'

import EnFlag from '../../../images/en.svg'
import ItFlag from '../../../images/it.svg'
import { useGlobalContext } from '../../Shared/Contexts/GlobalContext'

const LanguageMenu = ({ anchorEl, handleClose }) => {
  const { setLanguage } = useGlobalContext()

  const open = Boolean(anchorEl)

  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      onClick={handleClose}
      transformOrigin={{
        horizontal: 'right',
        vertical: 'top'
      }}
      anchorOrigin={{
        horizontal: 'right',
        vertical: 'bottom'
      }}
      sx={{ top: '20px' }}
    >
      <MenuItem onClick={() => setLanguage('it')}>
        <ListItemIcon>
          <Box
            component="img"
            sx={{
              width: '32px',
              height: '16px'
            }}
            src={ItFlag}
          />
        </ListItemIcon>
        <ListItemText>Italiano</ListItemText>
      </MenuItem>
      <MenuItem onClick={() => setLanguage('en')}>
        <ListItemIcon>
          <Box
            component="img"
            sx={{
              width: '32px',
              height: '16px'
            }}
            src={EnFlag}
          />
        </ListItemIcon>
        <ListItemText>English</ListItemText>
      </MenuItem>
    </Menu>
  )
}

export default LanguageMenu
