import { Divider } from '@mui/material'

import {
  AccessibilityFilter,
  BrandsFilter,
  ModelsFilter,
  StatusFilter,
  TagsFilter,
  VisibilityFilter
} from '../../Shared/Filters/Fields/index.js'

const FormFilters = ({ filters, onChange }) => {
  return (
    <>
      <StatusFilter
        filters={filters}
        onChange={onChange}
      />
      <Divider />
      <VisibilityFilter
        filters={filters}
        onChange={onChange}
      />
      <Divider />
      <AccessibilityFilter
        filters={filters}
        onChange={onChange}
      />
      <Divider />
      <BrandsFilter
        filters={filters}
        onChange={onChange}
      />
      <Divider hidden={true} />
      <ModelsFilter
        filters={filters}
        onChange={onChange}
      />
      <Divider hidden={true} />
      <TagsFilter
        filters={filters}
        onChange={onChange}
      />
    </>
  )
}

export default FormFilters
