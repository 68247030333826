import { Autocomplete, Stack, TextField, Typography } from '@mui/material'
import { t } from 'i18next'

const ActionTypeFilter = ({ filters = {}, onChange = () => { } }) => {
  const filterOptions = [
    {
      label: t('actions.alarm'),
      value: 'alarm'
    },
    {
      label: t('actions.webhook'),
      value: 'webhook'
    },
    {
      label: t('actions.notification'),
      value: 'notification'
    },
    {
      label: t('actions.downlink'),
      value: 'downlink'
    }
  ]

  const handleChange = (_, data) => {
    onChange({ actionTypes: data ? [data.value] : undefined })
  }

  return (
    <>
      <Stack
        direction="column"
        justifyContent="space-between"
        alignItems="left"
        spacing={2}
        mt={3}
        mb={3}
      >
        <Typography
          align="left"
          sx={{ fontWeight: 'bold' }}
        >
          {t('actions.actionType')}
        </Typography>
        <Autocomplete
          id="action-type-filter"
          options={filterOptions}
          onChange={handleChange}
          isOptionEqualToValue={(option, value) => option.value === value}
          getOptionLabel={(option) => option?.label || ''}
          value={
            filters?.actionTypes
              ? filterOptions.find(
                (f) => f.value === filters?.actionTypes[0]
              ) || ''
              : ''
          }
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
            />
          )}
        />
      </Stack>
    </>
  )
}

export default ActionTypeFilter
