import {
  Autocomplete,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  ListItem,
  ListItemText,
  Stack,
  TextField,
  Typography
} from '@mui/material'
import { t } from 'i18next'
import { useEffect, useState } from 'react'

import CloseDialogButton from '../../Shared/Dialogs/CloseDialogButton.jsx'
import { useAutomations } from '../Contexts/AutomationsContext.jsx'

const sortFn = (a, b) => a.rules.length - b.rules.length

const TriggerSelector = ({ isOpen, onClose, onChange, organization }) => {
  const { useSearchTriggers } = useAutomations()
  const [isTriggersLoading, setIstriggersLoading] = useState(false)

  const [triggers, setTriggers] = useState()

  const searchTriggers = ({ filters, page }) => {
    setIstriggersLoading(true)
    useSearchTriggers({
      filters,
      page,
      pageSize: 1000
    })
      .then((res) => setTriggers(res))
      .finally(() => setIstriggersLoading(false))
  }

  const handleClose = () => onClose()

  const handleChangeSelectedTrigger = (_event, value) => {
    if (value.rules.length) {
      return
    }
    onChange(value)
    onClose()
  }

  useEffect(() => {
    searchTriggers({
      filters: {},
      page: 1
    })
  }, [])

  useEffect(() => {
    if (organization) {
      const filters = { organizationKeys: [organization.key] }
      searchTriggers({
        filters,
        page: 1
      })
    }
  }, [organization])

  return (
    <Dialog
      fullWidth={true}
      maxWidth={'sm'}
      scroll="body"
      open={isOpen}
      onClose={handleClose}>
      <DialogTitle
        display="flex"
        justifyContent="center"
        variant="h4">
        {t('rules.selectTrigger')}
      </DialogTitle>

      <CloseDialogButton onClose={handleClose} />

      <DialogContent
        style={{
          paddingTop: '30px',
          minHeight: '15vh',
          maxHeight: '15vh'
        }}>
        <Container maxWidth="md">
          <Autocomplete
            id="trigger-select"
            loading={isTriggersLoading}
            options={triggers?.data?.sort(sortFn) || []}
            getOptionDisabled={(option) => option.rules.length > 0}
            onChange={handleChangeSelectedTrigger}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <TextField
                name="trigger-select-label"
                {...params}
                label={t('rules.selectTrigger')}
                variant="standard"
              />
            )}
            renderOption={(props, option) =>
              !option.rules.length ? (
                <ListItem
                  {...props}
                  divider
                  key={option.id}>
                  <ListItemText
                    primary={option.name}
                    secondary={option.organizationKey}
                  />
                </ListItem>
              ) : (
                <ListItem
                  sx={{ backgroundColor: 'rgba(0, 0, 0, 0.08)' }}
                  {...props}
                  divider
                  key={option.id}>
                  <Stack>
                    <ListItemText
                      primary={option.name}
                      secondary={option.organizationKey}
                    />
                    <Typography variant="body">
                      {t('rules.usedInRule')}: {option.rules[0].name}
                    </Typography>
                  </Stack>
                </ListItem>
              )
            }
          />
        </Container>
      </DialogContent>
    </Dialog>
  )
}

export default TriggerSelector

//'rgba(237, 108, 2, 0.80)'
