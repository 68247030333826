import { Stack } from '@mui/material'
import { t } from 'i18next'
import { useState } from 'react'

import Can from '../../Layout/Can/Can.jsx'
import Filter from '../../Layout/Dialogs/Filter.jsx'
import { useAuth } from '../../Shared/Contexts/AuthContext.jsx'
import { OrganizationsFilter } from '../../Shared/Filters/Fields/index.js'
import FiltersOthers from './Filters/Others.jsx'

const Filters = ({ filters, onChange }) => {
  const [selectedOrganizations, setSelectedOrganizations] = useState(false)
  const [selectedOther, setSelectedOther] = useState(false)

  const { currentUser } = useAuth()

  const isSuperAdmin = currentUser.isSuperAdmin
  const orgPermissions = currentUser.currentOrganizationKey
    ? currentUser.permissions[currentUser.currentOrganizationKey] || []
    : []

  const handleOrganizationsChange = (data) => {
    setSelectedOrganizations(true)
    onChange({
      ...filters,
      ...data
    })
  }

  const handleOthersFiltersChange = (data) => {
    setSelectedOther(true)
    onChange({
      ...filters,
      ...data
    })
  }

  const handleOnDeleteOrganizations = () => {
    setSelectedOrganizations(false)
    onChange({
      ...filters,
      organizationKeys: [],
      searchInSubOrganizations: false
    })
  }

  const handleOnDeleteOther = () => {
    setSelectedOther(false)
    onChange({
      ...filters,
      status: [],
      triggerTypes: []
    })
  }

  const filterUnits = [
    {
      label: t('filters.devices.organizations.label'),
      id: 'chip-filters-events-organizations',
      selected: selectedOrganizations,
      onDelete: handleOnDeleteOrganizations,
      hidden: !orgPermissions.includes('organizations_r') && !isSuperAdmin,
      content: (
        <OrganizationsFilter
          onChange={handleOrganizationsChange}
          filters={filters}
        />
      )
    },
    {
      label: t('common.filters.other'),
      id: 'chip-filters-events-other',
      selected: selectedOther,
      onDelete: handleOnDeleteOther,
      content: (
        <FiltersOthers
          onChange={handleOthersFiltersChange}
          filters={filters}
        />
      )
    }
  ]

  return (
    <Stack
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      spacing={2}
    >
      {[...filterUnits].map((e, i) => (
        <Can
          action="devices_r"
          key={`device-filter-${i}`}
        >
          <Filter
            key={`device-filter-${i}`}
            id={e.id}
            onClick={e.onClick}
            onDelete={e.onDelete}
            selected={e.selected}
            title={e.label}
            hidden={e.hidden || false}
            content={e.content}
            count={e.count}
          />
        </Can>
      ))}
    </Stack>
  )
}

export default Filters
