import { Alert, AlertTitle, Slide, Snackbar } from '@mui/material'
import { t } from 'i18next'

import Show from '../../Layout/Can/Show.jsx'
import { useGlobalContext } from '../Contexts/GlobalContext.jsx'

const ErrorAlert = () => {
  const { errorAlert: isOpen, setErrorAlert, errorAlertMessage: message } = useGlobalContext()

  return (
    <Show when={() => isOpen}>
      <Snackbar
        open={isOpen}
        onClose={() => setErrorAlert(false)}
        autoHideDuration={5000}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        TransitionComponent={(props) => (
          <Slide
            {...props}
            direction="down"
          />
        )}
        sx={{
          width: '20%'
        }}>
        <Alert
          severity="error"
          sx={{
            width: '400px',
            position: 'fixed',
            bottom: '10px',
            right: '5px',
            zIndex: 999999
          }}>
          <AlertTitle>{t('common.error')}</AlertTitle>
          {message}
        </Alert>
      </Snackbar>
    </Show>
  )
}

export default ErrorAlert
