import { Box, Button, Stack, Typography } from '@mui/material'
import { t } from 'i18next'
import { useEffect, useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'

import { useValidationEffect } from '../../../helpers/hooks.js'
import Can from '../../Layout/Can/Can.jsx'
import Show from '../../Layout/Can/Show'
import ZList from '../../Shared/Components/ZList'
import { useGlobalContext } from '../../Shared/Contexts/GlobalContext.jsx'
import ConfirmDialog from '../../Shared/Dialogs/ConfirmDialog'
import RuleCard from '../Card/RuleCard.jsx'
import { useAutomations } from '../Contexts/AutomationsContext'
import AddRule from '../Dialogs/AddRule'
import RuleDetail from '../Dialogs/RuleDetail'
import RulesFilters from '../Filters/RulesFilters.jsx'

const DEFAULT_PAGE_SIZE = 25

const AutomationRulesList = () => {
  const { useSearchRules, useDeleteRule } = useAutomations()
  const { openErrorAlert, openSuccessAlert } = useGlobalContext()
  const [searchParams] = useSearchParams()
  const fullText = searchParams.get('search')

  const { tab, entityId } = useParams()
  const [filters, setFilters] = useState({})

  const [rules, setRules] = useState()
  const [isFetchRulesLoading, setIsFetchRulesLoading] = useState(false)
  const [isFetchRulesError, setIsFetchRulesError] = useState(false)

  const [ruleId, setRuleId] = useState(null)
  const [ruleName, setRuleName] = useState(null)
  const [isAddRuleOpen, setIsAddRuleOpen] = useState(false)
  const [isRuleDetailOpen, setIsRuleDetailOpen] = useState(false)
  const [selectedRuleId, setSelectedRuleId] = useState(null)
  const [confirmDeleteRuleIsOpen, setConfirmDeleteRuleIsOpen] = useState(false)

  const [openReadonlyDialog, setOpenReadonlyDialog] = useState(false)

  const [page, setPage] = useState(1)

  const handleChangePage = (_, value) => setPage(value)

  const handleOnEdit = (rule) => {
    setSelectedRuleId(rule.id)
    setIsRuleDetailOpen(true)
  }

  const handleOnDelete = (rule) => {
    setRuleId(rule.id)
    setRuleName(rule.name)
    setConfirmDeleteRuleIsOpen(true)
  }

  const searchRules = ({ filters, page }) => {
    setIsFetchRulesLoading(true)
    setIsFetchRulesError(false)
    useSearchRules({
      filters: filters,
      page: page,
      pageSize: DEFAULT_PAGE_SIZE
    })
      .then((res) => setRules(res))
      .catch(() => setIsFetchRulesError(true))
      .finally(() => setIsFetchRulesLoading(false))
  }

  const handleCloseRule = (reload) => {
    if (reload) {
      setPage(1)
      searchRules({
        filters: {
          ...filters,
          fullText
        },
        page: 1
      })
    }
    setOpenReadonlyDialog(false)
    setSelectedRuleId(null)
    setIsRuleDetailOpen(false)
    setIsAddRuleOpen(false)
  }

  useEffect(() => {
    if (tab !== 'rules') {
      return
    }

    setOpenReadonlyDialog(true)
    setSelectedRuleId(entityId)
    setIsRuleDetailOpen(true)
  }, [tab, entityId])

  useEffect(() => {
    setPage(1)
    searchRules({
      filters: {
        ...filters,
        fullText
      },
      page: 1
    })
  }, [fullText])

  useValidationEffect(() => {
    searchRules({
      filters: {
        ...filters,
        fullText
      },
      page: page
    })
  }, [page])

  const handleFiltersChange = (filters) => {
    setFilters(filters)
    searchRules({
      filters: {
        ...filters,
        fullText
      },
      page: 1
    })
  }

  const handleOnConfirmDeleteRule = () =>
    useDeleteRule({ id: ruleId })
      .then(() => {
        setPage(1)
        searchRules({
          filters: {
            ...filters,
            fullText
          },
          page: 1
        })
        openSuccessAlert()
        setConfirmDeleteRuleIsOpen(false)
      })
      .catch((err) => openErrorAlert({ keys: err.data.keys }))

  const handleEnableEdit = () => setOpenReadonlyDialog(false)

  const rulesList = rules?.data?.map((rule, index) => (
    <RuleCard
      index={index}
      key={`rule-${index}`}
      rule={rule}
      onDelete={handleOnDelete}
      onEdit={handleOnEdit}
    />
  ))

  const rulesFilters = (
    <RulesFilters
      filters={filters}
      onChange={handleFiltersChange}
    />
  )

  return (
    <>
      <Stack
        direction="row"
        marginBottom={3}>
        <Typography variant="body1">
          <strong>{t('automations.rules')}</strong>
        </Typography>
        <Box sx={{ flexGrow: 1 }} />
        <Can action={'devices_u'}>
          <Button
            id="rules-list-create-new-rule"
            size="small"
            variant="contained"
            color="secondary"
            onClick={() => setIsAddRuleOpen(true)}>
            {t('rules.createNew')}
          </Button>
        </Can>
      </Stack>

      <ZList
        count={rules?.count}
        filters={rulesFilters}
        entities={rulesList}
        page={page}
        isLoading={isFetchRulesLoading || isFetchRulesError}
        onPageChange={handleChangePage}
      />

      <Show when={() => isAddRuleOpen}>
        <AddRule
          isOpen={isAddRuleOpen}
          onClose={handleCloseRule}
        />
      </Show>
      <Show when={() => isRuleDetailOpen && !!selectedRuleId}>
        <RuleDetail
          isOpen={isRuleDetailOpen}
          onClose={handleCloseRule}
          selectedRuleId={selectedRuleId}
          isEdit={true}
          onEnableEdit={handleEnableEdit}
          readonly={openReadonlyDialog}
        />
      </Show>

      <ConfirmDialog
        isOpen={confirmDeleteRuleIsOpen}
        title={t('rules.deleteRule')}
        message={`${t('rules.areYouSureYouWantToDelete')} "${ruleName}"?`}
        onConfirm={handleOnConfirmDeleteRule}
        onClose={() => setConfirmDeleteRuleIsOpen(false)}
      />
    </>
  )
}

export default AutomationRulesList
