import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import { t } from 'i18next'
import { useState } from 'react'

import { useGlobalContext } from '../../Shared/Contexts/GlobalContext.jsx'
import CloseDialogButton from '../../Shared/Dialogs/CloseDialogButton.jsx'
import { useOrganizations } from '../Contexts/OrganizationsContext.jsx'
import OrganizationForm from '../Form/OrganizationForm.jsx'

const OrganizationAddDialog = ({ isOpen, onClose, onSave }) => {
  const { useCreateRootOrganization, useCreateChildOrganization } = useOrganizations()
  const { openErrorAlert, openSuccessAlert } = useGlobalContext()

  const [orgData, setOrgData] = useState(null)
  const [isValid, setIsValid] = useState(false)

  const handleClose = () => onClose(false)

  const handleOrganizationFormChange = (orgData, isValid) => {
    setIsValid(isValid)
    setOrgData(orgData)
  }

  const handleSaveOrganization = () => {
    if (orgData.parentOrganization.key === 'root') {
      const payload = {
        data: {
          key: orgData.organizationKey,
          name: orgData.organizationName,
          vatNumber: orgData.vatNumber,
          domain: orgData.domain,
          networkServerUsers: [
            {
              server: orgData.networkServer.server,
              user: orgData.networkServer.user,
              id: orgData.networkServer.userId
            }
          ],
          orgProfileId: orgData.profile.id,
          timeZone: orgData.timezone
        },
        logo: orgData.file
      }
      useCreateRootOrganization(payload)
        .then(() => {
          onSave()
          openSuccessAlert()
        })
        .catch(() => openErrorAlert())
    } else {
      const payload = {
        data: {
          key: orgData.organizationKey,
          name: orgData.organizationName,
          vatNumber: orgData.vatNumber,
          domain: orgData.domain,
          networkServerUsers: [
            {
              server: orgData.networkServer.server,
              user: orgData.networkServer.user,
              id: orgData.networkServer.userId
            }
          ],
          timeZone: orgData.timezone
        },
        logo: orgData.file
      }
      useCreateChildOrganization({
        parentOrganization: orgData.parentOrganization.key,
        payload: payload
      })
        .then(() => {
          onSave()
          openSuccessAlert()
        })
        .catch((err) => openErrorAlert({ keys: err.data }))
    }
  }

  return (
    <Dialog
      maxWidth="md"
      open={isOpen}
      onClose={handleClose}
      scroll="body"
      aria-labelledby="add-organization-modal-title"
      aria-describedby="add-organization-modal-description">
      <DialogTitle
        variant="h4"
        display="flex"
        justifyContent="center">
        {t('organizations.add')}
      </DialogTitle>

      <CloseDialogButton onClose={handleClose} />

      <DialogContent>
        <OrganizationForm onChange={handleOrganizationFormChange} />
      </DialogContent>

      <DialogActions>
        <Button
          id="add-organization-confirm-save-button"
          variant="contained"
          onClick={handleSaveOrganization}
          disabled={!isValid}>
          {t('common.confirmAndSave')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default OrganizationAddDialog
