import { Box, Card, CardContent, Stack, Typography } from '@mui/material'
import { t } from 'i18next'
import { useEffect, useState } from 'react'

import BarChart from '../../Charts/BarChart'
import { useEvents } from '../../Events/Contexts/EventsContext'
import EventsTable from '../../Events/Tables/Table'
import NotFoundMessage from '../../Shared/Components/NotFoundMessage'
import { useGlobalContext } from '../../Shared/Contexts/GlobalContext'

const EventsTab = ({ deviceId, refresh, setRefresh }) => {
  const { dateRange } = useGlobalContext()
  const { useFetchEvents, useFetchEventsSearchSummary } = useEvents()

  //TODO capire a cosa e se serve
  const [, setEventsCount] = useState(0)
  const [events, setEvents] = useState()
  const [isEventsLoading, setIsEventsLoading] = useState(false)
  const [allEvents, setAllEvents] = useState([])

  useEffect(() => {
    useFetchEventsSearchSummary({
      fromTo: {
        from: dateRange.from,
        to: dateRange.to
      },
      deviceIds: [deviceId]
    }).then((res) => setAllEvents(res.data))
  }, [dateRange])

  useEffect(() => {
    if (!isEventsLoading) {
      setEventsCount(events?.totalCount)
    }
  }, [events])

  useEffect(() => {
    setIsEventsLoading(false)

    useFetchEvents({
      from: dateRange.from,
      to: dateRange.to,
      deviceId: deviceId
    })
      .then((res) => setEvents(res.data))
      .finally(() => setIsEventsLoading(false))
  }, [dateRange])

  useEffect(() => {
    if (!refresh) {
      return
    }

    useFetchEvents({
      from: dateRange.from,
      to: dateRange.to,
      deviceId: deviceId
    })
      .then((res) => setEvents(res.data))
      .finally(() => setIsEventsLoading(false))
    setRefresh(false)
  }, [refresh])

  return (
    <>
      {allEvents?.length && events?.results?.length ? (
        <Stack spacing={20}>
          <Box
            key={'events'}
            id="events"
            sx={{ height: '400px' }}
          >
            <Typography
              variant="h5"
              align="center"
              mb={2}
            >
              {t('devices.events')}
            </Typography>
            <Card variant="standard">
              <CardContent>
                <BarChart data={allEvents} />
              </CardContent>
            </Card>
          </Box>
          <Stack
            direction="column"
            justifyContent="space-between"
            alignItems="center"
            my={4}
          >
            <EventsTable
              events={events}
              paging={{
                page: 1,
                pageSize: 999
              }}
            />
          </Stack>
        </Stack>
      ) : (
        <NotFoundMessage
          when={() => !allEvents?.length || !events?.results?.length}
        />
      )}
    </>
  )
}

export default EventsTab
