import { Box, Grid2, Stack, Typography } from '@mui/material'
import { t } from 'i18next'


const ReviewActionWebhook = ({ actionName, webhookConfig }) => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid2
        container
        marginY={2}
        spacing={2}
      >
        <Grid2
          size={6}
          py={1}
        >
          <Typography
            variant="h5"
            align="right"
          >
            {t('actions.actionName')}
          </Typography>
        </Grid2>
        <Grid2
          size={6}
          py={1}
        >
          <Typography sx={{ lineHeight: '1.2' }}>{actionName}</Typography>
        </Grid2>

        <Grid2
          size={6}
          py={1}
        >
          <Typography
            variant="h5"
            align="right"
          >
            {t('actions.actionUrl')}
          </Typography>
        </Grid2>
        <Grid2
          size={6}
          py={1}
        >
          <Typography sx={{ lineHeight: '1.2' }}>
            {webhookConfig.urlAction}
          </Typography>
        </Grid2>

        <Grid2
          size={6}
          py={1}
        >
          <Typography
            variant="h5"
            align="right"
          >
            {t('actions.sendTriggerInformation')}
          </Typography>
        </Grid2>
        <Grid2
          size={6}
          py={1}
        >
          <Typography sx={{ lineHeight: '1.2' }}>
            {webhookConfig.sendTriggerInformation ? 'Si' : 'No'}
          </Typography>
        </Grid2>

        <Grid2
          size={6}
          py={1}
        >
          <Typography
            variant="h5"
            align="right"
          >
            {t('actions.httpHeaders')}
          </Typography>
        </Grid2>
        <Grid2
          size={6}
          py={1}
        >
          {webhookConfig && webhookConfig.httpHeaders
            ? Object.keys(webhookConfig.httpHeaders).map((key, index) => (
              <Stack
                direction={'row'}
                spacing={2}
                key={index}
              >
                <Typography variant="body">{key}:</Typography>
                <Typography variant="body">
                  {webhookConfig.httpHeaders[key]}
                </Typography>
              </Stack>
            ))
            : ''}
        </Grid2>
      </Grid2>
    </Box>
  )
}

export default ReviewActionWebhook
