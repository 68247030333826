import { FormControl, FormControlLabel, Switch } from '@mui/material'
import { t } from 'i18next'


const AlarmedFilter = ({ filters = {}, onChange = () => { } }) => {
  const handleChange = (_, value) => {
    onChange({
      ...filters,
      isAlarm: value ? value : undefined
    })
  }

  return (
    <>
      <FormControl fullWidth>
        <FormControlLabel
          control={
            <Switch
              checked={filters.isAlarm}
              value={filters.isAlarm}
              onChange={handleChange}
            />
          }
          label={t('filters.devices.alarmed.label')}
          labelPlacement="end"
        />
      </FormControl>
    </>
  )
}

export default AlarmedFilter
