import { CompassCalibrationRounded as CompassCalibrationRoundedIcon } from '@mui/icons-material'
import {
  Autocomplete,
  CircularProgress,
  Icon,
  InputAdornment,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  TextField,
  Typography
} from '@mui/material'
import { t } from 'i18next'
import { useEffect, useState } from 'react'

import { useFilters } from '../../Contexts/FiltersContext'

const ModelsFilters = ({ filters = {}, onChange }) => {
  const { brandIds = [], modelIds = [] } = filters
  const { useFetchModelsFilters } = useFilters()

  const [modelsFiltersOptions, setModelsFiltersOptions] = useState([])
  const [isModelsFilterLoading, setIsModelsFilterLoading] = useState(false)

  const handleChange = (_, value) => {
    const modelIds = value.map((m) => m.id)
    onChange({ modelIds })
  }

  useEffect(() => {
    if (!brandIds.length) {
      return
    }

    setIsModelsFilterLoading(true)
    useFetchModelsFilters(brandIds)
      .then(({ data }) => setModelsFiltersOptions(data))
      .finally(() => setIsModelsFilterLoading(false))
  }, [brandIds])

  return (
    <>
      <Stack
        direction="column"
        justifyContent="space-between"
        alignItems="left"
        spacing={2}
        mt={3}
        mb={3}
      >
        <Typography
          align="left"
          sx={{ fontWeight: 'bold' }}
        >
          {t('filters.devices.other.models.label')}
        </Typography>
        <Autocomplete
          multiple
          disabled={brandIds?.length <= 0}
          id="models-filter"
          options={modelsFiltersOptions}
          onChange={handleChange}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          groupBy={(option) => option.brand_name}
          loading={isModelsFilterLoading}
          getOptionLabel={(option) => option.name}
          value={modelsFiltersOptions.filter(({ id }) => modelIds.includes(id))}
          renderOption={(props, option) => (
            <ListItem
              {...props}
              divider
              key={option.id}
            >
              <ListItemIcon>
                <CompassCalibrationRoundedIcon fontSize="large" />
              </ListItemIcon>
              <ListItemText
                primaryTypographyProps={{
                  fontSize: '0.8rem',
                  fontWeight: 500
                }}
                primary={option.name}
              />
            </ListItem>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              placeholder={t('filters.devices.other.models.placeholder')}
              slotProps={{
                ...params.InputProps,
                endAdornment: (
                  <InputAdornment position="end">
                    <Icon
                      color="inherit"
                      edge="end"
                    >
                      {isModelsFilterLoading && (
                        <CircularProgress
                          edge="end"
                          size={20}
                        />
                      )}
                    </Icon>
                  </InputAdornment>
                )
              }}
            />
          )}
        />
      </Stack>
    </>
  )
}

export default ModelsFilters
