import { Save as SaveIcon } from '@mui/icons-material'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import {
  Autocomplete,
  Box,
  Button,
  Container,
  Divider,
  FormControlLabel,
  Grid2,
  Stack,
  Switch,
  Tab,
  TextField,
  Typography
} from '@mui/material'
import { t } from 'i18next'
import Joi from 'joi'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import useFieldValidation from '../../../helpers/fieldValidation.js'
import { useAuth } from '../../Shared/Contexts/AuthContext.jsx'
import { useGlobalContext } from '../../Shared/Contexts/GlobalContext.jsx'
import { useUsers } from '../Contexts/UsersContext.jsx'
import UpdatePassword from '../Dialogs/UpdatePassword.jsx'
import InvitesTable from './InvitesTable.jsx'
import OrganizationsTable from './OrganizationsTable.jsx'

const schema = Joi.object({
  firstName: Joi.string().required(),
  lastName: Joi.string().required(),
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .required(),
  phone: Joi.string().required()
})
  .allow()
  .unknown()

const UsersProfile = () => {
  const navigate = useNavigate()
  const { useSaveUserProfile, useGetUserProfile, useFetchUserInvites } = useUsers()
  const { openErrorAlert, openSuccessAlert } = useGlobalContext()
  const {
    logout,
    currentUser: { selfOrganizations }
  } = useAuth()

  const [validation, setValidation] = useState({ isValid: false })
  const [updatePasswordIsOpen, setUpdatePasswordIsOpen] = useState(false)
  const [removedOrganizationKeys, setRemovedOrganizationKeys] = useState([])
  const [tabValue, setTabValue] = useState('1')
  const [user, setUserProfile] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    languageCode: '',
    organizations: [],
    acceptsMarketing: false,
    notificationsPreferences: { schedule: 'regular' },
    preferences: {
      defaultOrganization: ''
    }
  })

  const [invites, setInvites] = useState()

  const fetchInvites = () => {
    useFetchUserInvites({ userId: user?.uid }).then((res) => setInvites(res.data))
  }

  const fetchProfileAndInvites = async () => {
    const { data, isError } = await useGetUserProfile()
    if (!isError) {
      setUserProfile(data)
    }
  }

  const onInviteAccepted = () => {
    fetchProfileAndInvites()
    fetchInvites()
  }

  const handleOpenUpdatePassword = () => {
    setUpdatePasswordIsOpen(true)
  }

  const handleCloseUpdatePassword = (changePasswordSuccess) => {
    setUpdatePasswordIsOpen(false)

    if (changePasswordSuccess) {
      logout()
      navigate('/')
    }
  }

  const handleChange = (_event, newValue) => {
    setTabValue(newValue)
  }

  const handleSave = () => {
    useSaveUserProfile({
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      phone: user.phone,
      languageCode: user.languageCode,
      notificationsPreferences: user.notificationsPreferences,
      acceptsMarketing: user.acceptsMarketing,
      removedOrganizationKeys,
      preferences: {
        defaultOrganization: user.preferences?.defaultOrganization
      }
    })
      .then(() => openSuccessAlert())
      .catch((err) => openErrorAlert(err.data.keys))
  }

  const handleFirstName = (event) => {
    const {
      target: { value }
    } = event

    setUserProfile({
      ...user,
      firstName: value
    })
  }

  const handleLastName = (event) => {
    const {
      target: { value }
    } = event

    setUserProfile({
      ...user,
      lastName: value
    })
  }

  const handleEmail = (event) => {
    const {
      target: { value }
    } = event

    setUserProfile({
      ...user,
      email: value
    })
  }

  const handlePhoneNumber = (event) => {
    const {
      target: { value }
    } = event

    setUserProfile({
      ...user,
      phone: value
    })
  }

  const handleMarketing = (_event, value) => {
    setUserProfile({
      ...user,
      acceptsMarketing: value
    })
  }

  const handleLanguageCode = (_event, languageCode) => {
    setUserProfile({
      ...user,
      languageCode: languageCode.value
    })
  }

  const handleDefaultOrganization = (_event, defaultOrganization) => {
    setUserProfile({
      ...user,
      preferences: {
        ...user.preferences,
        defaultOrganization: defaultOrganization.value
      }
    })
  }

  const handleNotificationPreferences = (_event, notificationsPreferences) => {
    setUserProfile({
      ...user,
      notificationsPreferences: {
        ...user.notificationsPreferences,
        schedule: notificationsPreferences.value
      }
    })
  }

  const handleLeaveOrganization = (organizationKey) => {
    removedOrganizationKeys.push(organizationKey)
    const orgs = user.organizations.filter((x) => x.key !== organizationKey)
    setRemovedOrganizationKeys(removedOrganizationKeys)

    setUserProfile({
      ...user,
      organizations: orgs
    })
  }

  const handleDeleteAccount = () => {
    navigate('/delete-account')
  }

  useEffect(() => {
    fetchProfileAndInvites()
  }, [])

  useEffect(() => {
    fetchInvites()
    const validationResults = useFieldValidation(user, schema)
    setValidation(validationResults)
  }, [user])

  return (
    <Container
      maxWidth="lg"
      sx={{ py: 3 }}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}>
        <Typography variant="h2">{t('common.userProfile')}</Typography>
      </Stack>

      <Box>
        <TabContext value={tabValue}>
          <Box sx={{
            borderBottom: 1,
            borderColor: 'divider'
          }}>
            <TabList
              onChange={handleChange}
              aria-label="lab API tabs example">
              <Tab
                aria-label="tab-personal-information"
                label={t('users.personalInformation')}
                value="1"
              />
              <Tab
                aria-label="tab-preferences"
                label={t('users.preferences')}
                value="2"
              />
              <Tab
                aria-label="tab-organizations"
                label={t('common.organizations')}
                value="3"
              />
            </TabList>
          </Box>

          <TabPanel value="1">
            <Stack
              spacing={2}
              direction={'row'}
              justifyContent={'space-between'}>
              <Typography
                variant="h3"
                py={1}>
                {t('invites.basicInformation')}
              </Typography>
              <Box
                display={'flex'}
                gap={1}>
                <Button
                  id="user-profile-button-change-password"
                  variant="contained"
                  onClick={handleOpenUpdatePassword}>
                  {t('users.changePassword')}
                </Button>
                <Button
                  id="user-profile-button-delete-account"
                  variant="contained"
                  color="error"
                  onClick={handleDeleteAccount}>
                  Elimina account
                </Button>
              </Box>
            </Stack>

            <Box sx={{ flexGrow: 1 }}>
              <Grid2
                container
                rowSpacing={2}
                columnSpacing={{ xs: 1 }}>
                <Grid2 size={8}>
                  <TextField
                    id="user-profile-first-name"
                    label={t('common.name')}
                    name="firstName"
                    variant="standard"
                    error={validation?.messages?.firstName?.length > 0}
                    helperText={validation?.messages?.firstName?.join(', ')}
                    onChange={handleFirstName}
                    value={user?.firstName || ''}
                    fullWidth
                    required
                  />
                </Grid2>
                <Grid2 size={8}>
                  <TextField
                    id="user-profile-last-name"
                    label={t('common.surname')}
                    name="lastName"
                    variant="standard"
                    error={validation?.messages?.lastName?.length > 0}
                    helperText={validation?.messages?.lastName?.join(', ')}
                    onChange={handleLastName}
                    value={user?.lastName || ''}
                    fullWidth
                    required
                  />
                </Grid2>
                <Grid2 size={8}>
                  <TextField
                    id="user-profile-email"
                    label={t('common.email')}
                    name="email"
                    variant="standard"
                    error={validation?.messages?.email?.length > 0}
                    helperText={validation?.messages?.email?.join(', ')}
                    onChange={handleEmail}
                    value={user?.email || ''}
                    fullWidth
                    required
                  />
                </Grid2>
                <Grid2 size={8}>
                  <TextField
                    id="user-profile-phone"
                    label={t('common.mobile')}
                    name="phone"
                    variant="standard"
                    error={validation?.messages?.phone?.length > 0}
                    helperText={validation?.messages?.phone?.join(', ')}
                    onChange={handlePhoneNumber}
                    value={user?.phone || ''}
                    fullWidth
                    required
                  />
                </Grid2>
              </Grid2>
            </Box>
          </TabPanel>

          <TabPanel value="2">
            <Stack
              spacing={2}
              direction={'row'}
              justifyContent={'space-between'}>
              <Typography
                variant="h3"
                py={1}>
                {t('users.preferences')}
              </Typography>
            </Stack>

            <Box sx={{ flexGrow: 1 }}>
              <Grid2
                container
                rowSpacing={2}
                columnSpacing={{ xs: 1 }}>
                <Grid2 size={8}>
                  <Typography
                    variant="p"
                    py={2}>
                    {t('users.notificationFrequencySelection')}:
                  </Typography>
                  <Typography variant="body">{t('users.notificationFrequencySelectionText')}</Typography>
                </Grid2>

                <Grid2 size={8}>
                  <Autocomplete
                    fullWidth
                    disablePortal
                    onChange={handleNotificationPreferences}
                    isOptionEqualToValue={(option, value) => option.value === value.value}
                    defaultValue={{
                      label: user?.notificationsPreferences?.schedule || '',
                      value: user?.notificationsPreferences?.schedule || ''
                    }}
                    value={{
                      label: user?.notificationsPreferences?.schedule || '',
                      value: user?.notificationsPreferences?.schedule || ''
                    }}
                    options={[
                      {
                        label: t('users.notifications.instant'),
                        value: 'regular'
                      },
                      {
                        label: t('users.notifications.daily'),
                        value: 'daily'
                      },
                      {
                        label: t('users.notifications.weekly'),
                        value: 'weekly'
                      },
                      {
                        label: t('users.notifications.monthly'),
                        value: 'monthly'
                      }
                    ]}
                    sx={{ width: 300 }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={t('users.notifications.selectFrequency')}
                        variant="standard"
                      />
                    )}
                  />
                </Grid2>
              </Grid2>
            </Box>
            <Box py={2}>
              <Stack>
                <Typography
                  py={2}
                  variant="p">
                  {t('users.selectLanguage')}:
                </Typography>
                <Autocomplete
                  py={1}
                  disablePortal
                  onChange={handleLanguageCode}
                  isOptionEqualToValue={(option, value) => option.value === value.value}
                  defaultValue={{
                    value: user?.languageCode || '',
                    label: user?.languageCode || ''
                  }}
                  options={[
                    {
                      label: 'it',
                      value: 'it'
                    },
                    {
                      label: 'en',
                      value: 'en'
                    }
                  ]}
                  sx={{ width: 300 }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Lingua"
                      variant="standard"
                    />
                  )}
                />
              </Stack>
            </Box>
            <Box py={2}>
              <Stack>
                <Typography
                  py={2}
                  variant="p">
                  Scegli la tua organizzazione di default:
                </Typography>
                <Autocomplete
                  py={1}
                  disablePortal
                  onChange={handleDefaultOrganization}
                  isOptionEqualToValue={(option, value) => option.value === value.value}
                  defaultValue={{
                    value: user?.preferences?.defaultOrganization || '',
                    label: user?.preferences?.defaultOrganization || ''
                  }}
                  options={selfOrganizations.map((o) => {
                    return {
                      label: o.name,
                      value: o.key
                    }
                  })}
                  sx={{ width: 300 }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Organizzazione di default"
                      variant="standard"
                    />
                  )}
                />
              </Stack>
            </Box>
            <Divider
              orientation="horizontal"
              sx={{ my: 2 }}
            />
            <Box>
              <Stack>
                <FormControlLabel
                  control={
                    <Switch
                      name="acceptsMarketing"
                      checked={user?.acceptsMarketing}
                      onChange={handleMarketing}
                    />
                  }
                  label={
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p">
                      {t('users.acceptCommercialAgreement')}
                    </Typography>
                  }
                />
              </Stack>
            </Box>
          </TabPanel>
          <TabPanel value="3">
            <Stack spacing={2}>
              <Container>
                <Typography
                  variant="h3"
                  py={1}
                  sx={{ marginBottom: '15px' }}>
                  {t('common.organizations')}
                </Typography>
                <OrganizationsTable
                  organizations={user?.organizations || []}
                  handleLeaveOrganization={handleLeaveOrganization}
                />
              </Container>
              <Container>
                <Typography
                  variant="h3"
                  py={1}
                  sx={{ marginBottom: '15px' }}>
                  {t('common.invites')}
                </Typography>
                <InvitesTable
                  invites={invites?.data || []}
                  onInviteAccepted={onInviteAccepted}
                />
              </Container>
            </Stack>
          </TabPanel>
        </TabContext>
        <Divider
          orientation="horizontal"
          sx={{ my: 5 }}
        />
        <Box sx={{ marginLeft: '20px' }}>
          <Button
            id="user-profile-button-save"
            variant="contained"
            endIcon={<SaveIcon />}
            disabled={!validation.isValid}
            onClick={handleSave}>
            {t('common.save')}
          </Button>
        </Box>
      </Box>
      <UpdatePassword
        isOpen={updatePasswordIsOpen}
        handleClose={handleCloseUpdatePassword}
      />
    </Container>
  )
}

export default UsersProfile
