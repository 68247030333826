import {
  ChevronLeftRounded as ChevronLeftRoundedIcon,
  ChevronRightRounded as ChevronRightRoundedIcon,
  SaveRounded as SaveRoundedIcon
} from '@mui/icons-material'
import { Button, Container, DialogTitle, Stack, Step, StepLabel, Stepper } from '@mui/material'
import { t } from 'i18next'
import { useEffect, useState } from 'react'

import { useGlobalContext } from '../../Shared/Contexts/GlobalContext.jsx'
import ZDialog from '../../Shared/Dialogs/ZDialog.jsx'
import { useDevices } from '../Contexts/DevicesContext.jsx'
import BrandModelSearch from '../Form/BrandModelSearch.jsx'
import Info from '../Form/Info.jsx'
import Network from '../Form/Network.jsx'
import Position from '../Form/Position.jsx'
import ProvisioningSetup from '../Form/ProvisioningSetup.jsx'
import Publication from '../Form/Publication.jsx'

const AddDevice = ({ isOpen, onClose }) => {
  const { useFetchProvisioningSetup, useCreateDevice } = useDevices()
  const { openErrorAlert, openSuccessAlert } = useGlobalContext()

  const [isValid, setIsValid] = useState(false)
  const [currentStep, setCurrentStep] = useState(0)

  const [deviceInfo, setDeviceInfo] = useState(null)
  const [modelInfo, setModelInfo] = useState(null)
  const [positionInfo, setPositionInfo] = useState(null)
  const [publicationInfo, setPublicationInfo] = useState(null)
  const [networkInfo, setNetworkInfo] = useState(null)
  const [provisioningInfo, setProvisioningInfo] = useState({})
  const [provisioningSetup, setProvisioningSetup] = useState()

  const handleModelChange = (model) => {
    setModelInfo(model)
    setNetworkInfo(null)
    setProvisioningInfo(null)
  }

  const handleOnProvisioningSetupChange = (provisioningSetup) => setProvisioningInfo(provisioningSetup)

  const handleInfoChange = (info) => setDeviceInfo(info)

  const handleOnChangePosition = (position) => setPositionInfo(position)

  const handleOnPublicationChange = (info) => setPublicationInfo(info)

  const handleOnChangeNetwork = (info) => setNetworkInfo(info)

  const handleNextStep = () => setCurrentStep(currentStep + 1)

  const handlePreviousStep = () => setCurrentStep(currentStep - 1)

  const showNetworkStep = () => ['lorawan', 'wmbus', 'nbiot', 'mqtt'].includes(modelInfo?.sourceType)

  const showParsingStep = () => !!provisioningSetup && provisioningSetup?.fields?.length

  let lastStepIndex = 2

  const showCurrentStep = () => {
    const activeSteps = [
      <BrandModelSearch
        key={0}
        setIsValid={setIsValid}
        onChange={handleModelChange}
      />,
      <Info
        key={1}
        setIsValid={setIsValid}
        onChange={handleInfoChange}
      />,
      <Position
        key={2}
        setIsValid={setIsValid}
        onChange={handleOnChangePosition}
      />
    ]

    if (showNetworkStep())
      activeSteps.push(
        <Network
          model={modelInfo}
          key={activeSteps.length}
          setIsValid={setIsValid}
          onChange={handleOnChangeNetwork}
        />
      )

    if (showParsingStep()) {
      activeSteps.push(
        <ProvisioningSetup
          key={activeSteps.length}
          setIsValid={setIsValid}
          onChange={handleOnProvisioningSetupChange}
          provisioningSetup={provisioningSetup}
        />
      )
    }

    activeSteps.push(
      <Publication
        key={activeSteps.length}
        onChange={handleOnPublicationChange}
      />
    )

    lastStepIndex = activeSteps.length - 1

    return activeSteps[currentStep]
  }

  const handleClose = ({ success }) => {
    setCurrentStep(0)
    onClose({ success })
  }

  const createFields = {
    lorawan: (networkInfo) => ({
      networkServerId: networkInfo.networkServer,
      device: {
        userId: networkInfo.userId,
        appId: networkInfo.appId,
        devEui: networkInfo.devEui?.toLowerCase(),
        appKey: networkInfo.appKey?.toLowerCase(),
        nwkSKey: networkInfo.nwkSKey?.toLowerCase(),
        appSKey: networkInfo.appSKey?.toLowerCase(),
        devaddr: networkInfo.devaddr?.toLowerCase(),
        joinEui: networkInfo.joinEui?.toLowerCase(),
        type: networkInfo.networkType === 'OTAA' ? 128 : 0,
        profile: modelInfo.netServerProfileId,
        info: {
          model: modelInfo.name,
          firmware: '',
          position: {
            altitude: null
          }
        }
      }
    }),
    wmbus: (networkInfo) => ({ wmbusId: networkInfo.wmbusId }),
    nbiot: (networkInfo) => ({ imsi: networkInfo.imsi }),
    mqtt: (networkInfo) => ({ mqttId: networkInfo.mqttId })
  }
  const createSourceDataFields = (networkInfo, sourceType) => (createFields[sourceType] ? createFields[sourceType](networkInfo) : {})

  const handleSave = () => {
    const sourceType = modelInfo.sourceType

    const sourceData = ['lorawan', 'wmbus', 'nbiot', 'mqtt'].includes(sourceType) ? createSourceDataFields(networkInfo, sourceType) : {}

    const payload = {
      device: {
        organizationKey: deviceInfo.organization.key,
        name: deviceInfo.name,
        description: deviceInfo.description,
        modelId: modelInfo.id,
        position: {
          latitude: positionInfo?.position?.latitude || null,
          longitude: positionInfo?.position?.longitude || null
        },
        serial: deviceInfo.serial,
        public: publicationInfo ? publicationInfo.isPublic : false,
        hidden: false, //2024-03-05 per ora lascerei così se entro 3 mesi nessuno chiede di ripristinarlo lo eliminiamo anche lato BE
        tags: deviceInfo.tags,
        odlCode: publicationInfo?.odlCode || null,
        locationCode: publicationInfo?.locationCode || null
      },
      sourceData,
      parsingData: provisioningInfo ? provisioningInfo : {}
    }

    useCreateDevice(payload)
      .then(() => {
        openSuccessAlert()
        handleClose({ success: true })
      })
      .catch((err) => openErrorAlert({ keys: err.data.keys }))
  }

  useEffect(() => {
    useFetchProvisioningSetup({
      brandEncoded: modelInfo?.brandNameEncoded,
      modelEncoded: modelInfo?.nameEncoded
    }).then((res) => setProvisioningSetup(res.data))
  }, [modelInfo])

  return (
    <ZDialog
      id="add-device"
      isOpen={isOpen}
      title={t('devices.add')}
      customTitle={
        <DialogTitle marginBottom={5}>
          <Stepper
            activeStep={currentStep}
            alternativeLabel>
            <Step key="brandModelSearch">
              <StepLabel>{t('devices.selectModel')}</StepLabel>
            </Step>
            <Step key="deviceData">
              <StepLabel>{t('devices.addInfo')}</StepLabel>
            </Step>
            <Step key="devicePosition">
              <StepLabel>{t('devices.addPosition')}</StepLabel>
            </Step>
            {showNetworkStep() && (
              <Step key="networkData">
                <StepLabel>{t('devices.addNetwork')}</StepLabel>
              </Step>
            )}
            {showParsingStep() && (
              <Step key="parsingData">
                <StepLabel>{t('devices.addParsing')}</StepLabel>
              </Step>
            )}
            <Step key="extraInfo">
              <StepLabel>{t('devices.addPublication')}</StepLabel>
            </Step>
          </Stepper>
        </DialogTitle>
      }
      content={
        <Container
          sx={{ mt: 4 }}
          maxWidth="md">
          {showCurrentStep()}
        </Container>
      }
      actions={
        <Stack
          direction={'row'}
          justifyContent={'space-between'}
          alignItems={'center'}
          justifyItems={'center'}>
          <Button
            onClick={handlePreviousStep}
            disabled={currentStep === 0}
            startIcon={<ChevronLeftRoundedIcon />}>
            {t('common.back')}
          </Button>

          {lastStepIndex > currentStep ? (
            <Button
              id="add-device-dialog-button-next"
              onClick={handleNextStep}
              disabled={!isValid}
              endIcon={<ChevronRightRoundedIcon />}>
              {t('common.next')}
            </Button>
          ) : (
            <Button
              id="add-device-dialog-button-save"
              variant="contained"
              onClick={handleSave}
              disabled={!isValid}
              endIcon={<SaveRoundedIcon />}>
              {t('common.save')}
            </Button>
          )}
        </Stack>
      }
      onClose={handleClose}
    />
  )
}

export default AddDevice
