import CommunicationRuleForm from './CommunicationRuleForm'
import DeltaRuleForm from './DeltaRuleForm'
import MeasureTriggerRuleForm from './MeasureTriggerRuleForm'
import ScheduledTriggerRuleForm from './ScheduledTriggerRuleForm'
import ThresholdRuleForm from './ThresholdRuleForm'
import TriggerRuleForm from './TriggerRuleForm'

const component = {
  'measure-communication': ({ isEdit, selectedOrganizationKey, validation, trigger, onChange, readonly }) => (
    <CommunicationRuleForm
      isEdit={isEdit}
      onChange={onChange}
      selectedOrganizationKey={selectedOrganizationKey}
      validation={validation}
      trigger={trigger}
      readonly={readonly}
    />
  ),

  communication: ({ isEdit, selectedOrganizationKey, validation, trigger, onChange, readonly }) => (
    <CommunicationRuleForm
      isEdit={isEdit}
      onChange={onChange}
      selectedOrganizationKey={selectedOrganizationKey}
      validation={validation}
      trigger={trigger}
      readonly={readonly}
    />
  ),

  threshold: ({ isEdit, selectedOrganizationKey, validation, trigger, onChange, readonly }) => (
    <ThresholdRuleForm
      readonly={readonly}
      isEdit={isEdit}
      onChange={onChange}
      selectedOrganizationKey={selectedOrganizationKey}
      validation={validation}
      trigger={trigger}
    />
  ),
  delta: ({ isEdit, selectedOrganizationKey, validation, trigger, onChange, readonly }) => (
    <DeltaRuleForm
      readonly={readonly}
      isEdit={isEdit}
      onChange={onChange}
      selectedOrganizationKey={selectedOrganizationKey}
      validation={validation}
      trigger={trigger}
    />
  ),

  trigger: ({ isEdit, selectedOrganizationKey, validation, trigger, onChange, readonly }) => (
    <TriggerRuleForm
      isEdit={isEdit}
      readonly={readonly}
      onChange={onChange}
      selectedOrganizationKey={selectedOrganizationKey}
      validation={validation}
      trigger={trigger}
    />
  ),

  'measure-trigger': ({ isEdit, selectedOrganizationKey, validation, trigger, onChange, readonly }) => (
    <MeasureTriggerRuleForm
      readonly={readonly}
      isEdit={isEdit}
      onChange={onChange}
      selectedOrganizationKey={selectedOrganizationKey}
      validation={validation}
      trigger={trigger}
    />
  ),

  'scheduled-trigger': () => <ScheduledTriggerRuleForm />
}

const TriggerComponent = ({ type, isEdit, selectedOrganizationKey, validation, trigger, onChange, readonly }) => {
  if (type === null) {
    return
  }
  return component[type]({
    isEdit,
    selectedOrganizationKey,
    validation,
    trigger,
    onChange,
    readonly
  })
}

export default TriggerComponent
