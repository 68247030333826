import { Autocomplete, Stack, TextField, Typography } from '@mui/material'
import { t } from 'i18next'
import Joi from 'joi'
import { useEffect, useState } from 'react'

import useFieldValidation from '../../../helpers/fieldValidation.js'
import { useDevices } from '../Contexts/DevicesContext.jsx'
import DeviceSummary from './DeviceSummary.jsx'

const schema = Joi.object({
  brand: Joi.object().empty(null).required(),
  model: Joi.object().empty(null).required()
})

const BrandModelSearch = ({ onChange, setIsValid }) => {
  const { useFetchBrands, useFetchModels } = useDevices()

  const [model, setModel] = useState(null)
  const [brand, setBrand] = useState(null)

  const [validation, setValidation] = useState({ isValid: false })
  const [brands, setBrands] = useState([])
  const [isBrandsLoading, setIsBrandsLoading] = useState(false)
  const [models, setModels] = useState([])
  const [isModelsLoading, setIsModelsLoading] = useState(false)

  useEffect(() => {
    setIsBrandsLoading(true)

    useFetchBrands()
      .then((res) => {
        setBrands(res.data)
      })
      .finally(() => {
        setIsBrandsLoading(false)
      })
  }, [])

  const onChangeBrand = (_, value) => {
    setBrand(value)
    setModel(null)
    if (value) {
      setIsModelsLoading(true)
      useFetchModels(value.id)
        .then((res) => {
          setModels(res.data)
        })
        .finally(() => {
          setIsModelsLoading(false)
        })
    }
  }

  const onChangeModel = (_, value) => {
    setModel(value)
  }

  useEffect(() => {
    const newValidation = useFieldValidation({
      brand,
      model
    }, schema)
    setValidation(newValidation)
    setIsValid(newValidation.isValid)

    onChange(model)
  }, [model])

  return (
    <Stack
      direction="column"
      spacing={5}
    >
      <Typography
        variant="h2"
        py={1}
      >
        {t('devices.selectModel')}
      </Typography>

      <Stack
        direction="row"
        spacing={2}
        justifyContent="space-between"
      >
        <Autocomplete
          id="chooseModel-Brand"
          name="brand"
          fullWidth
          loading={isBrandsLoading}
          options={brands}
          getOptionLabel={(option) => option.name}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          value={brand}
          onChange={onChangeBrand}
          renderInput={(params) => (
            <TextField
              {...params}
              label={t('devices.chooseBrand')}
              variant="standard"
              error={validation?.messages?.brandId?.length > 0}
              helperText={validation?.messages?.brandId?.join(', ')}
            />
          )}
        />

        <Autocomplete
          id="chooseModel-Model"
          name="model"
          fullWidth
          disabled={!brand}
          loading={isModelsLoading}
          options={models}
          getOptionLabel={(option) => option.name}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          filterOptions={(x) => x}
          value={model}
          onChange={onChangeModel}
          renderInput={(params) => (
            <TextField
              {...params}
              label={t('devices.chooseModel')}
              variant="standard"
              error={validation?.messages?.modelId?.length > 0}
              helperText={validation?.messages?.modelId?.join(', ')}
            />
          )}
        />
      </Stack>

      {brand && model && (
        <DeviceSummary
          model={model?.brand?.name}
          brand={model?.model?.name}
          type={model?.model?.typename}
          sourceType={model?.model?.sourceType}
          measurements={model?.model?.quantities}
        />
      )}
    </Stack>
  )
}

export default BrandModelSearch
