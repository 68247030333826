import { Stack } from '@mui/material'
import { t } from 'i18next'
import { useEffect, useState } from 'react'

import Filter from '../../Layout/Dialogs/Filter.jsx'
import Count from '../../Shared/Components/Count.jsx'
import { useAuth } from '../../Shared/Contexts/AuthContext.jsx'
import { AreaFilter, OrganizationsFilter } from '../../Shared/Filters/Fields/index.js'
import FilterOthers from './OthersFilter.jsx'

const DevicesFilters = ({ filters = {}, itemsCount, onChange }) => {
  const {
    currentUser: { isSuperAdmin, permissions, currentOrganizationKey }
  } = useAuth()

  //TODO: 05-12-2024 spostare questo raginamento in auth
  const orgPermissions = currentOrganizationKey && permissions ? permissions[currentOrganizationKey] || [] : []

  const [selectedArea, setSelectedArea] = useState(false)
  const [selectedOrganizations, setSelectedOrganizations] = useState(false)
  const [selectedOther, setSelectedOther] = useState(false)

  const handleAreaFilterChanged = (data) => {
    onChange({
      ...filters,
      ...data
    })
  }

  const handleOrganizationsFilterChanged = (data) => {
    setSelectedOrganizations(true)
    onChange({
      ...filters,
      ...data
    })
  }

  const handleOtherFiltersChanged = (data) => {
    setSelectedOther(true)
    onChange({
      ...filters,
      ...data
    })
  }

  const handleOnDeleteArea = () => {
    setSelectedArea(false)
    onChange({
      ...filters,
      nearby: null
    })
  }

  const handleOnDeleteOrganizations = () => {
    setSelectedOrganizations(false)
    onChange({
      ...filters,
      organizationKeys: [],
      searchInSubOrganizations: false
    })
  }

  const handleOnDeleteOther = () => {
    setSelectedOther(false)
    onChange({
      ...filters,
      modelIds: [],
      showPublic: null,
      showOffline: null,
      showHidden: null,
      brandIds: [],
      tags: [],
      tagSearchMode: null
    })
  }

  useEffect(() => {
    const { brandIds, modelIds, nearby, organizationKeys, showHidden, showOffline, showPublic, tagSearchMode, tags } = filters

    setSelectedArea(!!nearby)

    setSelectedOrganizations(organizationKeys?.length)

    setSelectedOther(
      modelIds?.length > 0 ||
      showOffline !== null ||
      showHidden !== null ||
      showPublic !== null ||
      brandIds?.length > 0 ||
      tags?.length > 0 ||
      tagSearchMode !== null
    )
  }, [filters])

  const filterUnits = [
    {
      label: t('filters.devices.area.label'),
      selected: selectedArea,
      onDelete: handleOnDeleteArea,
      content: (
        <AreaFilter
          filters={filters}
          onChange={handleAreaFilterChanged}
        />
      ),
      count: <Count itemsCount={itemsCount} />
    },
    {
      label: t('filters.devices.organizations.label'),
      selected: selectedOrganizations,
      onDelete: handleOnDeleteOrganizations,
      hidden: !orgPermissions.includes('organizations_r') && !isSuperAdmin,
      content: (
        <OrganizationsFilter
          filters={filters}
          onChange={handleOrganizationsFilterChanged}
        />
      ),
      count: <Count itemsCount={itemsCount} />
    },
    {
      label: t('common.filters.other'),
      selected: selectedOther,
      onDelete: handleOnDeleteOther,
      content: (
        <FilterOthers
          filters={filters}
          onChange={handleOtherFiltersChanged}
        />
      ),
      count: <Count itemsCount={itemsCount} />
    }
  ]

  return (
    <>
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={2}>
        {[...filterUnits].map((e, i) => (
          <Filter
            key={`device-filter-${i}`}
            onClick={e.onClick}
            onDelete={e.onDelete}
            selected={e.selected}
            title={e.label}
            hidden={e.hidden || false}
            content={e.content}
            count={e.count}
          />
        ))}
      </Stack>
    </>
  )
}

export default DevicesFilters
