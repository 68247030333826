
import { Navigate, Outlet } from 'react-router-dom'

import { useAuth } from '../../Shared/Contexts/AuthContext'

const ProtectedRoutes = () => {
  const { authState } = useAuth()

  return authState.isAuthenticated ? <Outlet /> : <Navigate to="/" />
}

export default ProtectedRoutes
