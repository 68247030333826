import { RefreshRounded as RefreshRoundedIcon } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import { useState } from 'react'

import ChangeOrganization from '../../Organizations/Dialogs/ChangeOrganization'

const ChangeOrganizationToggle = () => {
  const [isChangeOrganizationDialogOpen, setIsChangeOrganizationDialogOpen] =
    useState(false)

  const handleClick = () => {
    setIsChangeOrganizationDialogOpen(true)
  }

  return (
    <>
      <IconButton
        id="change-organization-toggle"
        aria-label="change organization"
        color="primary"
        onClick={handleClick}
      >
        <RefreshRoundedIcon fontSize="small" />
      </IconButton>

      <ChangeOrganization
        isOpen={isChangeOrganizationDialogOpen}
        setIsOpen={setIsChangeOrganizationDialogOpen}
      />
    </>
  )
}

export default ChangeOrganizationToggle
