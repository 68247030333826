import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import { t } from 'i18next'

const ConfirmDialog = ({
  title,
  message,
  isOpen,
  onClose,
  onConfirm
}) => {
  return (
    <Dialog
      maxWidth={'sm'}
      scroll="body"
      open={isOpen}
      onClose={onClose}
      sx={{ borderRadius: 100 }}
    >
      <DialogTitle
        id="confirm-dialog-title"
        variant="h4"
        display="flex"
        justifyContent="center"
      >
        {title}
      </DialogTitle>

      <DialogContent
        variant="h5"
        display="flex"
        justifyContent="center"
      >
        {message}
      </DialogContent>

      <DialogActions
        sx={{
          display: 'flex',
          justifyContent: 'space-evenly',
          alignItems: 'center'
        }}
      >
        <Button
          id="confirm-dialog-cancel"
          onClick={onClose}
          variant="standard"
        >
          {t('common.cancel')}
        </Button>
        <Button
          id="confirm-dialog-confirm"
          variant="contained"
          color="error"
          onClick={onConfirm}
        >
          {t('common.delete')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmDialog
