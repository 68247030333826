import { Autocomplete, TextField, Typography } from '@mui/material'
import { t } from 'i18next'
import { useEffect, useState } from 'react'

import { useValidationEffect } from '../../../../helpers/hooks'
import { useAutomations } from '../../Contexts/AutomationsContext'
import EnumValuesSelector from './EnumValuesSelector'
import BoolValuesSelector from './ExclusiveNumericValueSelector'
import ModelsSelector from './ModelsSelector'
import ThresholdTriggerSelector from './ThresholdTriggerSelector'

const ThresholdRuleForm = ({ trigger, isEdit, onChange, selectedOrganizationKey, validation, readonly }) => {
  const sortFn = (a, b) => {
    const label1 = a.label.toLowerCase()
    const label2 = b.label.toLowerCase()
    if (label1 < label2) return -1
    if (label1 > label2) return 1
    return 0
  }
  const { useGetQuantities } = useAutomations()

  const {
    quantityId = trigger.quantity?.id || trigger.quantityId,
    device,
    thresholdsCounter = 1,
    selectedModels = trigger.models,
    applyToChildOrganizations,
    type,
    trigger: triggerType,
    organizationKey
  } = trigger

  const [quantities, setQuantities] = useState([])
  const [selectedQuantity, setSelectedQuantity] = useState()
  const [selectedSubQuantity, setSelectedSubQuantity] = useState()

  const getSubQuantities = () =>
    device && selectedQuantity
      ? device.quantities
          .filter((q) => q.measureId === selectedQuantity.id)
          .map((q) => ({
            id: q.measureName,
            label: q.measureName
          }))
      : []

  const getSelectedSubQuantity = () => {
    const subQuantities = getSubQuantities()
    return subQuantities.length === 1 ? subQuantities[0] : ''
  }

  useEffect(() => {
    useGetQuantities(selectedOrganizationKey).then((res) => {
      setQuantities(res.data)
      setSelectedQuantity(res.data.find((q) => q.id === quantityId))
    })
  }, [])

  useEffect(() => {
    const selected = quantities.find((q) => q.id === quantityId)
    setSelectedQuantity(selected)
  }, [quantityId])

  useValidationEffect(() => {
    const subQuantities = getSubQuantities()
    const selectedSubQuantity = subQuantities.length === 1 ? subQuantities[0] : null
    changeSubMeasure(selectedSubQuantity)
  }, [selectedQuantity])

  const changeSubMeasure = (subQuantity) => {
    if (!device) {
      return
    }
    setSelectedSubQuantity(subQuantity)
    const models = subQuantity
      ? [
          {
            id: parseInt(device.modelId, 10),
            measures: [{ name: subQuantity.id }]
          }
        ]
      : []
    onChange({ models })
  }

  const handleChangeMeasuere = (_event, value) => {
    setSelectedSubQuantity(null)
    onChange({
      quantity: value,
      quantityId: value.id
    })
  }

  const handleChangeSubMeasuere = (_event, value) => changeSubMeasure(value)

  const handleThresholdsCounter = ({ target: { value } }) => onChange({ thresholdsCounter: value })

  const handleOnTriggerChange = (data) => onChange(data)

  const handleModelsChanged = (data) => onChange(data)

  const filteredQuantities = device
    ? quantities
        .filter((q) => device.quantities.some((dq) => dq.measureId === q.id))
        .map((fq) => ({
          ...fq,
          label: t(fq.label)
        }))
        .sort(sortFn)
    : quantities
        .map((fq) => ({
          ...fq,
          label: t(fq.label)
        }))
        .sort(sortFn)

  const subQuantities = getSubQuantities()

  return (
    <>
      <Autocomplete
        sx={{
          marginTop: '30px'
        }}
        id="measureSelector"
        name="measureSelector"
        options={filteredQuantities}
        value={
          selectedQuantity
            ? {
                ...selectedQuantity,
                label: t(selectedQuantity.label)
              }
            : ''
        }
        getOptionLabel={(option) => option.label || ''}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        onChange={handleChangeMeasuere}
        renderInput={(params) => (
          <TextField
            name="quantity"
            {...params}
            label={t('triggers.selectMeasure')}
            variant="standard"
            error={validation?.messages?.quantity?.length > 0}
            helperText={validation?.messages?.quantity?.join(', ')}
          />
        )}
        disabled={isEdit}
      />
      {subQuantities.length > 0 && (
        <Autocomplete
          sx={{
            marginTop: '30px'
          }}
          py={5}
          id="subMeasureSelector"
          name="subMeasureSelector"
          options={subQuantities}
          getOptionLabel={(option) => t(option.label)}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          onChange={handleChangeSubMeasuere}
          defaultValue={selectedSubQuantity || getSelectedSubQuantity()}
          value={selectedSubQuantity || getSelectedSubQuantity()}
          renderInput={(params) => (
            <TextField
              name="quantity"
              {...params}
              label={t('triggers.selectSubMeasure')}
              variant="standard"
            />
          )}
          disabled={isEdit}
        />
      )}

      {selectedQuantity && (
        <>
          {!device && (
            <ModelsSelector
              applyToChildOrganizations={applyToChildOrganizations}
              type={type}
              selectedModels={selectedModels || []}
              selectedQuantity={selectedQuantity}
              isEdit={isEdit}
              onModelsChanged={handleModelsChanged}
              selectedOrganizationKey={organizationKey || selectedOrganizationKey}
              validation={validation}
              readonly={readonly}
            />
          )}
          {selectedQuantity.type.name === 'numeric' && (
            <ThresholdTriggerSelector
              quantity={selectedQuantity}
              trigger={triggerType}
              onTriggerChange={handleOnTriggerChange}
              isEdit={isEdit}
            />
          )}
          {selectedQuantity.type.name === 'enum' && (
            <EnumValuesSelector
              quantity={selectedQuantity}
              trigger={triggerType}
              onTriggerChange={handleOnTriggerChange}
              isEdit={isEdit}
            />
          )}
          {selectedQuantity.type.name === 'exclusiveNumeric' && (
            <BoolValuesSelector
              quantity={selectedQuantity}
              trigger={triggerType}
              onTriggerChange={handleOnTriggerChange}
              isEdit={isEdit}
              readonly={readonly}
            />
          )}
        </>
      )}

      {selectedQuantity && (
        <>
          <Typography
            py={5}
            variant="h3">
            {t('triggers.alarmTolerance')}
          </Typography>
          <TextField
            id="thresholdsCounter"
            value={thresholdsCounter}
            onChange={handleThresholdsCounter}
            label={t('triggers.numberOfMeasuresAboveThresholdBeforeAlarm')}
            disabled={readonly}
          />
        </>
      )}
    </>
  )
}

export default ThresholdRuleForm
