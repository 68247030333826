import { Box, Stack } from '@mui/material'
import { useEffect, useState } from 'react'

import useFieldValidation from '../../../helpers/fieldValidation.js'
import useProvisioningSetupValidation from '../../../helpers/provisioningSetupValidation.js'
import DynamicInput from './DynamicInput.jsx'

const DynamicForm = ({ fields, fieldsPayload, onChange = () => { } }) => {
  const [validation, setValidation] = useState({ isValid: false })
  const [dynamicFormState, setDynamicFormState] = useState(fieldsPayload)

  useEffect(() => {
    const validationSchema = useProvisioningSetupValidation(fields)
    const schemaValidation = useFieldValidation(
      dynamicFormState,
      validationSchema
    )
    setValidation(schemaValidation)
    onChange(dynamicFormState, schemaValidation.isValid)
  }, [dynamicFormState])

  const handleOnDynamicInputChange = ({ internalName, value }) => setDynamicFormState({
    ...fieldsPayload,
    [internalName]: value
  })

  return (
    <Box sx={{
      marginTop: '10px',
      width: '100%'
    }}>
      {(fields || [])?.map((field, index) => (
        <Stack
          sx={{ width: '100%' }}
          direction="row"
          key={`${field.internalName}-${field.type}-${index}`}
        >
          <DynamicInput
            field={field}
            value={fieldsPayload ? fieldsPayload[field.internalName] : null}
            onChange={handleOnDynamicInputChange}
            validation={validation}
          />
        </Stack>
      ))}
    </Box>
  )
}

export default DynamicForm
