import { AddCircleOutlineRounded as AddCircleOutlineRoundedIcon } from '@mui/icons-material'
import {
  Autocomplete,
  Button,
  Divider,
  FormControlLabel,
  Input,
  Stack,
  Switch,
  TextField,
  Typography
} from '@mui/material'
import { t } from 'i18next'
import { useEffect, useState } from 'react'

import { useValidationEffect } from '../../../../helpers/hooks'
import Can from '../../../Layout/Can/Can'
import Show from '../../../Layout/Can/Show'
import InlineMessage from '../../../Shared/Components/InlineMessage'
import { useAuth } from '../../../Shared/Contexts/AuthContext'
import ActionCard from '../../Card/ActionCard'
import TriggerCard from '../../Card/TriggerCard'
import ActionSelector from '../../Dialogs/ActionSelector'
import TriggerSelector from '../../Dialogs/TriggerSelector'

const RuleForm = ({ onChange, rule, isEdit, readonly = false }) => {
  const {
    currentUser: { isSuperAdmin, selfOrganizations, currentOrganizationKey }
  } = useAuth()

  const [isTriggerSelectorDialogOpen, setIsTriggerSelectorDialogOpen] =
    useState(false)
  const [isActionSelectorDialogOpen, setActionSelectorDialogOpen] =
    useState(false)

  const [organization, setOrganization] = useState(() => ({
    key: rule?.organizationKey || currentOrganizationKey
  }))

  const [currentRule, setCurrentRule] = useState(() => rule)

  useEffect(() => {
    setCurrentRule(rule)
    setOrganization({ key: rule?.organizationKey })
  }, [rule])

  useValidationEffect(() => {
    onChange(currentRule)
  }, [currentRule])

  const handleChangeOrganization = (_, organization) => {
    setOrganization(organization)
    setCurrentRule({
      ...currentRule,
      organizationKey: organization.key
    })
  }

  const handleAddTrigger = () => setIsTriggerSelectorDialogOpen(true)

  const handleAddAction = () => setActionSelectorDialogOpen(true)

  const handleTriggerSelectorClose = () => setIsTriggerSelectorDialogOpen(false)

  const handleActionSelectorClose = () => setActionSelectorDialogOpen(false)

  const handleActionSelectorChange = (action) => {
    const actions = [...currentRule.actions, action]
    setCurrentRule({
      ...currentRule,
      actions
    })
  }
  const handleTriggerSelectorChange = (trigger) =>
    setCurrentRule({
      ...currentRule,
      actions: [],
      trigger
    })

  const handleNameChanged = (event) =>
    setCurrentRule({
      ...currentRule,
      name: event.target.value
    })

  const handleEnabledChanged = (event) =>
    setCurrentRule({
      ...currentRule,
      enabled: event.target.checked
    })

  const handleActionRemoveByIndex = (elementIndex) => {
    const newActions = currentRule.actions.filter(
      (_, index) => index !== elementIndex
    )
    setCurrentRule({
      ...currentRule,
      actions: newActions
    })
  }

  const handleTriggerRemove = () =>
    setCurrentRule({
      ...currentRule,
      trigger: null
    })

  return (
    <>
      <Stack
        direction="column"
        alignContent={'center'}
        spacing={5}
      >
        <Can
          action={'devices_u'}
          expression={() => isSuperAdmin}
        >
          <Autocomplete
            disabled={readonly}
            id="rule-form-organizations-autocomplete"
            name="organizationKey"
            disableClearable
            fullWidth
            options={selfOrganizations}
            value={
              selfOrganizations.find((x) => x.key === organization?.key) || {
                name: '',
                key: ''
              }
            }
            getOptionLabel={(option) => option.name}
            isOptionEqualToValue={(option, value) => option.key === value.key}
            onChange={handleChangeOrganization}
            renderInput={(params) => (
              <TextField
                name="organizationKey"
                variant="standard"
                {...params}
                label={t('devices.chooseOrganization')}
                required={true}
              />
            )}
          />
        </Can>

        <Input
          fullWidth
          id="input-name"
          variant="standard"
          placeholder={t('rules.inputNamePlaceholder')}
          onChange={handleNameChanged}
          value={currentRule.name}
          autoFocus
          disabled={readonly}
        />
        <Show when={() => isEdit}>
          <Typography marginTop={2}>
            <strong>{t('rules.ruleState')}</strong>
          </Typography>
          <FormControlLabel
            sx={{ marginLeft: '5px' }}
            control={
              <Switch
                id="automations-form-rule-switch-enable"
                checked={currentRule.enabled}
                size="small"
                onChange={handleEnabledChanged}
                disabled={readonly}
              />
            }
            labelPlacement="end"
            label={
              currentRule.enabled ? t('common.active') : t('common.notActive')
            }
          />
        </Show>

        <Typography variant="h2">{t('common.triggers')}</Typography>

        <Show when={() => !!currentRule.trigger}>
          <TriggerCard
            isRuleCard
            trigger={currentRule.trigger}
            onDelete={handleTriggerRemove}
          />
        </Show>

        <Show when={() => !readonly}>
          <Button
            id="rule-form-add-trigger"
            onClick={handleAddTrigger}
            variant="contained"
            component="label"
            sx={{
              height: '38px',
              width: '200px'
            }}
          >
            {currentRule.trigger ? t('triggers.change') : t('triggers.select')}
          </Button>
        </Show>

        <Divider />

        <Typography variant="h2">{t('common.actions')}</Typography>

        <Stack direction="column">
          <Show
            when={() =>
              currentRule.actions.some(
                (a) =>
                  a.organizationKey !== currentRule.trigger?.organizationKey
              )
            }
          >
            <InlineMessage
              message={t('automations.errors.actionsAndTriggersNotInSameOrg')}
            ></InlineMessage>
          </Show>
          <Show
            when={() =>
              currentRule.actions
                .map((a) => a.organizationKey)
                .reduce(
                  (_acc, curr) =>
                    currentRule.actions.some((a) => a.organizationKey !== curr),
                  false
                )
            }
          >
            <InlineMessage
              message={t('automations.errors.actionsNotInSameOrg')}
            ></InlineMessage>
          </Show>
          <Show
            when={() =>
              currentRule.trigger?.type === 'scheduled-trigger' &&
              currentRule.actions.filter((a) => a.type !== 'downlink').length >
              0
            }
          >
            <InlineMessage
              message={t('automations.errors.downlinkInScheduledTrigger')}
            ></InlineMessage>
          </Show>
          {currentRule.actions.map((action, index) => (
            <ActionCard
              index={index}
              key={`action-${index}`}
              action={action}
              onDelete={() => handleActionRemoveByIndex(index)}
              isRuleCard={true}
              readonly={readonly}
            />
          ))}
        </Stack>

        <Show when={() => !readonly}>
          <Button
            id="rule-form-add-action"
            onClick={handleAddAction}
            variant="contained"
            component="label"
            sx={{
              height: '38px',
              width: '200px'
            }}
            startIcon={<AddCircleOutlineRoundedIcon />}
          >
            {t('actions.add')}
          </Button>
        </Show>
      </Stack>

      <Show when={() => isTriggerSelectorDialogOpen}>
        <TriggerSelector
          organization={organization}
          isOpen={isTriggerSelectorDialogOpen}
          onClose={handleTriggerSelectorClose}
          onChange={handleTriggerSelectorChange}
        />
      </Show>

      {isActionSelectorDialogOpen && (
        <ActionSelector
          organization={organization}
          rule={currentRule}
          isOpen={isActionSelectorDialogOpen}
          onClose={handleActionSelectorClose}
          onChange={handleActionSelectorChange}
        />
      )}
    </>
  )
}

export default RuleForm
