import { createContext, useContext } from 'react'

import { useAuth } from '../../Shared/Contexts/AuthContext'

const RolesContext = createContext({})

export const useRoles = () => {
  return useContext(RolesContext)
}

export const RolesProvider = ({ children }) => {
  const { useBaseAxiosGet, useBaseAxiosPost } = useAuth()

  const useFetchRolesStandardQuery = async () => {
    return await useBaseAxiosGet({ url: 'roles/standard' })
  }

  const useCreateRoleStandardQuery = async () => {
    return await useBaseAxiosPost({
      url: 'roles/standard',
      payload: {}
    })
  }

  const useFetchRolesSuperQuery = async () => {
    return await useBaseAxiosGet({ url: 'roles/super' })
  }

  const value = {
    useFetchRolesStandardQuery,
    useCreateRoleStandardQuery,
    useFetchRolesSuperQuery
  }

  return <RolesContext.Provider value={value}>{children}</RolesContext.Provider>
}
