import {
  AddCircleOutlineRounded as AddCircleOutlineRoundedIcon,
  DeleteRounded as DeleteRoundedIcon
} from '@mui/icons-material'
import { Box, Button, Checkbox, FormControlLabel, Grid2, Stack, TextField, Typography } from '@mui/material'
import { t } from 'i18next'
import Joi from 'joi'
import { useState } from 'react'

import useFieldValidation from '../../../../helpers/fieldValidation'
import { useValidationEffect } from '../../../../helpers/hooks'

const schema = Joi.object({
  name: Joi.string().empty(null).required(),
  url: Joi.string().empty(null).required()
})
const ConfigureActionWebhook = ({
  webhookConfig,
  actionName,
  onChangeIsValid,
  onChangeWebhookConfig,
  onChangeActionName
}) => {
  const [name, setName] = useState(actionName)
  const [url, setUrl] = useState(null)
  const [sendTriggerInformation, setSendTriggerInformation] = useState(false)
  const [validation, setValidation] = useState({ isValid: false })

  const handleNameChange = (event) => setName(event.target.value)

  const [headers, setHeaders] = useState([])

  const handleAddHeader = () => setHeaders([...headers, {
    key: '',
    value: ''
  }])

  const handleRemoveHeader = (indexToRemove) => {
    const newHeaders = headers.filter((_, index) => index !== indexToRemove)
    const confHeaders = newHeaders.reduce((prev, current) => {
      return {
        ...prev,
        [current.key]: current.value
      }
    }, {})

    onChangeWebhookConfig({
      ...webhookConfig,
      httpHeaders: confHeaders
    })
    setHeaders(newHeaders)
  }

  const handleHttpHeadersKey = (index, event) => {
    headers[index].key = event.target.value

    const confHeaders = headers.reduce((prev, current) => {
      return {
        ...prev,
        [current.key]: current.value
      }
    }, {})

    onChangeWebhookConfig({
      ...webhookConfig,
      httpHeaders: confHeaders
    })
    setHeaders(headers)
  }

  const handleHttpHeadersValue = (index, event) => {
    headers[index].value = event.target.value

    const confHeaders = headers.reduce((prev, current) => {
      return {
        ...prev,
        [current.key]: current.value
      }
    }, {})

    onChangeWebhookConfig({
      ...webhookConfig,
      httpHeaders: confHeaders
    })
    setHeaders(headers)
  }

  const handleChangeCheckbox = (_event, checked) =>
    setSendTriggerInformation(checked)

  const handleUrlAction = (event) => setUrl(event.target.value)

  useValidationEffect(() => {
    const fieldValidation = useFieldValidation({
      name: name,
      url
    }, schema)
    setValidation(fieldValidation)
    onChangeIsValid(fieldValidation.isValid)
    onChangeActionName(name)
    onChangeWebhookConfig({
      ...webhookConfig,
      urlAction: url,
      sendTriggerInformation: sendTriggerInformation
    })
  }, [name, url, sendTriggerInformation])

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid2
        container
        marginBottom={5}
      >
        <Grid2
          size={12}
          paddingY={1}
        >
          <TextField
            id="configure-action-name"
            variant="standard"
            label={t('actions.actionName')}
            fullWidth
            value={actionName}
            onChange={handleNameChange}
            error={validation.messages?.name?.length > 0}
            helperText={validation.messages?.name?.join(', ')}
          />
        </Grid2>

        <Grid2
          size={12}
          paddingY={1}
        >
          <TextField
            id="configure-action-webhook-url"
            variant="standard"
            label={t('actions.actionUrl')}
            value={webhookConfig.urlAction}
            onChange={handleUrlAction}
            fullWidth
            error={validation.messages?.url?.length > 0}
            helperText={validation.messages?.url?.join(', ')}
          />
        </Grid2>

        <Grid2
          size={12}
          paddingY={1}
          display="flex"
        >
          <Typography
            variant="h5"
            fontWeight="bold"
          >
            {t('actions.httpHeaders')}
          </Typography>
          <Button
            id="configure-action-webhook-http-headers"
            sx={{ height: '24px' }}
            onClick={handleAddHeader}
          >
            <AddCircleOutlineRoundedIcon />
          </Button>
        </Grid2>

        <Grid2>
          <FormControlLabel
            control={<Checkbox onChange={handleChangeCheckbox} />}
            label={t('actions.sendTriggerInformation')}
          />
        </Grid2>
        <Grid2>
          <Box>
            <Stack
              direction={'column'}
              spacing={2}
            >
              {headers.map((_, index) => (
                <Stack
                  direction={'row'}
                  key={index}
                  spacing={2}
                >
                  <TextField
                    name="configure-action-webhook-http-header-key"
                    variant="standard"
                    label={t('common.key')}
                    onChange={(event) => handleHttpHeadersKey(index, event)}
                    size="small"
                  ></TextField>
                  <TextField
                    name="configure-action-webhook-http-header-value"
                    variant="standard"
                    label={t('common.value')}
                    onChange={(event) => handleHttpHeadersValue(index, event)}
                    size="small"
                  ></TextField>
                  <Button
                    variant="text"
                    key="trash"
                    onClick={() => handleRemoveHeader(index)}
                  >
                    <DeleteRoundedIcon
                      size={16}
                      color="red"
                    />
                  </Button>
                </Stack>
              ))}
            </Stack>
          </Box>
        </Grid2>
      </Grid2>
    </Box>
  )
}

export default ConfigureActionWebhook
