import { Visibility as VisibilityIcon, VisibilityOff as VisibilityOffIcon } from '@mui/icons-material'
import { FormControl, FormHelperText, IconButton, Input, InputAdornment, InputLabel } from '@mui/material'
import Joi from 'joi'
import { useState } from 'react'

import useFieldValidation from '../../../helpers/fieldValidation.js'
import { useValidationEffect } from '../../../helpers/hooks.js'

const schema = Joi.object({
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .required(),
  password: Joi.string().required()
})

const LoginForm = ({ onChange, onLogin }) => {
  const [showPassword, setShowPassword] = useState(false)
  const [form, setForm] = useState({
    email: '',
    password: ''
  })
  const [validation, setValidation] = useState({ isValid: false })

  useValidationEffect(() => {
    const validatedForm = useFieldValidation(form, schema)
    setValidation(validatedForm)
    onChange(form, validatedForm.isValid)
  }, [form])

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const handleChange = (prop) => (event) => {
    setForm({
      ...form,
      [prop]: event.target.value
    })
  }

  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }

  const handleKeyPress = (ev) => {
    if (ev.key === 'Enter') {
      ev.preventDefault()
      onLogin()
    }
  }

  return (
    <>
      <FormControl
        required
        variant="standard"
        error={validation?.messages?.email?.length > 0}
      >
        <InputLabel htmlFor="email-input-field">email</InputLabel>
        <Input
          id="email-input-field"
          value={form.email}
          onChange={handleChange('email')}
          onKeyDown={handleKeyPress}
        />
        <FormHelperText>
          {validation?.messages?.email?.join(', ')}
        </FormHelperText>
      </FormControl>
      <FormControl
        required
        variant="standard"
        error={validation?.messages?.password?.length > 0}
      >
        <InputLabel htmlFor="password-input-field">password</InputLabel>
        <Input
          id="password-input-field"
          type={showPassword ? 'text' : 'password'}
          value={form.password}
          onChange={handleChange('password')}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
              >
                {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
              </IconButton>
            </InputAdornment>
          }
          onKeyDown={handleKeyPress}
        />
        <FormHelperText>
          {validation?.messages?.password?.join(', ')}
        </FormHelperText>
      </FormControl>
    </>
  )
}

export default LoginForm
