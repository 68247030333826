import { Box, Stack, Switch, Typography } from '@mui/material'
import { t } from 'i18next'
import { useEffect, useState } from 'react'

const UpdatePublication = ({ deviceData, onChange }) => {
  const [updatedPublicationData, setuUpdatedPublicationData] = useState({
    isPublic: deviceData.device.public,
    isVisible: !deviceData.device.hidden
  })

  useEffect(() => {
    onChange(updatedPublicationData)
  }, [updatedPublicationData])

  const handleChangeIsPublic = (event) => {
    setuUpdatedPublicationData({
      ...updatedPublicationData,
      isPublic: event.target.checked
    })
  }

  return (
    <Stack
      direction="column"
      spacing={5}
    >
      <Typography
        variant="h2"
        py={1}
      >
        {t('devices.addPublication')}
      </Typography>

      <Stack
        direction="row"
        spacing={2}
        my={3}
      >
        <Box width="100%">
          <Typography variant="h3">{t('devices.public')}</Typography>
          <Typography variant="body2">{t('devices.publicInfo')}</Typography>
        </Box>
        <Switch
          name="isPublic"
          checked={updatedPublicationData.isPublic}
          onChange={handleChangeIsPublic}
        />
      </Stack>
    </Stack>
  )
}

export default UpdatePublication
