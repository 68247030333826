import { SensorsRounded as SensorsRoundedIcon } from '@mui/icons-material'
import { Stack, Typography } from '@mui/material'
import { t } from 'i18next'

const StatusBadge = ({ isAlarm, isAlive, withMessage = true }) => {
  const choseStatus = (isAlarm) => isAlarm
    ? {
      color: 'error.main',
      msg: t('common.alarmed')
    }
    : {
      color: 'success.light',
      msg: t('common.online')
    }

  const status = isAlive
    ? choseStatus(isAlarm)
    : {
      color: 'gray.700',
      msg: t('common.offline')
    }

  return (
    <Stack
      direction="row"
      alignItems="center"
      spacing={1}
      color={status.color}
    >
      <SensorsRoundedIcon />
      {withMessage && (
        <Typography variant="p">
          <small>{status.msg}</small>
        </Typography>
      )}
    </Stack>
  )
}

export default StatusBadge
