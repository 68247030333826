import { Save as SaveIcon } from '@mui/icons-material'
import { Button } from '@mui/material'
import { t } from 'i18next'
import { useState } from 'react'

import { useAuth } from '../../Shared/Contexts/AuthContext.jsx'
import { useGlobalContext } from '../../Shared/Contexts/GlobalContext.jsx'
import ZDialog from '../../Shared/Dialogs/ZDialog.jsx'
import { useAutomations } from '../Contexts/AutomationsContext.jsx'
import RuleForm from '../Form/Rule/RuleForm.jsx'

const AddRule = ({ isOpen, onClose }) => {
  const { useCreateRule } = useAutomations()
  const { openErrorAlert, openSuccessAlert } = useGlobalContext()
  const {
    currentUser: { currentOrganizationKey }
  } = useAuth()

  const [rule, setRule] = useState({
    trigger: null,
    actions: [],
    enabled: true,
    name: ''
  })

  const handleOnClose = (reload) => {
    setRule({
      trigger: null,
      actions: [],
      enabled: true,
      name: ''
    })
    onClose(reload)
  }

  const handleRuleFormChange = (rule) => setRule(rule)

  const handleSaveRule = async () => {
    useCreateRule({
      organizationKey: rule.organizationKey || currentOrganizationKey,
      name: rule.name,
      triggerId: rule.trigger.id,
      actions: rule.actions.map((x) => ({
        actionId: x.id,
        actionType: x.type
      }))
    })
      .then(() => {
        openSuccessAlert()
        setRule({
          trigger: null,
          actions: [],
          enabled: true,
          name: ''
        })
        onClose(true)
      })
      .catch((err) => openErrorAlert({ keys: err.data.keys }))
  }

  return (
    <ZDialog
      id={'add-rule-dialog'}
      isOpen={isOpen}
      onClose={() => handleOnClose(false)}
      title={t('rules.add')}
      content={
        <RuleForm
          onChange={handleRuleFormChange}
          rule={rule}
          isEdit={false}
        />
      }
      actions={
        <Button
          id="add-rule-dialog-button-save"
          variant="contained"
          endIcon={<SaveIcon />}
          onClick={handleSaveRule}
          disabled={
            rule.actions.length == 0 ||
            !rule.trigger ||
            !rule.name ||
            (rule.trigger?.type === 'scheduled-trigger' && rule.actions.filter((a) => a.type !== 'downlink').length > 0)
          }>
          {t('common.save')}
        </Button>
      }
    />
  )
}

export default AddRule
