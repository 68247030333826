import { Checkbox, FormControlLabel, FormGroup, Stack, Typography } from '@mui/material'
import { t } from 'i18next'
import { useState } from 'react'

const AccessibilityFilter = ({ filters = {}, onChange }) => {
  const { showPublic } = filters

  const [isPublic, setIsPublic] = useState(null)
  const [isPrivate, setIsPrivate] = useState(null)

  const handleChangeIsPublic = (_, value) => {
    setIsPublic(value)
    !value && !isPrivate
      ? onChange({ showPublic: null })
      : onChange({ showPublic: value })
  }

  const handleChangeIsPrivate = (_, value) => {
    setIsPrivate(value)
    !value && !isPublic
      ? onChange({ showPublic: null })
      : onChange({ showPublic: isPublic })
  }

  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
        mt={3}
        mb={3}
      >
        <Typography
          align="left"
          sx={{ fontWeight: 'bold' }}
        >
          {t('filters.devices.other.accessibility.label')}
        </Typography>
        <FormGroup
          aria-label="position"
          row
        >
          <FormControlLabel
            value="public"
            control={
              <Checkbox
                onChange={handleChangeIsPublic}
                checked={showPublic !== null && showPublic}
                disabled={showPublic !== null && !showPublic}
              />
            }
            label={t('filters.devices.other.accessibility.valueTrue')}
            sx={{ width: '100px' }}
          />
          <FormControlLabel
            value="private"
            control={
              <Checkbox
                onChange={handleChangeIsPrivate}
                checked={showPublic !== null && !showPublic}
                disabled={showPublic !== null && showPublic}
              />
            }
            label={t('filters.devices.other.accessibility.valueFalse')}
            sx={{ width: '100px' }}
          />
        </FormGroup>
      </Stack>
    </>
  )
}

export default AccessibilityFilter
