const notNullOrUndefined = (value) => value !== null && value !== undefined

const encodeInBase64Url = (values) => {
  if (!values) {
    return ''
  }
  const jsonString = JSON.stringify(values)
  const encoded = encodeURIComponent(jsonString)

  return btoa(encoded)
}

const putIntoUrl = (value, paramName = 'filters') => window.history.pushState({}, '', `${window.location.pathname}?${paramName}=${value}`)

const readFromUrl = (paramName) => {
  const searchParams = new URLSearchParams(window.location.search)
  return searchParams.get(paramName)
}

const decodeFromBase64Url = (value) => {
  if (!value) {
    return null
  }

  const decoded = decodeURIComponent(atob(value))
  const parsed = JSON.parse(decoded)
  return parsed
}

export { decodeFromBase64Url, encodeInBase64Url, notNullOrUndefined, putIntoUrl, readFromUrl }
