import { t } from 'i18next'
import { useEffect, useState } from 'react'

import { useDevices } from '../../Devices/Contexts/DevicesContext.jsx'
import { useGlobalContext } from '../../Shared/Contexts/GlobalContext.jsx'
import ZDialog from '../../Shared/Dialogs/ZDialog.jsx'
import { useAutomations } from '../Contexts/AutomationsContext.jsx'
import TriggerForm from '../Form/Trigger/TriggerForm.jsx'

const TriggerDetail = ({ triggerId, open, onClose, onEnableEdit, readonly }) => {
  const { useUpdateTrigger, useGetTrigger } = useAutomations()
  const { openErrorAlert, openSuccessAlert } = useGlobalContext()
  const { useFetchDevice } = useDevices()

  const [trigger, setTrigger] = useState()

  const fetchDevice = (trigger, deviceId) => {
    useFetchDevice({ id: deviceId })
      .then(({ data }) => {
        setTrigger({
          ...trigger,
          device: {
            ...data.device,
            quantities: data.quantities
          }
        })
      })
  }

  useEffect(() => {
    useGetTrigger({ id: triggerId }).then(({ data }) => {
      setTrigger(data)
      if (data.deviceId) {
        fetchDevice(data, data.deviceId)
      }
    })
  }, [])

  const handleChange = (trigger) => setTrigger(trigger)

  const handleSaveTrigger = (trigger) => {
    if (trigger.type === 'communication' || trigger.type === 'trigger') {
      onClose()
      return
    }

    const updatePayload = {
      type: trigger.type,
      name: trigger.name,
      isForSuperAdmin: trigger.isForSuperAdmin,
      applyToChildOrganizations: trigger.applyToChildOrganizations,
      models: trigger.models?.map((m) => ({
        id: m.id,
        measures: m.measures?.map((m) => ({ name: m.name }))
      })),
      trigger: trigger.trigger,
      schedulerType: trigger.schedulerType,
      version: trigger.revision.version,
      sendReminder: trigger.sendReminder,
      thresholdsCounter: trigger.thresholdsCounter,
      ...(trigger.schedulerType === 'scheduled' ? { scheduler: trigger.scheduler } : {})
    }
    useUpdateTrigger({
      payload: updatePayload,
      id: triggerId
    })
      .then(() => {
        openSuccessAlert()
        onClose()
      })
      .catch((err) => openErrorAlert({ keys: err.data.keys }))
  }

  return (
    <ZDialog
      id={'trigger-detail-dialog'}
      maxWidth={'md'}
      isOpen={open}
      title={t('triggers.edit')}
      content={
        <>
          {trigger && (
            <TriggerForm
              triggerData={trigger}
              isEdit={true}
              onSave={handleSaveTrigger}
              onChange={handleChange}
              onEnableEdit={onEnableEdit}
              readonly={readonly}
            />
          )}
        </>
      }
      onClose={onClose}
    />
  )
}

export default TriggerDetail
