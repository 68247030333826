import {
  ArrowBackRounded as ArrowBackRoundedIcon,
  ArrowForwardRounded as ArrowForwardRoundedIcon
} from '@mui/icons-material'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid2,
  Step,
  StepLabel,
  Stepper,
  Typography
} from '@mui/material'
import { t } from 'i18next'
import { useState } from 'react'

import CloseDialogButton from '../../Shared/Dialogs/CloseDialogButton.jsx'
import { useDevices } from '../Contexts/DevicesContext.jsx'
import ConfigureDownlink from './ConfigureDownlink.jsx'
import ReviewAction from './ReviewAction.jsx'

const SendAction = ({ device, isOpen, setIsOpen }) => {
  const { useSendDownlinkAction, useEncodeFields } = useDevices()

  const [currentPage, setCurrentPage] = useState(0)
  const [downlinkPayload, setDownlinkPayload] = useState({})
  const [encodedPayload, setEncodedPayload] = useState({})
  const [showErrorMessage, setShowErrorMessage] = useState(false)
  const [{ encodeFieldsError, isEncodeFieldsInError }, setEncodeError] = useState({
    encodeFieldsError: null,
    isEncodeFieldsInError: false
  })

  const handleChangeCurrentPage = async (value) => {
    switch (value) {
      case 0:
        break
      case 1:
        const { data } = await useEncodeFields({
          modelEncoded: device?.modelEncoded,
          brandEncoded: device?.brandEncoded,
          payload: {
            deviceId: device.id,
            saveAs: downlinkPayload.name,
            downlink: {
              actionName: downlinkPayload.downlink.name,
              downlinkAckByUplink: downlinkPayload.downlink.downlinkAckByUplink,
              fields: downlinkPayload.fieldsPayload,
              port: 0,
              payload: ''
            }
          },
          actionName: downlinkPayload.downlink.name
        }).catch((err) => {
          setEncodeError({
            encodeFieldsError: err.data,
            isEncodeFieldsInError: true
          })
        })

        setEncodedPayload(data)
        break
    }
    setCurrentPage(value)
  }

  const handleConfigureDownlinkChange = (value) => setDownlinkPayload(value)

  const handleSteps = () => {
    switch (currentPage) {
      case 0:
        return (
          <ConfigureDownlink
            device={device}
            onChange={handleConfigureDownlinkChange}
            downlinkPayload={downlinkPayload}
          />
        )
      case 1:
        return (
          <ReviewAction
            downlink={downlinkPayload}
            error={isEncodeFieldsInError ? encodeFieldsError : null}
          />
        )
    }
  }

  const handleSaveDownlink = async () => {
    const payloadToSend = {
      payload: {
        downlinkAckByUplink: downlinkPayload.sendUplink || false,
        actionName: downlinkPayload.downlink.name,
        fields: downlinkPayload.fieldsPayload,
        port: encodedPayload.port,
        payload: encodedPayload.payload
      },
      deviceId: device?.id
    }
    useSendDownlinkAction(payloadToSend)
      .then(() => handleClose())
      .catch(() => setShowErrorMessage(true))
  }

  const handleClose = () => {
    setIsOpen(false)
  }

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      fullWidth
      scroll="body">
      <DialogTitle
        id="device-detail-send-action-dialog-title"
        variant="h4"
        display="flex"
        justifyContent="center">
        {t('actions.sendNew')}
      </DialogTitle>

      <DialogTitle>
        <Stepper
          activeStep={currentPage}
          alternativeLabel>
          <Step key="configureAction">
            <StepLabel>{t('actions.configureAction')}</StepLabel>
          </Step>
          <Step key="reviewAndSave">
            <StepLabel>{t('actions.reviewAndConfirm')}</StepLabel>
          </Step>
        </Stepper>
      </DialogTitle>

      <CloseDialogButton onClose={handleClose} />

      <DialogContent
        display="flex"
        justifyContent="center"
        flexDirection="column">
        {handleSteps()}

        {encodedPayload?.alreadyInQueue && (
          <Typography
            id="device-detail-send-action-dialog-error-queue-msg"
            marginTop={3}
            color="red">
            {t('actions.downlinkAlreadyInQueue')}!
          </Typography>
        )}

        {showErrorMessage && (
          <Typography
            id="device-detail-send-action-dialog-error-msg"
            marginTop={3}
            color="red">
            {t('actions.downlinkNotSentError')}!
          </Typography>
        )}

        <Box sx={{ flexGrow: 1 }}>
          <Grid2
            container
            mt={8}>
            <Grid2></Grid2>
          </Grid2>
        </Box>
      </DialogContent>

      <DialogActions sx={{ justifyContent: 'space-between' }}>
        <Button
          aria-label="back"
          variant="text"
          onClick={() => handleChangeCurrentPage(currentPage - 1)}
          disabled={currentPage === 0}>
          <ArrowBackRoundedIcon />
          {t('common.previous')}
        </Button>

        {currentPage === 0 && (
          <Button
            id="device-dialog-send-action-button-next"
            aria-label="next"
            variant="text"
            disabled={!downlinkPayload.isValid}
            onClick={() => handleChangeCurrentPage(currentPage + 1)}>
            {t('common.next')}
            <ArrowForwardRoundedIcon />
          </Button>
        )}

        {currentPage === 1 && (
          <Button
            id="device-dialog-send-action-button-save"
            variant="contained"
            onClick={handleSaveDownlink}>
            {t('common.confirmAndSave')}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}

export default SendAction
