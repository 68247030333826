import { Autocomplete, Stack, TextField, Typography } from '@mui/material'
import { t } from 'i18next'

const filterOptions = [
  {
    label: 'In Corso',
    value: 'created'
  },
  {
    label: 'Terminato',
    value: 'ended'
  },
  {
    label: 'Disabilitato',
    value: 'disabled'
  }
]

const EventStatusFilter = ({ filters, onChange = () => { } }) => {
  const handleChange = (_, data) => {
    onChange(data ? data.value : undefined)
  }

  return (
    <>
      <Stack
        direction="column"
        justifyContent="space-between"
        alignItems="left"
        spacing={2}
        mt={3}
        mb={3}
      >
        <Typography
          align="left"
          sx={{ fontWeight: 'bold' }}
        >
          {t('events.eventStatus')}
        </Typography>
        <Autocomplete
          id="events-status-filter"
          options={filterOptions}
          onChange={handleChange}
          isOptionEqualToValue={(option, value) => option.value === value}
          getOptionLabel={(option) => option?.label}
          value={
            filters.status
              ? filterOptions.find((f) => f.value === filters.status)
              : null
          }
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
            />
          )}
        />
      </Stack>
    </>
  )
}

export default EventStatusFilter
